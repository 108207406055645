<template>
  <div class="num-box">
    <span class="num">{{num}}</span>
    <span class="title-txt">{{title}}</span>
  </div>
</template>

<script>
export default {
  name: 'NumView',
  props: {
    title: String,
    num:Number
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.num-box{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.num-box .num{
  font-size: 1.8rem;
  font-weight: 800;
  color: #00FFF6;
}

.num-box .title-txt{
  font-size: 1.0em;
  color: white;
  margin-top: 2px;
}
</style>
