export default function getData(legendData, chartData){
  return{
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    color: [
      // '#07a807',
      '#24A0FF',
      '#00EFE6',
      '#FFE200',
      '#9E41EC'
    ],

    // grid: {
    //   left: '0%',
    //   right: '-10%',
    //   top:'-6%',
    //   bottom: '0%',
    //   containLabel: false
    // },

    // legend: {
    //   data: legendData,
    //   itemGap: 16, //间隔
    //   padding: [
    //     0, 30, 0,0
    //   ],
    //   itemWidth: 26,
    //   itemHeight: 15,
    //   right:'right',
    //   top: '16%',
    //   textStyle: {
    //     color:'#fff',
    //     fontSize: 13
    //   }
    // },

    label: {
      fontSize: 14,
      formatter: '等级：{b}\n人数：{c}'
    },
    series: [
      {
        center: ['50%', '30%'],
        radius: ['30%', '50%'],

        itemStyle: {
          borderRadius: 4,
          borderColor: '#04133F',
          borderWidth: 1
        },
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowOffsetX: 0
          }
        },
        type: "pie",
        name: '行动能力：',
        label: {
          // alignTo: 'edge',
          formatter: '{name|{b}}\n{time|{c}人}',
          minMargin: 0,
          edgeDistance: 0,
          lineHeight: 20,
          color: '#fff',
          rich: {
            name: {
              fontSize: 12,
              color: '#ffffff'
            },
            time: {
              fontSize: 11,
              color: '#00EFE6'
            }
          }
        },
        labelLine: {
          length: 15,
          length2: 15,
          maxSurfaceAngle: 145
        },
      }
    ]
  }

};
