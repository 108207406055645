<template>
  <div class="container user-container">
    <div class="user-content">
      <div class="left-content">

        <userinfo :userBean="userBean" :showBtn="false"></userinfo>
        <div class="warning-tip">
          <i class="el-icon-warning"></i><span>安全提示：请不要轻易透露您的联系方式，不要借钱给陌生人。</span>
        </div>

        <el-card class="input-card">
          <div class="ih4">
            <span class="tag-title">发送内容</span>
          </div>
          <el-form status-icon :rules="rules" ref="form" :model="form">

            <el-form-item prop="msg">
              <el-input type="textarea" :rows="5" class="input-msg" v-model="form.msg" placeholder="请输入信息内容"
                        autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item class="btn-right">
              <el-button size="medium" type="primary" @click="onSubmit('form')" round>免费发送</el-button>
            </el-form-item>
          </el-form>
        </el-card>

      </div>
      <div class="right-content">
        <vip></vip>
        <recuser :recUserArr="recUserArr"></recuser>
      </div>
    </div>


  </div>
</template>

<script type="text/ecmascript-6">

import constant, { monthlyIncomeArray } from '../constant'
import request, { httpGet } from '../http/request'
import { CodeToText } from 'element-china-area-data'
import { setStore, getStore } from '../utils/storage'
import recuser from '@/components/RecUser'
import vip from '@/components/Vip'
import userinfo from '@/components/UserInfo'

export default {

  data () {

    return {
      rules: {
        msg: [
          {
            required: true,
            message: '请输入发送内容',
            trigger: 'blur'
          },
        ]
      },
      form: {
        msg: ''
      },
      recUserArr: [],
      userId: '',
      userBean: {},
      vipRadio: 3,
    }
  },
  components: {
    recuser,
    vip,
    userinfo
  },
  created () {
    this.userId = this.$route.params.id
    // this.userId = this.$route.query.user_id
    console.log(this.userId)
    request.httpGet(`/api/circle/user/get_info?user_id=${this.userId}&type=1`, {}, success => {
      this.userBean = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    request.httpPost('/api/web/meet/rec-side-users', {
      type: 1,
      is_circle: 1
    }, success => {
      this.recUserArr = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {

    onClickLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            phone: this.form.phone,
            password: this.form.password
          }
          request.httpPost('/api/login', params, data => {
            setStore(constant.K_TOKEN, data.token)
            this.token = data.token
            this.userInfo = data
          }, error => {
            this.$message({
              message: error,
              type: 'error',
              offset: 80
            })
            console.log(error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onPageChange (current) {
      console.log(current)
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.httpSendMsg(this.userId, this.form.msg)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    httpSendMsg (toUserId, msg) {

      var loading = this.$loading()

      // let length = this.data.msgArr.length;
      // let lastId = null;
      // if (length > 0) {
      //   lastId = this.data.msgArr[length - 1].id;
      // }
      // if (conversationId == null || conversationId == '') {
      //   conversationId = -1
      // }
      let conversationId = -1
      let lastId = null
      let type = 0
      var params = {
        conversation_id: conversationId,
        to_user_id: toUserId,
        msg: msg,
        type: type,
        last_id: lastId
      }
      request.httpPost('/api/chat/send', params, success => {
        loading.close()
        this.$router.go(-1)
        this.$message({
          message: '发送成功',
          type: 'success',
          offset: 80
        })
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })

    }

  }

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.user-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);
}

.input-card
  margin 20px 0

  .ih4
    display flex
    flex-direction row
    align-items center

    .tag-title
      font-size 18px
      color #1f1f1f

.detail_desc
  margin 20px 20px 50px 20px
  font-size 15px
  line-height 1.5em
  color #666666

.margintop40
  margin-top 40px

.item-row
  margin-top 20px
  align-items center

  .gender-radio-group
    margin-right 16px
    line-height 32px !important
    font-size 14px

    .el-radio
      margin-right 10px

  .label-title
    font-size 15px
    color #282828

  .cat-tag
    height 30px
    line-height 30px
    text-align center
    border-radius 15px
    padding 0 20px
    margin-right 16px
    font-size 14px
    color #323232
    background-color #F0F0F0

    &.cat-tag-selected
      color white
      background-color #fc7f97


.el-width {
  width 124px
  margin-right 20px
}


.user-content
  width 1200px
  margin-top 170px
  display flex
  flex-direction row
  justify-content space-between

  .left-content
    width 890px

    .warning-tip
      display flex
      flex-direction row
      align-items center
      font-size 16px
      font-weight bold
      margin-left 10px
      margin-top 30px
      color #282828

      .el-icon-warning
        font-size 24px
        color #F7A200
        font-weight bold
        margin-right 12px

    .input-card
      .input-msg
        width 100%
        margin-top 20px

    .btn-right
      display flex
      flex-direction row-reverse

  .right-content
    width 290px


</style>
