<template>
  <!--  destroy-on-close 暂时只能设置 false  设置 true 会报错-->
  <el-dialog
    :visible="show"
    custom-class="dialog-container"
    @open="onOpen"
    @close="onClose"
    :modal-append-to-body="false"
    :append-to-body="false"
    :fullscreen="false"
    :show-close="false"
    :destroy-on-close="false"
    :close-on-click-modal="false"
    width="500px">
    <!--    <div class="title">测试</div>-->
    <div class="title">{{ title }}</div>

    <i class="el-icon-close close" @click="closeDialog"></i>
    <el-main class="dialog-box">
      <div class="item-box"  @click="onClickNav(1)">助餐&探视随访</div>
      <div class="item-box" @click="onClickNav(2)"> 视频监控中心</div>
      <div class="item-box" @click="onClickNav(3)">儿童探视随访</div>
      <div class="item-box" @click="onClickNav(4)">机构养老</div>
      <divl class="item-box" @click="onClickNav(5)">居家养老</divl>
      <div class="item-box" @click="onClickNav(6)">社区养老</div>
    </el-main>

  </el-dialog>
</template>


<script>

var player = null;

export default {
  name: "DialogSelectMonitor",
  components: {},
  props: {
    show: Boolean
  },

  data() {
    return {
      loading: false
    }
  },
  mounted: () => {

  },

  methods: {
    onOpen() {
    },

    onClose() {
    },

    onClickNav(index){
        switch (index){
          case 1:
            this.$router.replace('/')
            break
          case 2:
            this.$router.replace('/institution/video')
            break
          case 3:
            this.$router.replace('/visit/child')
            break
          case 4:
          case 5:
          case 6:
            this.$message.error("暂无权限！")
            break
        }
    },

    closeDialog() {
      // this.show = false
      this.$emit('onClose')
    },

  }
};
</script>
<style scoped>


.close {
  color: #03FFDE;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  font-size: 20px;
  color: white;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
}

.dialog-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 !important;
  .item-box{
    width: 120px;
    height: 60px;
    line-height: 60px;
    border: 1px solid #00FCF9;
    border-radius: 6px;
    margin-left: 20px;
    margin-bottom: 12px;
    color: #0BA1F8;
    font-size: 16px;
    text-align: center;
  }



}

</style>
