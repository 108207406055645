<template>
  <div class="container user-container">
    <div class="user-content" >
      <div class="left-content" >
        <userinfo :userBean="userBean" :showBtn="true" :isVip="myInfo.is_vip"></userinfo>

        <el-card class="user-detail-card">
          <div class="ih4">
            <div class="tag-line"></div>
            <span class="tag-title">个人资料</span>
          </div>
          <el-row class="item-row" type="flex" align="center" >

            <div class="cat-tag cat-tag-selected" v-if="userBean&&userBean.birthday!=0">{{getAge(userBean.birthday)}}</div>
            <div class="cat-tag cat-tag-selected"
                  v-if="userBean.user_info!=null&&userBean.user_info.height!=null&&userBean.user_info.height!=0">{{userBean.user_info.height}}cm</div>
            <div class="cat-tag cat-tag-selected"
                 v-if="userBean.occupation">职业：{{userBean.occupation}}</div>
            <div class="cat-tag cat-tag-selected"
                 v-if="userBean.city">现居：{{userBean.city}}</div>
            <div class="cat-tag cat-tag-selected"
                 v-if="userBean.education">{{educationArray[userBean.education]}}</div>


          </el-row>
          <el-row class="item-row" type="flex" align="center" >
            <div class="cat-tag"
                 v-if="userBean.user_info&&userBean.user_info.city">户籍：{{userBean.user_info.province}}-{{userBean.user_info.city}}</div>
            <div class="cat-tag"
                 v-if="userBean.user_info!=null&&userBean.user_info.marital_status!=0">月收入:{{maritalStatusArray[userBean.user_info.marital_status]}}</div>

            <div class="cat-tag"
                 v-if="userBean.user_info">{{monthlyIncomeArray[userBean.user_info.monthly_income]}}</div>
            <div class="cat-tag"
                 v-if="userBean.user_info">{{housingArray[userBean.user_info.housing]}}</div>
            <div class="cat-tag"
                 v-if="userBean.user_info">{{carStatusArray[userBean.user_info.car_status]}}</div>


          </el-row>
          <div class="ih4 margintop40">
            <div class="tag-line"></div>
            <span class="tag-title">我的介绍</span>
          </div>
          <div class="detail_desc">{{userBean.user_info&&userBean.user_info.your_detail_desc?userBean.user_info.your_detail_desc:'暂未填写'}}</div>
          <div class="ih4 margintop40">
            <div class="tag-line"></div>
            <span class="tag-title">兴趣爱好</span>
          </div>
          <div class="detail_desc">{{userBean.user_info&&userBean.user_info.hobbies?userBean.user_info.hobbies:'暂未填写'}}</div>
          <div class="ih4 margintop40">
            <div class="tag-line"></div>
            <span class="tag-title">心仪的TA</span>
          </div>
          <div class="detail_desc">{{userBean.user_info&&userBean.user_info.mate_selection_desc?userBean.user_info.mate_selection_desc:'暂未填写'}}</div>
        </el-card>

      </div>
      <div class="right-content">
        <vip></vip>
        <recuser :recUserArr="recUserArr"></recuser>
      </div>
    </div>


  </div>
</template>

<script type="text/ecmascript-6">

import constant, { monthlyIncomeArray } from '../constant'
import request, { httpGet } from '../http/request'
import { setStore, getStore } from '../utils/storage'
import recuser from '@/components/RecUser'
import vip from '@/components/Vip'
import userinfo from '@/components/UserInfo'

export default {
  components: {
    recuser,
    vip,
    userinfo
  },

  data () {

    return {
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
      monthlyIncomeArray: constant.monthlyIncomeArray,
      carStatusArray: constant.carStatusArray,
      housingArray: constant.housingArray,
      recUserArr: [],
      userId: 12,
      userBean: {},
      myInfo:{},
      vipRadio:3
    }
  },

  created () {
    this.userId = this.$route.params.id
    console.log(this.$route.params)
    this.token = getStore(constant.K_TOKEN)
    request.httpGet(`/api/web/user/get_info?user_id=${this.userId}&type=1`, {}, success => {
      this.userBean = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    if (this.token) {
      request.httpGet('/api/user/get_my_info', {}, data => {
        this.myInfo = data
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }
    request.httpPost('/api/web/meet/rec-side-users', {
      type: 1,
      is_circle: 1
    }, success => {
      this.recUserArr = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {
    splitStr (photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },

    onClickLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            phone: this.form.phone,
            password: this.form.password
          }
          request.httpPost('/api/login', params, data => {
            setStore(constant.K_TOKEN, data.token)
            this.token = data.token
            this.userInfo = data
          }, error => {
            this.$message({
              message: error,
              type: 'error',
              offset: 80
            })
            console.log(error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onPageChange (current) {
      console.log(current)
    },
    // 根据出生日期计算年龄周岁 参数2019-12-09 小于10补0
    getAge (birthday) {
      if (!birthday) {
        return ''
      }

      var returnAge = ''
      var mouthAge = ''
      var strBirthdayArr = birthday.split('-')
      var birthYear = strBirthdayArr[0]
      var birthMonth = strBirthdayArr[1]
      var birthDay = strBirthdayArr[2]
      var d = new Date()
      var nowYear = d.getFullYear()
      var nowMonth = d.getMonth() + 1
      var nowDay = d.getDate()
      if (nowYear == birthYear) {
        // returnAge = 0; //同年 则为0岁
        var monthDiff = nowMonth - birthMonth //月之差
        if (monthDiff < 0) {
        } else {
          mouthAge = monthDiff + '个月'
        }
      } else {
        var ageDiff = nowYear - birthYear //年之差
        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay //日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1 + '岁'
            } else {
              returnAge = ageDiff + '岁'
            }
          } else {
            var monthDiff = nowMonth - birthMonth //月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1 + '岁'
            } else {
              mouthAge = monthDiff + '个月'
              returnAge = ageDiff + '岁'
            }
          }
        } else {
          returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
        }
      }
      // return returnAge + mouthAge; //返回周岁年龄+月份
      return returnAge //返回周岁年龄+月份
    }

  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.user-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);
}

.user-detail-card
  margin 20px 0

.ih4
  display flex
  flex-direction row
  align-items center

  .tag-title
    font-size 18px
    color #1f1f1f
.detail_desc
  margin 20px 20px 50px 20px
  font-size 15px
  line-height 1.5em
  color #666666

.margintop40
  margin-top 40px

.item-row
  margin-top 20px
  align-items center

  .gender-radio-group
    margin-right 16px
    line-height 32px !important
    font-size 14px

    .el-radio
      margin-right 10px

  .label-title
    font-size 15px
    color #282828

  .cat-tag
    height 30px
    line-height 30px
    text-align center
    border-radius 15px
    padding 0 20px
    margin-right 16px
    font-size 14px
    color #323232
    background-color #F0F0F0

    &.cat-tag-selected
      color white
      background-color #fc7f97


.el-width {
  width 124px
  margin-right 20px
}


.user-content
  width 1200px
  margin-top 170px
  display flex
  flex-direction row
  justify-content space-between
  .left-content
    width 890px

  .right-content
    width 290px

</style>
