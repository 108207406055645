<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import { setStore, getStore } from './utils/storage'
import constant from './constant'

export default {
  data () {
    return {
      token:'',
      routeName:'',
      activeIndex: '1',
      activeIndex2: '1'
    }
  },


  created () {
    this.token = getStore(constant.K_TOKEN)
    this.routeName = this.$route.name
    if (this.routeName=='Index'){
      this.activeIndex = '1'
    }else if (this.routeName=='FindFate'){
      this.activeIndex = '2'
    }else if (this.routeName=='Activity'){
      this.activeIndex = '3'
    }else if (this.routeName=='VipCenter'){
      this.activeIndex = '4'
    }
  },

  updated () {
    this.token = getStore(constant.K_TOKEN)
    this.routeName = this.$route.name
    if (this.routeName=='Index'){
      this.activeIndex = '1'
    }else if (this.routeName=='FindFate'){
      this.activeIndex = '2'
    }else if (this.routeName=='Activity'){
      this.activeIndex = '3'
    }else if (this.routeName=='VipCenter'){
      this.activeIndex = '4'
    }
  },

  methods: {
    handleSelect (key, keyPath) {
      // console.log(key)
      this.$router.push(key==2?"/find_fate":key==3?"/activity":key==4?"":key==5?"/vip":"/");
    },
    logoutFun () {
      this.token = ''
      setStore(constant.K_TOKEN, '')
      this.$router.go(0);

    },
    onJumpMineCenter(){
      this.$router.push("/mine/base_info");
    },
    onJumpMsgCenter(){
      this.$router.push("/msg");
    },
    onJumpSetting(){
      this.$router.push("/mine/modfiy_pwd");
    },
    onClickRegister(){
      this.$router.push("/register");
    },
    onClickLogin(){
      this.$router.push("/");
    },

  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">
@import "./common/stylus/mixin.styl"
#app {
  display flex
  flex-direction column
  align-items center
  background #F6F6F6 !important
}

.top-header {
  position fixed
  top 0
  left 0
  z-index 9999
  width 100%;
  height 64px;
  display flex;
  flex-direction row;
  align-items center;
  justify-content center;
  background-color #FF4666;
}

.top-header .header-content{
  width: 1200px
  display flex;
  flex-direction row;
  align-items center;
}

.logo{
  width 180px;
  height 48px;
}

.el-menu-nav{

}

.userinfo{
  display flex
  flex-direction row
  align-items center
  justify-content flex-end
}

.footer-view
  width 100%
  background-color white
  margin-top 60px
  padding 20px 0
  box-sizing border-box
  display flex
  flex-direction column
  align-items center
  color #454545
  font-size 15px
  line-height 30px
  .margin-left
    margin-left 30px
  .divider-row
    align-items center
  .ver-divider
    height 12px
    width 1px
    background-color #aaaaaa
    margin 0 10px
  a
    color #454545
  a:hover
    color #ff4666

.btn-top-opt{
  height 34px !important
  line-height 0 !important
  background-color rgba(255,255,255,0.9) !important
  color #666666 !important
}

</style>
