// import {LinearGradient} from "echarts/graphic";
import * as echarts from 'echarts'


export default function getData(xData, chartData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    // backgroundColor: "#ffffff",
    // title: {
    //   text: '发现问题数量走势曲线',
    //   left: 'left'
    // },
    legend: {
      show: false
    },
    grid: { //控制边距大小
      left: '50px',
      right: '2%',
      bottom: '32px',
      top: '56px',
      containLabel: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      name: "时间",
      // boundaryGap: false,
      data: xData,
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: 'rgba(4,120,195,0.5)',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: 'rgba(4,120,195,0.5)',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding: 2,
        },
      },
    },
    yAxis: {
      x: 'left',
      type: 'value',
      position: 'left',
      // name: '人流量(个)    ',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 1,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding: 2,
        },
        formatter: function(value) {
          if (value >= 1000 && value < 999950) { // 根据需求调整条件
            return Math.round((value / 1000)) + 'k';
          } else if (value >= 1000000) {
            return Math.round((value / 1000000)) + 'm';
          } else {
            return value;
          }
        },
      }
      // show: false
    },
    detail: {
      formatter: '{value}人'
    },
    series: [{
      // name: '人流量',
      type: 'line',
      showSymbol: true,
      symbolSize: 7,
      symbol: "circle",
      lineStyle: {
        color: 'rgba(54, 240, 178, 0.8)'
      },
      // 折线拐点的样式
      itemStyle: {
        color: "#36F0B2",
        borderColor:  '#36F0B2',
        borderWidth: 1,
        emphasis: { // 鼠标经过时：
          color: '#00f6a2',
        }
      },
      areaStyle: {
        opacity: 1,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(0, 255, 198, 0.4)'
        },
          {
            offset: 1,
            color: 'rgba(0, 255, 198, 0.01)'
          }
        ])
      },
      markPoint: {
        data: [
          {type: 'max', name: 'Max'},
          {type: 'min', name: 'Min'}
        ],
        formatter: function(value) {
          if (value >= 1000 && value < 999950) { // 根据需求调整条件
            return Math.round((value / 1000)) + 'k';
          } else if (value >= 1000000) {
            return Math.round((value / 1000000)) + 'm';
          } else {
            return value;
          }
        }
      },
      data: chartData
    }]
  }
}
