<template>
  <div class="container personal-center-container">
    <div class="personal-center-content">
      <LeftSiderBar :userBean="userBean" navIndex="/mine/detail_info"></LeftSiderBar>
      <div class="right-content">

        <div class="ih4" style="margin-bottom: 15px;">
          <div class="tag-line"></div>
          <span class="tag-title">详细信息</span>
        </div>


        <el-form status-icon  ref="form" :model="form" label-width="100px" label-position="left">
          <el-form-item label="我的职业">
            <el-input class="item-width" v-model="form.occupation" placeholder="请输入你的职业" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="我的籍贯">
            <el-cascader
              class="item-width"
              :options="options"
              v-model="selectedOptions"
              :placeholder="addressPlaceholder"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="婚房情况">
            <el-select class="item-width" v-model="form.housing" placeholder="请选择" size="medium">
              <el-option label="已购房" value="1"></el-option>
              <el-option label="未购房" value="2"></el-option>
              <el-option label="有能力购房" value="3"></el-option>
              <el-option label="和父母同住" value="4"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="车辆状况">
            <el-radio-group v-model="form.car_status">
              <el-radio label="1">有车</el-radio>
              <el-radio label="2">无车</el-radio>
            </el-radio-group>

          </el-form-item>
<!--          <el-form-item label="我的体重">-->
<!--            <el-slider style="width: 500px;margin-bottom: 10px" v-model="form.height" :format-tooltip="formatTooltip"-->
<!--                       :min="120" :max="230" :marks="marks"></el-slider>-->
<!--          </el-form-item>-->



          <el-form-item label="恋爱宣言">
            <el-input class="item-width-big"  type="textarea" rows="4" v-model="form.mate_selection_desc" placeholder="你可以描述你的择偶要求 例如：身高看，学历，收入，年龄，住房，户籍，兴趣爱好等" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="我的兴趣爱好">
            <el-input class="item-width-big" type="textarea" :rows="4" v-model="form.hobbies" placeholder="请输入你的兴趣爱好" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="我的生活照">
            <el-upload
              action
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :http-request="uploadHttp"
              :file-list="form.fileList"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>

          <el-form-item>
            <el-button class="item-width" size="medium" type="primary" @click="onSubmit('form')">保存</el-button>
          </el-form-item>
        </el-form>

      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">

import constant from '../../constant'
import request, { httpGet } from '../../http/request'
import { setStore, getStore } from '../../utils/storage'
import LeftSiderBar from '../../components/LeftSiderBar'
import { CodeToText, TextToCode, regionData } from 'element-china-area-data'
import ossClient from '../../aliyun.oss.client'

export default {
  components: { LeftSiderBar },
  data () {
    return {
      form: {
        occupation: '',
        housing: '',
        car_status: '',
        mate_selection_desc:'',
        hobbies:'',
        fileList: []
      },
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
      monthlyIncomeArray: constant.monthlyIncomeArray,
      carStatusArray: constant.carStatusArray,
      housingArray: constant.housingArray,
      marks: {
        130: '130cm',
        170: '170cm',
        220: '220cm'
      },
      addressPlaceholder: "请选择",
      options: regionData,
      selectedOptions: [],

      userBean: {},

      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  created () {

    request.httpGet('/api/circle/user/get_my_info', {}, success => {
      this.userBean = success
      this.form.occupation = success.occupation
      if (success.user_info) {
        this.form.car_status = success.user_info.car_status==0?'':success.user_info.car_status
        this.form.housing = success.user_info.housing==0?'':success.user_info.housing
        this.form.hobbies = success.user_info.hobbies
        this.form.mate_selection_desc = success.user_info.mate_selection_desc
        let photos = this.splitStr(success.user_info.life_photos)

        photos.forEach( (photo) =>{
          this.form.fileList.push({
            name: this.splitFileNameStr(photo),
            url:photo
          })
        });
        if (success.user_info.province) {
          // let options = [ TextToCode(success.province),TextToCode(success.city)]
          // this.selectedOptions = options
          this.addressPlaceholder = success.user_info.province + "/" + success.user_info.city

        }
      }


    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {
    splitStr (photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },
    splitFileNameStr (photoStr) {
      if (photoStr&&photoStr.length>22) {
        return photoStr.substring(22);
      }
      return []
    },

    formatTooltip (val) {
      if (val == 120) {
        return '120cm以下'
      } else if (val == 230) {
        return '230cm以上'
      }
      return val + 'cm'
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var txt= ''
          if (this.form.mate_selection_desc){
            txt = txt + this.form.mate_selection_desc
          }
          if (this.form.hobbies){
            txt = txt + this.form.hobbies
          }
          if (txt){
            this.httpTxtCensor(txt)
          }else {
            this.httpModifyInfo()
          }

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChange () {
      var loc = ''
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]]
      }
      // alert(loc);
      // console.log(this.selectedOptions)

    },

    httpModifyInfo () {

      var params = {
        occupation: this.form.occupation,
        car_status: this.form.car_status,
        housing: this.form.housing,
        mate_selection_desc: this.form.mate_selection_desc,
        hobbies: this.form.hobbies
      }

      if (this.selectedOptions.length > 0) {
        params.birthProvince = CodeToText[this.selectedOptions[0]]
        params.birthCity = CodeToText[this.selectedOptions[1]]
        params.birthpalce = params.birthProvince + ' ' + params.birthCity
      }
      if (this.form.fileList){
        let life_photos = ''
        this.form.fileList.forEach((file)=>{
          if (life_photos==''){
            life_photos = ossClient.fileHost + file.name
          }else {
            life_photos = life_photos + ',' + ossClient.fileHost + file.name
          }
        })
        params.life_photos = life_photos
      }

      if (this.options) {
        request.httpPost('/api/user/modify_info', params, success => {
          this.$message({
            message: '保存成功',
            type: 'success',
            offset: 80
          })
          this.$router.go(0)

        }, error => {
          this.$message({
            message: error,
            type: 'error',
            offset: 80
          })
          console.log(error)
        })
      }
    },
    httpTxtCensor(txt){
      var params = {
        txt: txt
      }

      request.httpPost('/api/censor/txt', params, success => {
        if (success['conclusionType']==1){
            this.httpModifyInfo()
        }else {
          this.$message({
            message: '您填写的的内容不适合传播，请修改后，重新提交',
            type: 'error',
            offset: 80
          })
        }
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },
    /**
     * 阿里云OSS上传
     */
    uploadHttp({ file }) {
      var that = this;
      async function multipartUpload() {
        let temporary = file.name.lastIndexOf(".");
        let fileNameLength = file.name.length;
        let fileFormat = file.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = ossClient.getFileNameUUID() + "." + fileFormat;
        var filePath = 'user/life/' + new Date().getTime() + "-" + fileName;

        ossClient.put(filePath, file).then((result) => {
          // console.log(result)
          // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
          var imgarr = {
            name: result.name,
            url: result.url,
            id:new Date().getTime()+100
          }
          // that.form.fileList.push(imgarr);
          that.httpImgCensor(imgarr)
          // console.log(that.from.fileList)
        }).catch(err => {
          console.log("err:", err);
          that.$message({
            message: '图片上传失败',
            type: 'error',
            offset: 80
          })
        });

      }
      multipartUpload();
    },
    httpImgCensor(imgArr){
      var params = {
        url: imgArr['url']
      }

      request.httpPost('/api/censor/img', params, success => {
        if (success['conclusionType']==1){
          this.form.fileList.push(imgArr);
        }else {
          this.$message({
            message: '图片审核失败，请重新上传',
            type: 'error',
            offset: 80
          })
        }
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },


    /**
     * 图片限制
     */
    beforeAvatarUpload (file) {
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG && !isJPEG && !isPNG) {
        this.$message({
          message: '上传图片只能是 JPEG/JPG/PNG 格式!',
          type: 'error',
          offset: 80
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '单张图片大小不能超过 500KB!',
          type: 'error',
          offset: 80
        })
      }
      return (isJPEG || isJPG || isPNG) && isLt2M
    },

    /**
     * 移除图片
     */
    handleRemove (file, fileList) {
      console.log(`移除图片回调`, fileList);
      this.form.fileList = fileList
      // file 当前本地已上传的这张图片
      // var fileName = file.name; // 当前本地已上传的这张图片的名称(带有时间日期)
      // var removeName = "";
      // this.fileList.forEach(function (name) {
      //   // forEach 在循环的数组中(fileList)找到oss和本地相同的文件名称
      //   if (name.match(fileName)) {
      //     removeName = name; // 给循环出匹配上文件名的name赋值，这里循环出的图片是带有时间日期
      //   }
      // });
      // ossClient.remove(removeName).then((result) => {
      //   // 把循环出来的图片传进来，然后进行删除
      //   if (result.res.status != "204") {
      //     console.log("删除失败");
      //   }
      // });

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../../common/stylus/mixin.styl"

.personal-center-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);

}


.user-detail-card
  margin 20px 0

//.ih4
//  display flex
//  flex-direction row
//  align-items center
//
//  .tag-title
//    font-size 18px
//    color #1f1f1f

//.detail_desc
//  margin 20px 20px 50px 20px
//  font-size 15px
//  line-height 1.5em
//  color #666666
//
//
//.personal-center-content
//  width 1200px
//  margin-top 30px
//  display flex
//  flex-direction row
//  justify-content space-between
//
//  .right-content
//    width 890px
//    box-shadow: 0px 2px 6px 2px rgba(192, 192, 192, 0.5);
//    border-radius 6px
//    padding 20px
//    box-sizing border-box
//    background-color white


</style>
