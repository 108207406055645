<template>
  <main class="monitor-container"  v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">

    <img class="bg-img" src="../images/bg_monitor.jpg"/>
    <div class="full-icon">
      <!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click"/>-->
<!--      <i :class="isFullscreen?'el-icon-top-left':'el-icon-full-screen'" style="color: white"-->
<!--         ></i>-->
      <img v-if="isFullscreen" src="../images/icon_compress.png" width="20" height="20" @click="clickScreenFull">
      <img v-else src="../images/icon_expend.png" width="20" height="20" @click="clickScreenFull">
    </div>

    <div class="header-box">
      <img class="bg-header" src="../images/bg_top_header.png"/>
      <div class="logo-txt" @click="onClickSwitchMonitor">木子信息科技</div>
      <el-popover
        ref="popover4"
        placement="bottom"
        width="600"
        popper-class="pop-select-region-container"
        trigger="click"
        style="z-index: 99">
        <div class="pop-select-region-content" style="overflow: hidden;">
          <el-input
            placeholder="输入关键字"
            v-model="filterGroupText">
          </el-input>
          <el-scrollbar style="height: 500px;margin-top: 10px">
            <el-tree
              class="filter-tree"
              ref="tree"
              node-key="id"
              :data="groupData"
              :props="defaultProps"
              :check-strictly="true"
               show-checkbox
               default-expand-all
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              :check-on-click-node="true"
              @check-change="handleCheckChange"
              >
            </el-tree>

          </el-scrollbar>

        </div>
        <!--        <el-button slot="reference" > click 激活</el-button>-->
        <div class="title-txt" slot="reference"  >{{curGroupName}}  <i class="el-icon-caret-bottom"   style="color: white; font-size: 24px"></i></div>
      </el-popover>

      <img class="icon-exit" src="../images/icon_exit.png" @click="onClickLogout"/>
    </div>
    <div class="content-box">
      <div class="left-box">
        <h2-view style="margin-top: -6px;" title="探视走访统计"></h2-view>
        <div class="chart-box" style="margin-top: 6px">
          <h4-view title="发现问题数走势"></h4-view>
          <figure>
            <v-chart
              :option="barLineVisitQuestion"
              :init-options="initOptions"
              ref="lineVisitQuestion"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 20vh;"
            />
          </figure>
        </div>
        <div class="divider-line" style="margin-top: 15px"></div>
        <div class="chart-box">
          <h4-view title="问题分类统计"></h4-view>
          <figure>
            <v-chart
              :option="barVisitQuestionType"
              :init-options="initOptions"
              ref="barVisitQuestionType"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 20vh;"
            />

          </figure>

        </div>
        <div class="divider-line" style="margin-top: 12px"></div>
        <div class="chart-box" style="margin-top: -6px">
<!--          <h4-view title="行动能力等级分布"></h4-view>-->
          <figure style="min-width: calc(10vw + 6em);">
            <v-chart
              :option="pie"
              :init-options="initOptions"
              ref="pie"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 22vh;"
            />
            <div style="margin-top: -92px; margin-bottom: 44px">
              <num-view-hor title="总人数" :num="visitTotalBean.totalPersonNum"></num-view-hor>
               <div  class="title-txt"  style="margin-top: 6px">行动能力等级分布</div>
            </div>
          </figure>
          <figure style="min-width: calc(10vw + 7em);">
            <v-chart
              :option="pieVisitUserAlone"
              :init-options="initOptions"
              ref="pie"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 22vh;"
            />
            <div  style="margin-top: -92px; margin-bottom: 44px; ">
              <div style="display: flex;flex-direction: row;">
                <div style="flex: 0.2"></div>
                <num-view-hor title="总人数" :num="visitTotalBean.totalPersonNum"></num-view-hor>
                <num-view-hor title="独居老人" :num="visitAloneUserNum"></num-view-hor>
                <div style="flex: 0.2"></div>
              </div>

              <div class="title-txt" style="margin-top: 6px">独居老人基本情况</div>
            </div>
          </figure>

        </div>
<!--        <div class="user-pie-title" style="margin-top: -80px">-->
<!--          <div class="title-txt" style="margin-top: -80px">行动能力等级分布</div>-->
<!--          <div class="title-txt">行动能力等级分布</div>-->
<!--        </div>-->
        <div class="divider-line" style="margin-top: -30px;"></div>
        <div class="chart-box" style="margin-top: -10px">
          <h4-view title="行动能力分布"></h4-view>
          <figure>
            <v-chart
              :option="barVisitUserActionAbility"
              :init-options="initOptions"
              ref="barVisitUserActionAbility"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 20vh;"
            />
          </figure>
        </div>


      </div>
      <div class="center-box">

        <h3-view title="助餐统计大数据"></h3-view>
        <num-scroll-view :val="mealCurMonthTotalBean.amount" label="本月助餐金额"  ></num-scroll-view>

        <div class="num-view-box">
          <num-view title="助餐点" :num="mealTotalBean.shopNum"></num-view>
          <div class="dash-line"></div>
          <num-view title="助餐人数" :num="mealTotalBean.personNum"></num-view>
          <div class="dash-line"></div>
          <num-view title="助餐次数" :num="mealTotalBean.num"></num-view>
          <div class="dash-line"></div>
          <num-view title="运营补贴 (元)" :num="mealTotalBean.subsidyAmount"></num-view>
          <div class="dash-line"></div>
          <num-view title="助餐金额 (元)" :num="mealTotalBean.amount"></num-view>
        </div>
        <auto-scroll-table-view-meal  @onClickItem="onClickItemMeal" :list-data="mealGroupListData" :recently-year-arr="recentlyYearArr" :cur-select-user-type="curSelectUserType" :province-id="provinceId" :city-id="cityId" :district-id="districtId" :group-id="groupId"></auto-scroll-table-view-meal>

        <h3-view title="探视走访统计大数据"></h3-view>

        <section class="small-chart-group-box" style="margin-top: -8px">
          <num-view title="总人数" :num="visitTotalBean.totalPersonNum"></num-view>
          <num-view title="本月登记问题" :num="visitTotalBean.questionNum"></num-view>
          <div class="dash-line"></div>
          <v-chart
            :option="gaugeVisitRate"
            :init-options="initOptions"
            ref="gauge"
            theme="ovilia-green"
            autoresize
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 13vh"
          />
          <v-chart
            :option="gaugeVisitCompleteRate"
            :init-options="initOptions"
            ref="gauge"
            theme="ovilia-green"
            autoresize
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 13vh"
          />
          <num-view title="本月探视人数" :num="visitTotalBean.visitPersonNum"></num-view>
          <div class="dash-line"></div>
          <num-view title="本月走访次数" :num="visitTotalBean.visitsNum"></num-view>
<!--          <div class="dash-line"></div>-->
<!--          <num-view title="本月任务次数" :num="visitTotalBean.visitPersonNum*4"></num-view>-->

        </section>

<!--        <div class="num-view-box">-->
<!--          <num-view title="总人数" :num="visitTotalBean.totalPersonNum"></num-view>-->
<!--          <div class="dash-line"></div>-->
<!--          <num-view title="探视人数" :num="visitTotalBean.visitPersonNum"></num-view>-->
<!--          <div class="dash-line"></div>-->
<!--          <num-view title="登记问题" :num="visitTotalBean.questionNum"></num-view>-->
<!--          <div class="dash-line"></div>-->
<!--          <num-view title="走访次数" :num="visitTotalBean.visitsNum"></num-view>-->
<!--          <div class="dash-line"></div>-->
<!--          <num-view title="任务次数" :num="visitTotalBean.visitPersonNum*4"></num-view>-->
<!--        </div>-->
        <auto-scroll-table-view-visit @onClickItem="onClickItemVisit" :list-data="visitGroupListData" :recently-year-arr="recentlyYearArr" :cur-select-user-type="curSelectUserType" :province-id="provinceId" :city-id="cityId" :district-id="districtId" :group-id="groupId"></auto-scroll-table-view-visit>

        <!--      <h2 id="map">
                <a href="#map">
                  Map
                  <small>(with GeoJSON &amp; image converter)</small>
                </a>
                <button
                  :class="{
                  round: true,
                  expand: expand.map
                }"
                  @click="expand.map = !expand.map"
                  aria-label="toggle"
                ></button>
              </h2>
              <section v-if="expand.map">
                <figure style="background-color: #404a59">
                  <v-chart
                    :option="map"
                    :init-options="initOptions"
                    ref="map"
                    autoresize
                  />
                </figure>
                <p>
                  <button @click="convert">Convert to image</button>
                </p>
              </section>-->
      </div>
      <div class="right-box">
        <h2-view title="助餐数据统计"></h2-view>
        <div class="chart-box" style="margin-top: 20px;width: 96%;">
          <figure style="width: 100%">
            <v-chart
              :option="barLineMealMonth"
              :init-options="initOptions"
              ref="barLineMealMonth"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 26vh; "
            />
          </figure>
        </div>
        <div class="meal-month-title">助餐月度统计</div>
        <div class="divider-line"></div>
        <div class="chart-box">
          <h4-view title="助餐等级人员分布"></h4-view>
          <figure>
            <v-chart
              :option="pieMealUserLevel"
              :init-options="initOptions"
              ref="pieMealUserLevel"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 20vh;"
            />
          </figure>
        </div>

        <div class="divider-line"></div>
        <div class="chart-box">
          <h4-view title="老年人类型统计"></h4-view>
          <figure>
            <v-chart
              :option="barMealUserType"
              :init-options="initOptions"
              ref="barMealUserType"
              theme="ovilia-green"
              autoresize
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              @zr:click="handleZrClick"
              @click="handleClick"
              style="height: 26vh;"
            />
          </figure>
        </div>
      </div>
    </div>
    <dialog-select-monitor :show="selectMonitorShow" @onClose="selectMonitorShow=false"></dialog-select-monitor>
  </main>
</template>

<script type="text/ecmascript-6">
/* eslint-disable no-console */
import qs from "qs";
// import VChart from "../ECharts";

import {
  use,
  registerMap,
  registerTheme,
  connect,
  disconnect
} from "echarts/core";
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart
} from "echarts/charts";
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent
} from "echarts/components";

import {CanvasRenderer, SVGRenderer} from "echarts/renderers";
import "echarts-liquidfill";
// import logo from "./data/logo";
import getBar from "./data/bar";
import getPie from "./data/pie";
import getPieUserAlone from "./data/pie-user-alone";
import polar from "./data/polar";
import scatter from "./data/scatter";
import map from "./data/map";
import {c1, c2} from "./data/connect";

import getGauge from "./data/gauge";
import getBarLineVisitQuestion from "./data/bar_line_visit_question";

import getBarQuestionType from "./data/bar-question-type";
import getBarPieActionAbility from "./data/bar-pie-action-ability";
import getBarLineMealMonth from "./data/bar_line_meal_month";
import getPieMealUserLevel from "./data/pie-meal-user-level";
import getBarMealUserType from "./data/bar_meal_user_type";

import H2View from "../components/H2View.vue";
import H3View from "../components/H3View.vue";
import H4View from "../components/H4View.vue";
import NumView from "../components/NumView.vue";
import NumViewHor from "../components/NumViewHor.vue";
import NumScrollView from "../components/NumScrollView.vue";

import AutoScrollTableViewVisit from "../components/AutoScrollTableViewVisit.vue";
import AutoScrollTableViewMeal from "../components/AutoScrollTableViewMeal.vue";

// custom theme
import theme from "./theme.json";

// Map of China
import chinaMap from "./china.json";
import worldMap from "./world.json";


import screenfull from 'screenfull'
import constant from '../constant'
import request from '../http/request'
import {setStore, getStore} from '../utils/storage'
import * as echarts from "echarts";
import getData from "./data/bar_meal_user_type";
import DialogSelectMonitor from "@/components/DialogSelectMonitor.vue";


use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent
]);

// registering map data
registerMap("china", chinaMap);
registerMap("world", worldMap);

// registering custom theme
registerTheme("ovilia-green", theme);

export default {
  name: "vue-echarts-demo",
  components: {
    DialogSelectMonitor,
    NumViewHor,
    H2View, H3View, H4View, NumView, AutoScrollTableViewVisit, AutoScrollTableViewMeal, NumScrollView
  },

  data() {
    const options = qs.parse(location.search, {ignoreQueryPrefix: true});
    return {
      timer : null,
      loading: false,
      selectMonitorShow:false,
      curGroupName:'',
      queryMonth: '',
      recentlyYearArr:[],
      userType:'',
      userId:'',
      provinceId: '',
      cityId: '',
      districtId: '',
      groupId: '',
      villageId: '',
      curSelectUserType:-1,
      filterGroupText: '',
      groupData: [],
      isShowSelectGroupDialog: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isFullscreen: false,
      options,
      bar: getBar(),
      pie: getPie([], []),
      pieVisitUserAlone: getPieUserAlone([], []),
      polar,
      scatter,
      map,
      c1,
      c2,
      gaugeVisitRate: getGauge("本月探视率", 0, "#00D1EE"),
      gaugeVisitCompleteRate: getGauge("本月完成率", 0, "#FC9600"),
      barLineVisitQuestion: getBarLineVisitQuestion([], [], [], []),
      barVisitQuestionType: getBarQuestionType([], [], [], [],[],[]),
      barVisitUserActionAbility: getBarPieActionAbility([], [], [], []),
      barLineMealMonth: getBarLineMealMonth([], [], [], []),
      pieMealUserLevel: getPieMealUserLevel([]),
      barMealUserType: getBarMealUserType([]),
      visitGroupListData: [],
      mealGroupListData: [],
      visitTotalBean: {},
      visitAloneUserNum:0,
      mealTotalBean: [],
      mealCurMonthTotalBean: [],
      initOptions: {
        renderer: options.renderer || "canvas"
      },
      polarTheme: "dark",
      seconds: -1,
      asyncCount: false,
      connected: true,
      metricIndex: 0,
      open: false,
      img: {},
      barLoading: false,
      barLoadingOptions: {
        text: "Loading…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0)"
      },
    };
  },

  created() {
    this.userId = this.$route.params.id
    this.token = getStore(constant.K_TOKEN)
    if (!this.token) {
      this.$router.push({
        path: '/login'
      })
      return
    }
    this.queryMonth = this.getNowDate()
    this.getRecentlyYear()
    this.provinceId = getStore(constant.K_PROVINCE_ID)
    this.cityId = getStore(constant.K_CITY_ID)
    this.districtId = getStore(constant.K_DISTRICT_ID)
    this.groupId = getStore(constant.K_GROUP_ID)
    this.villageId = getStore(constant.K_VILLAGE_ID)
    this.userType = getStore(constant.K_USER_TYPE)
    this.userId = getStore(constant.K_UID)
    this.curSelectUserType = parseInt(this.userType)

    // 设置整点定时发送请求
    this.timer = setInterval(() => {
      // const now = new Date();
      // if (now.getMinutes() === 0 && now.getSeconds() === 0) {
      //   this.httpRefreshCurData();
      // }
      // this.currentTime = now.toLocaleString();
      this.httpRefreshCurData();
    }, 5000);

    this.refreshAllData();
    this.httpRefreshCurData();


    // this.httpGroupList()
    this.httpGetCurGroupDetail()
  },


  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null
    this.destroyScreenFull()
  },
  watch: {
    filterGroupText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {

  },

  methods: {
    onClickSwitchMonitor(){
      this.selectMonitorShow = true
    },
    onClickLogout() {
      // this.centerDialogVisible = false
      this.token = ''
      this.userInfo = {}
      setStore(constant.K_TOKEN, '')
      setStore(constant.K_APP_ID, '')
      setStore(console.K_UID, '')
      clearInterval(this.timer);
      this.timer = null
      this.$router.replace('/login')
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    onClickItemMeal(item){
      console.log("======curSelectUserType==" + this.curSelectUserType +"==" + item.id)
      if (this.curSelectUserType !=5){
        let id = item.id
        let data = this.getSelectedItem(id)
        if (data){
          this.handleCheckChange(data, true, null)
        }
      }else {
        this.$message.error('不能再往下查看了');
      }

    },
    onClickItemVisit(item){
      if (this.curSelectUserType !=6){
        let id = item.id
        let data = this.getSelectedItem(id)
        if (data){
          this.handleCheckChange(data, true, null)
        }
      }else {
        this.$message.error('不能再往下查看了');
      }
    },

    getSelectedItem(id){
      for (let itemBean of   this.groupData) {
        if (id == itemBean.id){
          console.log("======1="+itemBean.id)
          return itemBean
        }
        if (itemBean.children){
          for (let childItem of itemBean.children) {
            if (id == childItem.id){
              console.log("======2="+childItem.id)
              return childItem
            }
            if (childItem.children){
              for (let child2Item of childItem.children) {
                if (id == child2Item.id){
                  console.log("======3="+child2Item.id)
                  return child2Item
                }
                if (child2Item.children){
                  for (let child3Item of child2Item.children) {
                    if (id == child3Item.id){
                      console.log("======4="+child3Item.id)
                      return child3Item
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    handleCheckChange(data, checked, indeterminate){
      if (checked) {
        this.dirId = data.id
        this.$refs.tree.setCheckedKeys([data.id])
        this.curGroupName = data.label
        this.provinceId = ''
          this.cityId = ''
          this.districtId = ''
        this.groupId = ''
        this.villageId = ''
        switch (data.level){
          case 0:
            this.curSelectUserType = 11
            this.provinceId = data.id.toString()
            break
          case 1:
            this.curSelectUserType = 12
            this.cityId = data.id.toString()
            break
          case 2:
            this.curSelectUserType = 4
            this.districtId = data.id.toString()
            break
          case 3:
            this.curSelectUserType = 5
            this.groupId = data.id.toString()
            break
          case 4:
            this.curSelectUserType = 6
            this.villageId = data.id.toString()
            break
        }
        this.refreshAllData()
      }else {
        // 如何取消当前选项，就重新选择
        if (this.dirId == data.id){
          this.dirId = data.id
          this.$refs.tree.setCheckedKeys([data.id])
        }
      }

      this.$refs.popover4.showPopper = false;
    },
    handleClick(...args) {
      console.log("click from echarts", ...args);
    },
    handleZrClick(...args) {
      console.log("click from zrender", ...args);
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      // const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      const NOW_MONTHS_AGO = `${year}-${month}`
      return NOW_MONTHS_AGO
    },

    //  获取近一年的年份
    getRecentlyYear() {
      var dataArr = [];
      var data = new Date();
      var year = data.getFullYear();
      data.setMonth(data.getMonth() + 1, 1)
      for (var i = 0; i < 12; i++) {
        data.setMonth(data.getMonth() - 1);
        var m = data.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        dataArr.push({
          name:data.getFullYear() + "年" + (m) + "月",
          value:data.getFullYear() + "-" + (m)
        })
      }
      this.recentlyYearArr = dataArr
    },


    splitStr(photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },

    clickScreenFull() {
      if (!screenfull.isEnabled) {
        this.$message({message: '你的浏览器不支持全屏', type: 'warning'})
        return false
      }
      screenfull.toggle()
    },
    changeScreen() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.changeScreen)
      }
    },
    destroyScreenFull() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.changeScreen)
      }
    },

    refreshAllData(){
      this.loading = true
      this.httpVisitQuestionByMonth()
      this.httpVisitQuestionByType()
      this.httpVisitAbilityByType()
      this.httpVisitAbilityLevel()
      this.httpVisitUserAlone()
      this.httpVisitListByArea()

      this.httpMealAllByMonth()
      this.httpMealSubsidyLevel()
      this.httpMealSubsidyType()
      this.httpMealListByArea()
    },

    // 更新探视走访数据

    // 更新助餐数据
    refreshBarLineMealOption(orginData) {

      if (orginData.length != 12) {
        return
      }
      let xData = []
      let chatData = []
      let amountChatData = []
      let subsidyAmountChartData = []

      for (var i = 0; i < 12; i++) {
        var month = orginData[i].mon.substring(5);
        if (month.indexOf("0") == 0) {
          month = month.substring(1)
        }
        xData.push(month + '月')
        chatData.push(orginData[i].nums ? orginData[i].nums : 0)
        amountChatData.push(orginData[i].count ? orginData[i].count : 0)
        subsidyAmountChartData.push(orginData[i].subsidyAmount ? orginData[i].subsidyAmount : 0)
      }

      this.barLineMealMonth = getBarLineMealMonth(xData, chatData, amountChatData, subsidyAmountChartData)
    },
    refreshPieChartMealLevel(orginData) {

      var chatData = []
      var legendData = []
      orginData.forEach((itemBean) => {
        chatData.push({
          name: itemBean.name,
          value: itemBean.num
        })
        legendData.push(itemBean.name)
      });

      this.pieMealUserLevel = getPieMealUserLevel(legendData, chatData)

    },
    refreshBarChartMealType(orginData) {
      var xData = []
      var chatDataOn = []
      var chatDataOff = []
      var totalNum = orginData[0].num
      var colors = [
        '#24A0FF',
        '#00EFE6',
        '#FFE200',
        '#9E41EC',
        '#07a807',
        '#1684FC',
        '#ffd700',
        '#ffa500',
        '#ff0000'
      ]
      for (var i = 1; i < orginData.length; i++) {
        xData.push(orginData[i].name)
        chatDataOn.push({
          value: orginData[i].num,
          itemStyle: {
            color: colors[i]
          }
        })
        chatDataOff.push(totalNum - orginData[i].num)
      }


      this.barMealUserType = getBarMealUserType(xData, chatDataOn)
    },

    // 探视走访-最近一年问题走势
    httpVisitQuestionByMonth(){
      // 探视走访-最近一年问题走势
      request.httpPost('/v1/business/visit/statistics/question/by/month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId
      }, success => {
        let xData = []
        let chatData = []
        let processingData = []
        let completedData = []
        let orginData = success
        if (orginData.length != 12) {
          return
        }
        for (let i = 6; i < 12; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          chatData.push(orginData[i].count ? orginData[i].count : 0)
          processingData.push(orginData[i].processingQuestionNum)
          completedData.push(orginData[i].count - orginData[i].processingQuestionNum)
        }
        this.barLineVisitQuestion = getBarLineVisitQuestion(xData, chatData, processingData, completedData)
      }, error => {
      })
    },


    httpVisitQuestionByType(){
      // 探视走访-问题分类数量
      request.httpPost('/v1/business/visit/statistics/question/by/type', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        // queryMonth: this.queryMonth,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var xDataLeft = []
        var chatDataLeft = []
        var chatDataLeft2 = []
        var xDataRight = []
        var chatDataRight = []
        var chatDataRight2 = []
        var totalNum = orginData[0].nums
        var colors = [
          '#0092EE',
          '#FF9000'
        ]
        for (var i = 1; i < orginData.length; i++) {
          if (i < 6) {
            xDataLeft.push(orginData[i].name)
            chatDataLeft.push({
              value: -orginData[i].nums + orginData[i].processingQuestionNum,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#00D5EE'
                },
                  {
                    offset: 1,
                    color: '#0092EE'
                  }
                ])
              }
            })
            chatDataLeft2.push({
              value: - orginData[i].processingQuestionNum,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#FF0000'
                },
                  {
                    offset: 1,
                    color: '#FF0000'
                  }
                ])
              }
            })
          } else {
            xDataRight.push(orginData[i].name)
            chatDataRight.push({
              value: orginData[i].nums - orginData[i].processingQuestionNum,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#EABB00'
                },
                  {
                    offset: 1,
                    color: '#FF9000'
                  }
                ])
              }
            })
            chatDataRight2.push({
              value: orginData[i].processingQuestionNum,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#FF0000'
                },
                  {
                    offset: 1,
                    color: '#FF0000'
                  }
                ])
              }
            })
          }

        }
        this.barVisitQuestionType = getBarQuestionType(xDataLeft, xDataRight, chatDataLeft, chatDataLeft2, chatDataRight, chatDataRight2)
      }, error => {
      })
    },


    // 探视走访-行动能力统计
    httpVisitAbilityByType(){
      request.httpPost('/v1/business/visit/statistics/ability/by/type', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var xData = []
        var chatDataOn = []
        var chatDataOff = []
        var totalNum = orginData[0].nums
        for (var i = 1; i < orginData.length; i++) {
          xData.push(orginData[i].name)
          chatDataOn.push(orginData[i].nums)
          chatDataOff.push(totalNum - orginData[i].nums)
        }
        this.barVisitUserActionAbility = getBarPieActionAbility(xData, xData, chatDataOn, chatDataOff)
      }, error => {
      })
    },

    // 探视走访-行动能力统计等级统计
    httpVisitAbilityLevel(){
      request.httpPost('/v1/business/visit/statistics/ability/level', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var chatData = []
        var legendData = []
        orginData.forEach((itemBean) => {
          chatData.push({
            name: itemBean.name,
            value: itemBean.nums
          })
          legendData.push(itemBean.name)
        });
        this.pie = getPie(legendData, chatData)
      }, error => {
      })
    },

    // 探视走访-独居老人统计
    httpVisitUserAlone(){
      request.httpPost('/v1/business/visit/statistics/user/alone', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var chatData = []
        var legendData = []
        var num = 0
        orginData.forEach((itemBean) => {
          if (num>=2){
            chatData.push({
              name: itemBean.name,
              value: itemBean.nums
            })
            legendData.push(itemBean.name)
          }else if (num ==1){
            this.visitAloneUserNum = itemBean.nums
          }
          num++
        });
        this.pieVisitUserAlone = getPieUserAlone(legendData, chatData)
      }, error => {
      })
    },


    // 探视走访按照区域统计列表
    httpVisitListByArea(){
      let userType = parseInt(this.curSelectUserType)
     switch (userType){
       case 11:
         this.httpVisitListByCity()
         break
       case 12:
         this.httpVisitListByDistrict()
         break
       case 4:
         this.httpVisitListByTown()
         break
       case 5:
         this.httpVisitListByVillage()
         break
       case 6:
         break
     }
    },

    // 探视走访-按城市分组统计列表
    httpVisitListByCity(){
      request.httpPost('/v1/business/visit/statistics/list/by/city', {
        provinceId:this.provinceId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
      }, error => {
      })
    },
    // 探视走访-按区县分组统计列表
    httpVisitListByDistrict(){
      request.httpPost('/v1/business/visit/statistics/list/by/district', {
        cityId:this.cityId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
      }, error => {
      })
    },

    // 探视走访-按城镇分组统计列表
    httpVisitListByTown(){
      request.httpPost('/v1/business/visit/statistics/list/by/town', {
        districtId:this.districtId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
      }, error => {
      })
    },




    // 探视走访-按城镇分组统计列表
    httpVisitListByGroup(){
      request.httpPost('/v1/business/visit/statistics/list/by/group', {
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
      }, error => {
      })
    },

    // 探视走访-按乡村分组统计列表
    httpVisitListByVillage(){
      request.httpPost('/v1/business/visit/statistics/list/by/village', {
        groupId: this.groupId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },


    // 助餐-按月份分组统计
    httpMealAllByMonth(){
      request.httpPost('/v2/business/meal/statistics/all/by/month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.refreshBarLineMealOption(success)
      }, error => {
      })
    },

    // 助餐-统计不同用户等级用户数量
    httpMealSubsidyLevel(){
      request.httpPost('/v2/business/meal/statistics/subsidy/level', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.refreshPieChartMealLevel(success)
      }, error => {
      })
    },

    // 助餐-统计老年人补贴类型数量
    httpMealSubsidyType(){
      request.httpPost('/v2/business/meal/statistics/subsidy/type', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.refreshBarChartMealType(success)
      }, error => {
      })
    },



    // 助餐按照区域统计列表
    httpMealListByArea(){
      let userType = parseInt(this.curSelectUserType)
      switch (userType){
        case 11:
          this.httpMealListByCity()
          break
        case 12:
          this.httpMealListByDistrict()
          break
        case 4:
          this.httpMealListByTown()
          break
        case 5:
          this.httpMealListByShop()
          break
        case 6:
          break
      }
    },


    // 助餐-按城市分组统计列表
    httpMealListByCity(){
      request.httpPost('/v2/business/meal/statistics/list/by/city', {
        provinceId: this.provinceId,
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
    // 助餐-按区县分组统计列表
    httpMealListByDistrict(){
      request.httpPost('/v2/business/meal/statistics/list/by/district', {
        cityId: this.cityId,
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpMealListByTown(){
      request.httpPost('/v2/business/meal/statistics/list/by/town', {
        districtId: this.districtId,
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpMealListByGroup(){
      request.httpPost('/v2/business/meal/statistics/list/by/group', {
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按店铺统计列表
    httpMealListByShop(){
      request.httpPost('/v2/business/meal/statistics/list/by/shop', {
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
          this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    httpGroupList(parentGroup){
      request.httpPost('/v1/common/group/all', {
      }, success => {

        if (success){
          var allGroupArr = []
          var groupArr = []

          let name = ""
          let suffix = ""
          if (this.userId == 64921){
            name = "市县区数字养老监管中心(演示)"
            suffix = "(演示)"
          }else {
            name =  parentGroup.name +"数字养老监管中心"
          }

          for (let itemBean of success) {
            var childArr = []
            if (itemBean.child){
              for (let childItem of itemBean.child) {
                var child2Arr = []
                if (childItem.child){
                  for (let child2Item of childItem.child) {
                    child2Arr.push({
                      id:child2Item.id,
                      label: child2Item.name +"数字养老监管中心",
                      parentId: child2Item.id,
                      level: child2Item.level
                    })
                  }
                }
                childArr.push({
                  id:childItem.id,
                  label: childItem.name +"数字养老监管中心" + suffix,
                  parentId: itemBean.id,
                  level: itemBean.level,
                  children: child2Arr,
                })
              }
            }

              groupArr.push({
                id:itemBean.id,
                label: itemBean.name +"数字养老监管中心" + suffix,
                parentId: parentGroup.id,
                level: itemBean.level,
                children: childArr,
            })
          }
          allGroupArr.push({
            id:parentGroup.id,
            label: name,
            level: parentGroup.level,
            children: groupArr,
          })


          this.groupData  = allGroupArr
        }
      }, error => {
      })
    },

    httpGetCurGroupDetail(){
      let id = this.groupId
      switch (parseInt(this.userType)){
        case 11:
          id = this.provinceId
          break
        case 12:
          id = this.cityId
          break
        case 4:
          id = this.districtId
          break
        case 5:
          id = this.groupId
          break
        case 6:
          id = this.villageId
          break
      }

      request.httpPost(`/v1/business/group/detail/${id}`, {
      }, success => {
        if (success){
              if (this.userId == 64921){    // 市县区演示账号
                this.curGroupName = "市县区数字养老监管中心(演示)"
              }else {
                this.curGroupName =  success.name +"数字养老监管中心"
              }

              this.httpGroupList(success)
        }
      }, error => {
      })
    },


    httpRefreshCurData() {
      // 探视走访总体统计数据
      request.httpPost('/v1/business/visit/statistics/total', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        let visitRate = (success && success.totalPersonNum != 0) ? (success.visitPersonNum / success.totalPersonNum * 100).toFixed(2) : 0;
        if (visitRate > 100.00) {
          visitRate = 100.00
        }
        this.visitTotalBean = success
        let completeRate = (success && success.totalPersonNum != 0) ? (success.visitsNum / success.totalPersonNum / 4 * 100).toFixed(2) : 0;
        if (completeRate > 100.00) {
          completeRate = 100.00
        }
        this.gaugeVisitRate = getGauge("本月探视率", visitRate, "#00D1EE")
        this.gaugeVisitCompleteRate = getGauge("本月完成率", completeRate, "#FC9600")
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })


      // 助餐-总体统计数据
      request.httpPost('/v2/business/meal/statistics/total', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        // queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.mealTotalBean = success
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })


      // 助餐-本月总体统计数据
      request.httpPost('/v2/business/meal/statistics/total', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.mealCurMonthTotalBean = success
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })


    }
  }
}
</script>


<style lang="stylus" rel="stylesheet/stylus">
@import "../common/stylus/mixin.styl"
@import "../styles/monitor-center.css"


*,
*::before,
*::after {
  box-sizing: border-box;
}


::-webkit-scrollbar {
  display: none
}


html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
  background-color: #050E2F;
}

.monitor-container {
  width: 100%;
  //height: 100%;
  background-color: #050E2F !important;
  display: flex;
  flex-direction: column;
  align-items center
  justify-content center
}


.full-icon {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index 9999
}


a {
  color: inherit;
  text-decoration: none;
}

h1 {
  margin-bottom: 1em;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
}

h1,
h2 {
  color: white;
  font-weight: 400;
}

h2 {
  margin-top: 2em;
  padding-top: 1em;
  font-size: 1.2em;
  color white !important

  button {
    margin-left: 1em;
    vertical-align: middle;
  }
}

.desc {
  margin-bottom: 3em;
  color: white;

  a {
    color: #42b983;
  }
}

h2 small {
  opacity: 0.7;
}

p small {
  font-size: 0.8em;
  color: white;
}

p {
  line-height: 1.5;
}

pre {
  display: inline-block;
  padding: 0.8em;
  background-color: #f9f9f9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);
  line-height: 1.1;
  color: #2973b7;
}

pre,
code {
  font-family: "Roboto Mono", Monaco, courier, monospace;
}

pre code {
  font-size: 0.8em;
}

.attr {
  color: #e96900;
}

.val {
  color: #42b983;
}


figure {
  display: inline-block;
  position: relative;
  margin: 0;
  border-radius: 8px;
  padding: 0;
  min-width: calc(20vw + 4em);

  .echarts {
    width: 100%;
    //width: 20vw;
    min-width: 200px;
    height: 10vw;
    min-height 200px
  }
}

#logo {
  display: inline-block;
  width: 128px;
  height: 128px;
  pointer-events: none;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;

  &.open {
    display: block;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #404a59;
    max-width: 80vw;
    border: 2px solid #fff;
    border-radius: 3px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 980px) {
  figure.half {
    padding: 1em 1.5em;
    min-width: calc(240px + 3em);

    .echarts {
      width: 28vw;
      min-width: 240px;
      height: 180px;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

@media (max-width: 980px) {
  p {
    display: flex;
    justify-content: center;

    select {
      text-indent: calc(50% - 1em);
    }

    select,
    label {
      border: 1px solid #4fc08d;
      border-radius: 2em;
      background-color: #fff;
      color: #42b983;
      cursor: pointer;
      transition: opacity 0.3s;
    }

    button,
    input,
    select,
    label {
      flex: 1 0;
      margin: 0 0.5em;
      padding: 0;
      line-height: 2.4em;
      max-width: 40vw;
      border-radius: 2px;
      font-size: 0.8em;
    }

    input[type="checkbox"] {
      display: none;

      &:checked + label {
        background: #42b983;
        color: #fff;
      }
    }
  }

  figure {
    width: 100vw;
    margin: 1em auto;
    padding: 1em 0;
    border: none;
    border-radius: 0;
    box-shadow: none;

    .echarts {
      width: 100%;
      min-width: 0;
      height: 80vw;
    }
  }
}


.renderer {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 16px;
  text-align: center;

  button {
    float: left;
    position: relative;
    width: 64px;
    border-radius: 6px;
    border-color: #36485e;
    color: rgba(54, 72, 94, 0.8);
    font-weight: 500;

    &:focus-visible {
      box-shadow: 0 0 1px #36485e;
    }

    &:active {
      background: rgba(54, 72, 94, 0.2);
    }

    &.active {
      z-index: 1;
      background-color: #36485e;
      color: #fff;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .max-h-300px {
    max-height: 300px;
  }
  .overflow-auto {
    overflow: auto;
  }

}
</style>
