<template>
  <div class="love-user-container">
    <div class="love-user-item" v-for="user in userArr" :key="user.id" @click="onClickDetail(user.id)">
<!--      <router-link :to="`/user_detail/${user.id}`" >-->
        <el-image class="avatar" fit="cover" :src="user.avatar"></el-image>
        <div class="user-info">
          <div class="name">
            <span>{{ user.name }}</span>
            <img style="margin-left: 10px" width="20" height="20" src="../images/icon_vip.png"/>
          </div>
          <div class="user-tag-box">
            <span class="user-tag">{{ getAge(user.birthday) }}</span>
            <span class="user-tag">{{ user.city }}</span>
            <span  class="user-tag">{{ user.occupation }}</span>
            <span  class="user-tag">{{ educationArray[user.education] }}</span>
          </div>
        </div>
<!--      </router-link>-->
<!--      <div class="info-box">-->
<!--        <router-link :to="`/msg_send/${user.id}`">-->
<!--          <el-button class="btn" type="primary" icon="el-icon-chat-round" round>联系TA</el-button>-->
<!--        </router-link>-->
<!--      </div>-->
    </div>
    <el-empty style="margin: 160px 320px;" v-if="userArr.length==0" description="暂无关注的嘉宾！"></el-empty>
  </div>
</template>

<script>
import constant from '../constant'

export default {
  name: 'RecUser',
  props: {
    isNeedVip:{
      type:Number,
      default: 0
    },
    userArr: {
      type: Array
    }
  },
  data(){
    return{
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
    }
  },
  methods: {
    onClickDetail (id) {
      if (this.isNeedVip==0){
        this.$message({
          message: '开通钻石会员后，才有查看权限',
          type: 'error',
          offset: 80,
        })
        this.$router.push({
          path: '/vip'
        })
        return
      }
      this.$router.push({
        path: `/user_detail/${id}`
      })
    },
    // 根据出生日期计算年龄周岁 参数2019-12-09 小于10补0
    getAge (birthday) {
      if (!birthday) {
        return ''
      }

      var returnAge = ''
      var mouthAge = ''
      var strBirthdayArr = birthday.split('-')
      var birthYear = strBirthdayArr[0]
      var birthMonth = strBirthdayArr[1]
      var birthDay = strBirthdayArr[2]
      var d = new Date()
      var nowYear = d.getFullYear()
      var nowMonth = d.getMonth() + 1
      var nowDay = d.getDate()
      if (nowYear == birthYear) {
        // returnAge = 0; //同年 则为0岁
        var monthDiff = nowMonth - birthMonth //月之差
        if (monthDiff < 0) {
        } else {
          mouthAge = monthDiff + '个月'
        }
      } else {
        var ageDiff = nowYear - birthYear //年之差
        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay //日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1 + '岁'
            } else {
              returnAge = ageDiff + '岁'
            }
          } else {
            var monthDiff = nowMonth - birthMonth //月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1 + '岁'
            } else {
              mouthAge = monthDiff + '个月'
              returnAge = ageDiff + '岁'
            }
          }
        } else {
          returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
        }
      }
      // return returnAge + mouthAge; //返回周岁年龄+月份
      return returnAge //返回周岁年龄+月份
    },
  }
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

.love-user-container
  display flex;
  flex-direction row;
  flex-wrap wrap;
  width 890px

  .love-user-item:nth-child(4n)
    margin-right 0

  .love-user-item
    width 200px
    height 280px
    margin-right 18px
    margin-top 20px
    position relative
    overflow hidden
    background-color white
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;



    .avatar
      position absolute
      left 15px
      top 15px
      width 170px
      height 170px
      border-radius 4px

    .user-info
      position absolute
      left 15px
      right 15px
      top 194px
      display flex
      flex-direction column

      .name
        font-size 18px
        color #282828
        font-weight bold
        display flex
        flex-direction row
        align-items center

      .user-tag-box
        display flex
        flex-direction row
        flex-wrap wrap;

        .user-tag
          height 16px
          padding 0 5px
          line-height 16px
          font-size 12px
          color #8070F1
          margin-right 8px
          margin-top 6px
          border 1px solid #8070F1
          border-radius 5px

      .mate-desc
        color #2B2B2B
        font-size 14px
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-top 10px

  .info-box
    display none

</style>
