export default function getData(leftYData, rightYData, leftChatData,leftChatData2, rightChatData, rightChatData2) {
  return {
    color:['#0092EE','#FF0000','#FF9000','#A36FE7','#FEA13A'],
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      top:-6, //调整位置
      left:0 ,//调整位置
      data: ['已处理', '已处理1', '未处理'],
      textStyle: {
        color: "#ffffff",
        fontSize: 13
      }
    },
    grid: {
      left: '20%',
      right: '18%',
      top: '7%',
      bottom: '1%',
      containLabel: false
    },
    xAxis: {
      type: 'value',
      show:false,
      splitLine: {
        show: false    // 网格线
      },
    },
    yAxis: [
      {
        type: 'category',
        show:true,
        splitLine: {
          show: false    // 网格线
        },
    // 坐标刻度线
        axisTick: {
          show: false,
          lineStyle: {
            type: 'solid', //y轴分割线类型
            color: 'rgb(250,175,62)',
            width: 2.5,
          },
        },
        // 坐标 轴线
        axisLine: {
          show: true,
          lineStyle: {
            type: 'solid',
            // color: 'rgba(66, 192, 255, .3)',
            color: 'rgba(102,102,102,0.9)',
            width: 2.5
            // color: 'red'
          },
        },
        // //坐标轴样式
        axisLabel: {
          show: true,
          textStyle: {
            color: '#FFFFFF',
            fontSize: 13,
            padding:0,
          },
        },

        data: leftYData
      }, {
        type: 'category',
        show: true,
        splitLine: {
          show: false    // 网格线
        },
        // 坐标 轴线
        axisLine: {
          show: false,
          lineStyle: {
            type: 'solid',
            // color: 'rgba(66, 192, 255, .3)',
            color: 'rgba(102,102,102,0.9)',
            width: 2.5
            // color: 'red'
          },
        },
        // //坐标轴样式
        axisLabel: {
          show: true,
          textStyle: {
            color: '#FFFFFF',
            fontSize: 13,
            padding:0,
          },
        },
        data: rightYData
      }
    ],
    series: [
      {
        name: '已处理',
        type: 'bar',
        stack: 'stack',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius:[15, 0, 0, 15],
            label: {
              show: true, //开启显示数值
              position: 'top', //数值在上方显示
              distance: 0,
              textStyle: {  //数值样式
                color: '#00FFF6',   //字体颜色
                fontSize: 13,  //字体大小\
                fontWeight: 500
              },
              formatter: function (value) {
                return Math.abs(value.data.value);
              }
            }
          }
        },
        barGap:'0%',
        data: leftChatData
      },
      {
        name: '未处理',
        type: 'bar',
        stack: 'stack',
        label: {
          show: true
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius:[15, 0, 0, 15],
            label: {
              show: true, //开启显示数值
              position: 'left', //数值在上方显示
              distance: 10,
              textStyle: {  //数值样式
                color: '#f80b66',   //字体颜色
                fontSize: 13,  //字体大小
                fontWeight: 500
              },
              formatter: function (value) {
                return Math.abs(value.data.value);
              }
            }
          }
        },
        barGap:'0%',
        data: leftChatData2
      },
      {
        name: '已处理1',
        type: 'bar',
        barWidth: 20, // 柱子宽度
        stack: 'stack',
        label: {
          show: true,
          position: 'left'
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius:[0, 15, 15, 0],
            label: {
              show: true, //开启显示数值
              position: 'top', //数值在上方显示
              distance: 10,
              textStyle: {  //数值样式
                color: '#faaf3e',   //字体颜色
                fontSize: 13,  //字体大小
                fontWeight:500
              }
            }
          }
        },
        barGap:'0%',
        data: rightChatData
      },
      {
        name: '未处理',
        type: 'bar',
        barWidth: 20, // 柱子宽度
        stack: 'stack',
        label: {
          show: true,
          position: 'left'
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius:[0, 15, 15, 0],
            label: {
              show: true, //开启显示数值
              position: 'right', //数值在上方显示
              distance: 10,
              textStyle: {  //数值样式
                color: '#f80b66',   //字体颜色
                fontSize: 13,  //字体大小
                fontWeight: 500
              }
            }
          }
        },
        barGap:'0%',
        data: rightChatData2
      }
    ]
  };
}
