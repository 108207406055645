<template>
  <div class="monitor-screen-box">

    <div class="row">
      <div v-if="dataArr.length>=1" class="item" @click="onClickDetail(0)">
<!--        <dv-decoration-1 style="width:100px;height:10px;" />-->
        <loading-breath-light v-if="dataArr[0].deviceStatus==1" class="loading-breath"/>
        <el-image v-if="dataArr[0].deviceStatus==1" class="cover" fit="cover" :src="dataArr[0].coverUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-else class="cover-empty">
          <el-image class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
          <div class="txt-offline">设备不在线</div>
        </div>
        <div class="name">
          {{ dataArr.length > 0 ? dataArr[0].ownerName+ '(' + dataArr[0].deviceName + ')' : '--' }}
        </div>
      </div>
      <div v-else class="item"></div>

      <div v-if="dataArr.length>=2" class="item" @click="onClickDetail(1)">
        <loading-breath-light v-if="dataArr[1].deviceStatus==1" class="loading-breath"/>
        <el-image v-if="dataArr[1].deviceStatus==1" class="cover" fit="cover" :src="dataArr[1].coverUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-else class="cover-empty">
          <el-image class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
          <div class="txt-offline">设备不在线</div>
        </div>
        <div class="name">
          {{ dataArr.length > 1 ? dataArr[1].ownerName + '(' + dataArr[1].deviceName + ')' : '--' }}
        </div>
      </div>
      <div v-else class="item"></div>
      <div v-if="dataArr.length>=3" class="item" @click="onClickDetail(2)">
        <loading-breath-light  v-if="dataArr[2].deviceStatus==1" class="loading-breath"/>
        <el-image v-if="dataArr[2].deviceStatus==1" class="cover" fit="cover" :src="dataArr[2].coverUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-else class="cover-empty">
          <el-image class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
          <div class="txt-offline">设备不在线</div>
        </div>
        <div class="name">
          {{ dataArr.length > 2 ? dataArr[2].ownerName + '(' + dataArr[2].deviceName + ')' : '--' }}
        </div>
      </div>
      <div v-else class="item"></div>
    </div>
    <div class="row">
      <div v-if="dataArr.length>=4" class="item" @click="onClickDetail(3)">
        <loading-breath-light  v-if="dataArr[3].deviceStatus==1" class="loading-breath"/>
        <el-image v-if="dataArr[3].deviceStatus==1" class="cover" fit="cover" :src="dataArr[3].coverUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-else class="cover-empty">
          <el-image class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
          <div class="txt-offline">设备不在线</div>
        </div>
        <div class="name">
          {{ dataArr.length > 3 ? dataArr[3].ownerName + '(' + dataArr[3].deviceName + ')' : '--' }}
        </div>
      </div>
      <div v-else class="item"></div>
      <div v-if="dataArr.length>=5" class="item" @click="onClickDetail(4)">
        <loading-breath-light v-if="dataArr[4].deviceStatus==1" class="loading-breath"/>
        <el-image v-if="dataArr[4].deviceStatus==1" class="cover" fit="cover" :src="dataArr[4].coverUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-else class="cover-empty">
          <el-image class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
          <div class="txt-offline">设备不在线</div>
        </div>
        <div class="name">
          {{ dataArr.length > 4 ? dataArr[4].ownerName +  '(' + dataArr[4].deviceName + ')' : '--' }}
        </div>
      </div>
      <div v-else class="item"></div>
      <div v-if="dataArr.length>=6" class="item" @click="onClickDetail(5)">
        <loading-breath-light v-if="dataArr[5].deviceStatus==1" class="loading-breath"/>
        <el-image v-if="dataArr[5].deviceStatus==1" class="cover" fit="cover" :src="dataArr[5].coverUrl">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <div v-else class="cover-empty">
          <el-image class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
          <div class="txt-offline">设备不在线</div>
        </div>
        <div class="name">
          {{ dataArr.length > 5 ? dataArr[5].ownerName + '(' + dataArr[5].deviceName + ')' : '--' }}
        </div>
      </div>
      <div v-else class="item"></div>
    </div>

  </div>
</template>

<script>
import LoadingBreathLight from "@/components/LoadingBreathLight.vue";

export default {
  name: 'RealTimeMonitorScreen',
  components: {LoadingBreathLight},
  props: {
    dataArr: {
      type: Array
    }
  },
  methods: {
    onClickDetail(index) {
      this.$emit("onClickVideoPlay", this.dataArr[index])
    },
  }
}
</script>

<style lang='stylus' scoped rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"
.monitor-screen-box
  display flex;
  flex-direction column;
  padding-left 16px
  box-sizing border-box

  .row
    display flex
    flex-direction row
    align-items center

  .item
    flex 1
    position relative
    display flex
    flex-direction column
    align-items flex-start
    height 20vh
    margin-right 16px
    margin-bottom 16px
    background-color #014263

    .loading-breath
      position absolute
      right 6px
      top 6px
      z-index 1

    .cover
      width 100%
      flex 1

    .cover-empty
      width 100%
      flex 1
      display flex
      flex-direction column
      align-items center
      justify-content center

      .img-offline
        width 76px
        height 55px

      .txt-offline
        color #919393
        font-size 16px
        margin-top 10px

    .name
      height 34px
      line-height 34px
      font-size 16px
      color #DDDDDD
      padding-left 5px
      width 100%
      box-sizing border-box
      border-top 1px solid #2e547a
      text-align left
      display: -webkit-box; /* 将元素设置为弹性布局 */
      overflow: hidden; /* 超过部分进行裁切 */
      text-overflow: ellipsis; /* 添加省略号表示被裁切的内容 */
      -webkit-line-clamp: 1; /* 限制最多显示一行 */
      -webkit-box-orient: vertical;

  /* 设置垂直方向展开 */


  //transition: all 1.5s ease-in-out;

  .item:hover
    //-webkit-transform:translateY(-100px);
    //transform: translateY(-100px);
    transition: all 1.5s ease-in-out;

</style>
