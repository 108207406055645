<template>
  <!--  <div class="dialog-container">-->
  <el-dialog
    :visible="show"
    custom-class="dialog-play-center-container"
    @open="onOpenPlayCenter"
    @close="onClosePlayCenter"
    :modal-append-to-body="false"
    :append-to-body="true"
    :fullscreen="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="96vw"
    top="15px">
<!--    <el-image class="bg-play-center-img" fit="cover" :src="require('../images/institution/bg_institution_video.png')"/>-->
    <div class="play-center-header-box">
      <el-image class="play-center-bg-header" fit="cover" :src="require('../images/institution/bg_header.png')"/>
      <div class="btn-back" @click="onClickBack">
        <img class="icon-back" src="../images/institution/icon_back_dialog.png" />
        <span>返回大屏</span>
      </div>
      <div class="title-txt">监控播放大厅</div>
      <div class="logo-txt"></div>
<!--      <i class="el-icon-close close" @click="onClickBack"></i>-->
    </div>


    <el-main class="dialog-box-play-center">
      <div class="item-module module-left" v-loading="isLoading"   element-loading-text="加载中..."
           element-loading-background="rgba(0, 0, 0, 0.5)">
        <tab-bar class="video-tab-bar" :data-arr="categoryArr" @onClickItemListener="onVideoTabChangeListener"/>
        <div class="device-box">
          <div v-for="(item, index) in deviceList" class="item-box" :key="index" @click="onClickDetail(item)">
            <div>{{ item.name }}</div>
            <el-image v-if="item.deviceStatus==1" class="cover" fit="cover" :src="item.coverUrl">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div v-else class="cover-empty">
              <el-image   class="img-offline" fit="contain" :src="require('../images/institution/bg_device_offline.png')"/>
              <div class="txt-offline">设备离线了</div>
              <div class="txt-offline-time">离线时间：{{moment(item.activeTime).format('YYYY/MM/DD HH:mm')}}</div>
            </div>

            <div class="name">{{
                (item.ownerName) + '(' + item.deviceName + ')'
              }}
            </div>
          </div>

        </div>
      </div>
      <div class="item-module module-right">
        <institution-list-view :category-list="categoryList" :shop-list="shopList" @onClickItemListener="onClickSelectShopListener"/>
      </div>
    </el-main>
  </el-dialog>
</template>


<script>
import request from "@/http/request";
import TabBar from "@/components/TabBar.vue";
import InstitutionListView from "@/components/InstitutionListView.vue";
import moment from "moment";


export default {
  name: "DialogPlayCenter",
  computed: {
    moment() {
      return moment
    }
  },
  components: {InstitutionListView, TabBar},
  props: {
    show: Boolean,
    shopList: {
      type: Array,
      value: []
    },
    categoryList: {
      type: Array,
      value: []
    },
    captureTime: String
  },
  data() {
    return {
      isLoading: false,
      shopId:null,
      childCategoryId: '',     // 监控设备分类
      currentPage: 1,   // 当前页
      categoryArr:[],   // 分类
      deviceList: []
    }
  },
  mounted() {
    // this.httpActiveDeviceList()

  },

  methods: {
    onVideoTabChangeListener(item) {
      this.childCategoryId = item.id
      this.httpActiveDeviceList()
    },

    onClickSelectShopListener(item){
      this.shopId = item.id
      this.httpActiveDeviceList()
      this.httpDeviceCategoryList()
    },

    onClickDetail(item) {
      this.$emit("onClickVideoPlay", item)
    },

    onClickBack() {
      // this.show = false
      this.$emit('onClose')
    },

    onOpenPlayCenter(){
      this.httpActiveDeviceList()
      this.categoryArr = this.categoryList
    },

    onClosePlayCenter() {
      // this.destroy()
    },

    // 获取摄像头分类
    httpDeviceCategoryList() {
      request.httpPost('/v1/business/device/monitor/category/child', {
        parentId: 101,
        shopId:this.shopId
      }, success => {
        this.categoryArr = success
      }, error => {
      })
    },

    // 获取摄像头
    httpActiveDeviceList() {
      this.isLoading = true
      request.httpPost('/v1/business/device/monitor/list', {
      // request.httpPost('/v1/business/institution_monitor/device/active/latest', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId,
        categoryId: 101,      // 固定101 监控设备分类
        childCategoryId: this.childCategoryId,
        shopId: this.shopId,
        currentPage: this.currentPage,
        pageSize: 10
      }, data => {

        this.deviceList = data.list
        // this.deviceList = data
        this.isLoading = false
        // console.log(this.deviceList)
      }, error => {
        this.isLoading = false
      })
    },

  }
};
</script>
<style scoped lang='stylus'>


.close {
  color: #03FFDE;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  font-size: 20px;
  color: white;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
}

.dialog-box-play-center
  display: flex;
  flex-direction: row;
  padding 0px 20px 20px 20px
  box-sizing border-box
  height 92vh
  z-index 1
  background-color  #070536 ;

  .item-module
    border-left: 1px solid rgba(0, 255, 255, 0.4);
    border-right: 1px solid rgba(0, 255, 255, 0.4);
    border-bottom: 1px solid rgba(0, 255, 255, 0.4);
    border-radius: 2px;
    height: 100%;
    display flex
    flex-direction column

    .video-tab-bar
      margin 20px 0 20px 20px

    .device-box
      width 100%
      display flex
      flex-direction row
      flex-wrap wrap
      padding-left 0.5%
      padding-right 0.5%
      box-sizing border-box

      .item-box
        display flex
        flex-direction column
        align-items flex-start
        width: 19%;
        height: 190px;
        margin-left 0.5%;
        margin-right 0.5%
        margin-bottom 16px
        background-color #014263
        box-sizing  border-box


        .cover
          width 100%
          flex 1
        .cover-empty
          width 100%
          flex 1
          display flex
          flex-direction column
          align-items center
          justify-content center
          .img-offline
            width 70px
            height 50px
          .txt-offline
            color  #919393
            font-size 14px
            margin-top 10px
          .txt-offline-time
            color  #919393
            font-size 13px
            margin-top 6px

        .name
          height 34px
          line-height 34px
          font-size 16px
          color #DDDDDD
          paddling-left 5px
          width 100%
          box-sizing border-box
          text-align left
          border-top 1px solid #2e547a
          display: -webkit-box; /* 将元素设置为弹性布局 */
          overflow: hidden; /* 超过部分进行裁切 */
          text-overflow: ellipsis; /* 添加省略号表示被裁切的内容 */
          -webkit-line-clamp: 1; /* 限制最多显示一行 */
          -webkit-box-orient: vertical;

  /* 设置垂直方向展开 */


  .module-left
    width: 75%;
    margin-right: 20px;
    box-sizing border-box


  .module-right
    width: 25%;


.bg-play-center-img
  position: absolute;
  left: 0;
  top: 0;
  width: 96vw;
  height: 96vh;
  z-index: 0;




.play-center-header-box
  position: relative;
  width: 100%;
  height: 64px;
  .bg-header
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  .btn-back
    position: absolute;
    left 0
    top 6px
    display flex
    flex-direction row
    align-items center
    font-size 16px
    color#00FCF9
    margin-left 20px
    z-index 10
    .icon-back
      width: 29px;
      height: 25px;
      padding: 5px;
      margin-left 0px
      box-sizing border-box
  .title-txt
    position absolute
    top 12px
    left 0
    width 100%
    text-align center
    font-size: 20px;
    color: white;
    background: linear-gradient(0deg, #D7D7D7 0%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
    font-weight: bold

</style>
