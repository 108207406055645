module.exports = {
  K_TOKEN: 'k_token',
  K_APP_ID: 'k_appid',
  K_UID: 'k_uid',
  K_PROVINCE_ID: 'k_province_id',
  K_CITY_ID: 'k_city_id',
  K_DISTRICT_ID: 'k_district_id',
  K_GROUP_ID: 'k_group_id',
  K_VILLAGE_ID: 'k_village_id',
  K_USER_TYPE: 'k_user_type',
  K_PHONE: 'k_phone',
  K_URL_LOGIN_BEFORE: 'k_url_login_before',
  K_TAB_VIEW_BEFORE: 'k_tab_view_before',
  K_IVID: 'klj_ivid',
  educationArray: ["","高中以下" , "高中/中专", "本科/大专", "硕士", "博士/博士后"],
  heightArray: [
    '150cm', '151cm', '152cm', '153cm', '154cm', '155cm', '156cm', '157cm', '158cm', '159cm',
    '160cm', '161cm', '162cm', '163cm', '164cm', '165cm', '166cm', '167cm', '168cm', '169cm',
    '170cm', '171cm', '172cm', '173cm', '174cm', '175cm', '176cm', '177cm', '178cm', '179cm',
    '180cm', '181cm', '182cm', '183cm', '184cm', '185cm', '186cm', '187cm', '188cm', '189cm',
    '190cm', '191cm', '192cm', '193cm', '194cm', '195cm', '196cm', '197cm', '198cm', '199cm',
    '200cm', '201cm', '202cm', '203cm', '204cm', '205cm', '206cm', '207cm', '208cm', '209cm'],
  housingArray: ["","已购房", "未购房", "有能力购房", "和父母同住"],
  monthlyIncomeArray: ["","5千以下", "5千到1万", "1万到2万", "2万到3万", "3万以上"],
  maritalStatusArray: ["","未婚", "离异有小孩", "离异无小孩"],
  carStatusArray: ["","有车", "无车"],
}
