
<template>
  <div class="chart-box">
    <div class="title-box" :style="isHeaderPlaceHolder?'':'position: absolute;'">
      <div class="title-txt">{{title}}</div>
      <div v-if="isShowMore" class="btn-more" @click="onClickMore">{{moreValue}}》</div>
    </div>
<!--    <img  class="bg-h2" src="../images/bg_h2.png" />-->
    <slot>
      <div class="default-txt">图标默认显示区域</div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'ChartComView',
  props: {
    title: String,
    isHeaderPlaceHolder:{
      type: Boolean,
      default: true
    },
    isShowMore:{
      type: Boolean,
      default: false
    },
    moreValue:{
      type:String,
      default: '查看全部'
    }
  },
  methods:{
    onClickMore(){
      this.$emit('onClickMore')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped   lang="less">
 .chart-box{
   width:100%;
   min-height: 208px;
   background: rgba(89, 83, 210, 0.1);
   border: 1px solid rgba(0, 255, 255, 0.4);
   border-radius: 2px;
   display: flex;
   position: relative;
   flex-direction: column;
   box-sizing: border-box;
   .title-box{
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     padding-top: 8px;
     left: 0;
     top: 0;
     .title-txt{
       font-size: 16px;
       color: #FFFFFF;
       z-index: 1;
       height: 30px;
       line-height: 30px;
       background-image: url("../images/institution/bg_sub_title.png"); /* 指定图片路径 */
       background-repeat: no-repeat; /* 防止重复平铺 */
       background-position: left; /* 居中显示图片 */
       background-size: cover; /* 拉伸图片到完全覆盖容器 */
       padding-left: 20px;
     }
     .btn-more{
       padding: 10px 10px 10px 20px;
     }
   }
   .default-txt{
     margin-top: 40px;
     color: #777777;
   }

 }

</style>
