<template>
  <div v-loading="loading" style="width: 1120px;min-height:800px;padding:100px 30px 30px 30px;background-color: white">
<!--    <h1>{{title}}</h1>-->
    <div  v-html="desc">

    </div>
  </div>
</template>


<script type="text/ecmascript-6">

import constant, { monthlyIncomeArray } from '../constant'
import request, { httpGet } from '../http/request'
import { setStore, getStore } from '../utils/storage'

export default {

  data () {
    return {
      title:'',
      desc:'',
      loading: true
    }
  },
  components: {

  },
  created () {
    var id = this.$route.params.id
    request.httpGet(`/api/web/article/${id}`, {}, success => {
      if (success){
        this.desc = success.desc
        this.title = success.title
        this.loading = false
      }
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
      this.loading = false
    })


  },

  methods: {
  }

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"


</style>
