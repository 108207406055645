<template>
  <el-dialog
    :visible="innerShow"
    custom-class="dialog-container"
    @open="onOpenInner"
    @close="onCloseInner"
    :modal-append-to-body="false"
    :append-to-body="true"
    :fullscreen="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="960px"
    top="8vh"

  >
    <!--    <div class="title">测试</div>-->
    <div class="title">卫生巡查</div>

    <i class="el-icon-close close" @click="closeDialogInner"></i>
    <el-main class="dialog-box-hygiene" v-loading="loadingInner"
             element-loading-text="拼命加载中"
             element-loading-background="rgba(0, 0, 0, 0.8)">
      <img class="pic-capture" :src="capturePicData"/>
      <div class="item-box">
        <span class="label-txt">是否存在问题:</span>
        <el-radio-group v-model="form.isHasProblem">
          <el-radio :label="0">未发现问题</el-radio>
          <el-radio :label="1">发现问题</el-radio>
        </el-radio-group>
      </div>
      <div v-if="form.isHasProblem==1" class="item-box">
        <span class="label-txt">问题类型:</span>
        <el-radio-group v-model="form.problemId">
          <el-radio :label="1" class="radio-txt">卫生差</el-radio>
          <el-radio :label="2">用电危险</el-radio>
          <el-radio :label="3">煤气危险</el-radio>
          <el-radio :label="4">用水安全</el-radio>
          <el-radio :label="10">其他</el-radio>
        </el-radio-group>
      </div>
      <div v-if="form.isHasProblem==1&&form.problemId==10" class="item-box">
        <span class="label-txt">其他问题:</span>
        <el-input type="textarea" v-model="form.problemOther" placeholder="请输入其他问题"></el-input>
      </div>
      <div class="item-box">
        <span class="label-txt">巡查描述:</span>
        <el-input type="textarea" v-model="form.description" placeholder="请输入巡查描述"></el-input>
      </div>

      <div class="btn-box">
        <div  class="btn-submit" @click="onSubmit">提交</div>
      </div>


    </el-main>


  </el-dialog>
</template>


<script>
import request from "@/http/request";
import moment from "moment/moment";
import ossClient, {putBase64} from "@/aliyun.oss.client";

var player = null;

export default {
  name: "DialogVideoLive",
  props: {
    innerShow: Boolean,
    ideviceId: Number,
    ideviceData: Object,
    capturePicData:String,
    captureTime: String
  },
  use(plugin, options) {
    chartComView
  },
  data() {
    return {
      isQuestion:1,
      loadingInner: false,
      selectCaptureId: null,
      form: {
        deviceId:'',    // 设备id
        isHasProblem: 0,
        problemId: null,      // 问题类型 设备警告： 0 未知   1，设备警告 2 危险区 3 禁区 4 攀爬   卫生整改：1，卫生差 2，用电危险 3，煤气危险 4 用水安全 10 其他',
        problemOther:'',   // 其他具体问题
        categoryId: 1,   // 固定
        description: '',   // 具体描述
        captureTime: '',
        faceImage: '',
        backgroundImage: ''
      }
    }
  },
  mounted: () => {

  },

  methods: {
    onSubmit() {

      var filePath = 'monitor/capture/' + new Date().getTime() + (Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000) + 'bg.jpeg'
      this.updateCaptureImgAndSubmit(filePath, this.capturePicData)
    },

    updateCaptureImgAndSubmit(filePath, base64){
      this.loadingInner = true
      ossClient.putBase64(filePath, base64).then((result) => {
        // that.form.avatar = ossClient.fileHost + result.name
        this.form.backgroundImage = ossClient.fileHost + result.name
        this.httpAddWarnRecord()
      }).catch(err => {
        this.loadingInner = false
        this.$message({
          message: '图片上传失败',
          type: 'error',
          offset: 80
        })
      })
    },
    formattedHMTime(time) {
      return moment(time).format('HH:mm'); // 这里的'time'为传递到组件的时间数据
    },
    onOpenInner() {

    },

    onCloseInner() {
      // console.group("onClose 组件挂载完毕状态===============》");
      // this.destroy()
    },
    closeDialogInner() {
      // this.$props.show = false
    // this.innerShow = false
      this.$emit('onCloseInner')
    },
    //添加巡查记录
    httpAddWarnRecord() {
      this.loadingInner = true
      this.form.deviceId = this.ideviceId
      this.form.captureTime = this.captureTime
      request.httpPost('/v1/business/institution_monitor/inspect/capture/add', this.form, success => {
        this.$message({
          message: '提交成功',
          type: 'success',
          offset: 80
        })
        // this.innerShow = false
        this.$emit('onCloseInner')
        this.loadingInner = false
      }, error => {
        this.loadingInner = false
        this.$message({
          message: '提交失败',
          type: 'error',
          offset: 80
        })
      })
    },

  }
};
</script>
<style scoped>


.close {
  color: #03FFDE;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  font-size: 20px;
  color: white;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
}

.dialog-box-hygiene {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  background: rgba(89, 83, 210, 0.1);
  border: 1px solid rgba(0, 255, 255, 0.4);
  border-radius: 2px;

  .pic-capture{
    width: 90%;
    margin: 20px 0;
  }

  .item-box{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    color: white;
    width: 90%;
    padding: 20px 0;
    box-sizing: border-box;
    .label-txt{
      color: white;
      font-size: 16px;
      width: 140px;
      box-sizing: border-box;
      text-align: left;
    }

  }
  .btn-submit{
    width: 200px;
    height: 48px;
    line-height: 48px;
    background-color: rgba(0, 252, 249, 0.8);
    border-radius: 4px;
    font-size: 16px;
    margin: 20px 5% 10px auto;
    color: white;
  }
  .btn-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(89, 83, 210, 0.1);
    padding: 12px 20px;
    box-sizing: border-box;

    .btn-item {
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-size: 18px;
      color: white;

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }

}

.el-textarea__inner{
  background-color: #0BA1F8 !important;
}
</style>
