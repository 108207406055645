// import {LinearGradient} from "echarts/graphic";
import * as echarts from 'echarts'


export default function getData(xData, chartData,  processingChartData,completedChartData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    // backgroundColor: "#ffffff",
    // title: {
    //   text: '发现问题数量走势曲线',
    //   left: 'left'
    // },
    // legend: {
    //   data: ['A'],
    //   top: 0,
    //   left: 'center',
    //   backgroundColor: 'white',
    //   z: 100
    // },
    legend: {
      top:2, //调整位置
      right:100 ,//调整位置
      // data: ['助餐次数','助残金额',"运营补贴"],
      textStyle:{
        color: '#fff'
      }
    },
    grid: { //控制边距大小
      left: '12%',
      right: '2%',
      bottom: '10%',
      top: '18%',
      containLabel: false
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    // tooltip: {
    //   show: true,
    //   trigger: 'axis'
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    xAxis:  {
      type: 'category',
      name: "时间",
      // boundaryGap: false,
      data: xData,
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
      },
    },
    yAxis: {
      x: 'left',
      type: 'value',
      position: 'left',
      name: '问题数(个)    ',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
      }
      // show: false
    },
    detail: {
      formatter: '{value}个'
    },
    series: [{
      name: '问题数',
      type: 'line',
      smooth: true,
      markPoint: {
        data: [
          { type: 'max', name: 'Max'},
          { type: 'min', name: 'Min'  }
        ]
      },
      color:'#5581FD',
      data: chartData
    }, {
      name: '已处理',
      type: 'bar',
      barWidth:26,
      yAxisIndex: 0,
      barGap: '0',
      barCategoryGap: '0',
      data: completedChartData,
      label: {
        normal: {
          show: false,
          position: 'inside'
        }
      },
      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius:[2, 2, 2, 2],
          color: '#45cc45',
          label: {
            show: true, //开启显示数值
            // position: 'center', //数值在上方显示
            textStyle: {  //数值样式
              color: '#ffffff',   //字体颜色
              fontSize: 13  //字体大小
            }
          }
        }
      },
    },{
      name: '处理中',
      type: 'bar',
      barWidth:26,
      yAxisIndex: 0,
      data: processingChartData,
      label: {
        normal: {
          show: true,
          position: 'top'
        }
      },
      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius:[2, 2, 2, 2],
          color: '#FFB000',
          label: {
            show: true, //开启显示数值
            // position: 'center', //数值在上方显示
            textStyle: {  //数值样式
              color: '#ffffff',   //字体颜色
              fontSize: 13  //字体大小
            }
          }
        }
      },
    }]
  };
}
