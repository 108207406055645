<template>
  <div class="institution-list-container">

    <div class="item-box item-header">
      <div class="name">机构名称</div>
      <div class="traffic">人流量</div>
      <div class="device-status"><span>设备在线</span><span>/离线</span></div>
      <div class="warn-num">报警数</div>
    </div>
    <div class="item-box" :class="indexSel==-1?'item-sel':'item-double'"
         @click="onClickItemListener(-1, allItem)">
      <div class="name">{{ allItem.name }}</div>
      <div class="traffic">{{ allItem.amount }}</div>
      <div class="device-status"><span class="online">{{ allItem.onLineNum }}</span><span
        class="offline">/{{ allItem.offLineNum }}</span></div>
      <div class="warn-num"><span class="color-red"> {{ allItem.warnNum }}</span></div>
    </div>
    <div v-for="(item, index) in shopList" :key="index" class="item-box"
         :class="indexSel==index?'item-sel':index%2==0?'item-single':'item-double'"
         @click="onClickItemListener(index, item)">
      <div class="name">{{ item.name }}</div>
      <div class="traffic">{{ item.amount }}</div>
      <div class="device-status"><span class="online">{{ item.onLineNum }}</span><span
        class="offline">/{{ item.offLineNum }}</span></div>
      <div class="warn-num"><span class="color-red"> {{ item.warnNum }}</span></div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'InstitutionListView',
  props: {
    shopList: {
      type: Array,
      value: []
    }
  },
  data() {
    return {
      indexSel: -1,       // 选中的index
      allItem: {}
    }
  },
  methods: {
    onClickItemListener(index, item) {
      this.indexSel = index
      this.$emit('onClickItemListener', item); // 触发自定义事件，并传递参数
    }
  },
  mounted() {
    if (this.shopList) {
      let index;
      let amount = 0
      let onLineNum = 0
      let offLineNum = 0
      let warnNum = 0
      for (index in this.shopList) {
        amount = amount + this.shopList[index].amount
        onLineNum = onLineNum + this.shopList[index].onLineNum
        offLineNum = offLineNum + this.shopList[index].offLineNum
        warnNum = warnNum + this.shopList[index].warnNum
      }
      this.allItem = {
        id:null,
        name: '全县',
        amount:amount,
        onLineNum:onLineNum,
        offLineNum:offLineNum,
        warnNum:warnNum
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='stylus'>
.institution-list-container
  display: flex;
  flex-direction: column;
  align-items: center;
  padding 0px
  box-sizing: border-box;

  .item-box
    width: 100%;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    padding: 0 8px;
    font-size: 14px;
    color: white;

    .name
      flex 2
      text-align left
      display: -webkit-box; /* 将元素设置为弹性布局 */
      overflow: hidden; /* 超过部分进行裁切 */
      text-overflow: ellipsis; /* 添加省略号表示被裁切的内容 */
      -webkit-line-clamp: 1; /* 限制最多显示一行 */
      -webkit-box-orient: vertical;

    .traffic
      flex 1

    .device-status
      flex 2

      .online
        color #11FF00

      .offline
        color #FFFFFF

    .warn-num
      flex 1

      .color-red
        color #FF0000


  .item-header
    background linear-gradient(92deg, #0A208A, #01CCFF)
    opacity 0.8
    color #03FFDE
    height 42px !important
    font-weight bold

  .item-single
    background-color: #070536;


  .item-double
    background-color: #090646;


  .item-sel
    background-color: #057FD4;


</style>
