<template>
  <div class="container personal-center-container">
    <div class="personal-center-content">
      <LeftSiderBar :userBean="userBean" navIndex="/msg"></LeftSiderBar>
      <div class="right-content">

        <div class="ih4">
          <div class="tag-line"></div>
          <span class="tag-title">我的消息</span>
        </div>
        <div class="msg-item" v-for="item in msgArr" :key="item.id">
          <el-image style="width:86px;height: 86px;border-radius: 5px;" :src="item.from_user_id==userBean.id?item.to_avatar:item.from_avatar" fit="cover" >
            <img src="../images/icon_avatar_default.png"/>
          </el-image>
          <el-row class="user-info">
            <div class="user-name">
              <span style="margin-right: 8px">{{ item.from_user_id==userBean.id?item.to_name:item.from_name }}</span>
              <img src="../images/icon_vip.png" width="20" height="20"/>
            </div>
            <div class="info">{{ item.from_user_id==userBean.id?item.to_occupation:item.from_occupation }}</div>
            <div class="time">{{ getGapTime(item.updated_at) }}</div>
          </el-row>
          <div class="un-read-num">{{ item.from_unread_nums > 0 ? (item.from_unread_nums) + '封未读' : '' }}</div>
          <el-button type="primary" class="btn-view" @click="onClickReply( item.from_user_id==userBean.id?item.to_user_id:item.from_user_id,item.id)">点击查看</el-button>
        </div>

      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">

import constant, { monthlyIncomeArray } from '../constant'
import request, { httpGet } from '../http/request'
import { setStore, getStore } from '../utils/storage'
import LeftSiderBar from '../components/LeftSiderBar'
import util from '../utils/util'

export default {
  components: {LeftSiderBar},
  data () {

    return {
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
      monthlyIncomeArray: constant.monthlyIncomeArray,
      carStatusArray: constant.carStatusArray,
      housingArray: constant.housingArray,
      recUserArr: [],
      msgArr: [],
      userId: 12,
      userBean: {},
      vipRadio: 3
    }
  },
  created () {

    request.httpGet(`/api/circle/user/get_my_info`, {}, success => {
      this.userBean = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    var currentPage = 1
    request.httpGet(`/api/conversation/list?page=${currentPage}`, {}, success => {
      this.msgArr = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {
    splitStr (photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },
    onClickReply(toid, cid){
      if (this.userBean.is_vip!=1){
        this.$message({
          message: '开通钻石会员后，才能发消息',
          type: 'error',
          offset: 80,
        })
        this.$router.push({
          path: '/vip'
        })
        return
      }
      this.$router.push({
        path: `/msg_reply/${toid}`,
        query: {conversation_id: cid}
      })
    },
    getGapTime (date) {
      //传入的date为‘yyyy-mm-dd hh:mm:ss’形式的
      // var date = date.replace(getRegExp('-','g'),'/');
      var date = date.replace('-', '/')
      var result = '00'
      var timestamp = new Date().getTime() //wxs中不能使用js中的Date()函数，只能使用getDate()来获取当前标准时间，getTime()函数可以用来将标准时间转换为时间戳，单位为ms
      timestamp = parseInt(timestamp / 1000)
      //发表文章的时间戳 s
      var publicstamp = new Date(date).getTime()
      publicstamp = parseInt(publicstamp / 1000)
      //时间差s
      var gap = timestamp - publicstamp
      var time = new Date(date)
      var year = time.getFullYear()
      var month = time.getMonth() + 1
      var day = time.getDay()
      var monthOfDay = time.getDate()
      var hour = time.getHours()
      var minute = time.getMinutes()

      var divstamp = new Date(year + '-' + month + '-' + day + ' 00:00:00').getTime()
      divstamp = parseInt(divstamp / 1000)
      var divgap = timestamp - divstamp
      if (gap < 60) {
        result = '刚刚'
      } else if (divgap < 60 * 60 * 24) {
        result = hour + ':' + minute
      } else if (divgap < 60 * 60 * 24 * 2) {
        result = '昨天'
      } else if (divgap < 60 * 60 * 24 * 7) {
        if (day == 0) {
          result = '星期一'
        } else if (day == 1) {
          result = '星期二'
        } else if (day == 2) {
          result = '星期三'
        } else if (day == 3) {
          result = '星期四'
        } else if (day == 4) {
          result = '星期五'
        } else if (day == 5) {
          result = '星期六'
        } else {
          result = '星期日'
        }

      } else {
        result = month + '月' + monthOfDay + '日'
      }
      return result
    },
    onPageChange (current) {
      console.log(current)
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }

  },

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.personal-center-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);

}


.user-detail-card
  margin 20px 0

.ih4
  display flex
  flex-direction row
  align-items center

  .tag-title
    font-size 18px
    color #1f1f1f

.detail_desc
  margin 20px 20px 50px 20px
  font-size 15px
  line-height 1.5em
  color #666666


.personal-center-content
  width 1200px
  margin-top 30px
  display flex
  flex-direction row
  justify-content space-between

  .right-content
    width 890px
    box-shadow: 0px 2px 6px 2px rgba(192, 192, 192, 0.5);
    border-radius 6px
    padding 20px
    box-sizing border-box
    background-color white

    .msg-item
      display flex
      flex-direction row
      align-items center
      border-bottom 1px solid #f2f2f2
      box-sizing border-box
      padding 20px 0
      margin 0 20px

      .user-info
        display flex
        flex-direction column
        margin-left 15px
        flex 1

        .user-name
          font-size 18px
          color #282828
          font-weight bold
          display flex
          flex-direction row
          align-items center

        .info
          color #666666
          font-size 16px
          margin-top 8px

        .time
          color #666666
          font-size 14px
          margin-top 8px

      .un-read-num
        font-size 16px
        color #ff4666
        margin-right 12px

      .btn-view
        width 130px

</style>
