<template>
  <div class="container personal-center-container">
    <div class="personal-center-content" style="margin-top: 40px">
      <div class="right-content" style="width: 1000px">

        <div class="ih4" style="margin-bottom: 15px;">
          <div class="tag-line"></div>
          <span class="tag-title">找回密码</span>
        </div>

        <el-steps style="margin: 30px 0"  :active="active" align-center>
          <el-step title="手机号验证"></el-step>
          <el-step title="输入新密码"></el-step>
          <el-step title="修改成功"></el-step>
        </el-steps>
        <div style="display: flex;flex-direction: column;align-items: center">
          <div style="color: #333333;margin: 20px 0">{{active==0?'请输入手机号':active==1?'请输入新密码':'密码找回成功！去登录'}}</div>
          <el-form :rules="active==0?rules1:active==1?rules2:null" style="width: 280px" status-icon ref="form" :model="form" label-position="top">
            <el-form-item v-if="active==0" label="" prop="phone">
              <el-input class="item-width" type="tel" v-model="form.phone" placeholder="请输入手机号" autocomplete="off"></el-input>
              <el-form-item  prop="captcha" style="margin-top: 20px;">
                <el-input class="item-width" placeholder="请输入手机验证码" v-model="form.captcha">
                  <template slot="append">
                    <!--  -->
                    <el-button class="btn-captcha" v-if="form.showloginCode" type="primary"
                               @click="getLoginPhoneCode('form')" >{{ isRetry ? '重新发送' : '获取验证码' }}
                    </el-button>
                    <div class="btn-captcha-txt" v-else>{{ form.count }}s</div>
                  </template>
                </el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item  v-if="active==1" label=""  prop="password">
              <el-input class="item-width" type="password" v-model="form.password" placeholder="请输入密码，长度8-20位" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item  v-if="active==1" label="" prop="checkPass">
              <el-input class="item-width" type="password" v-model="form.checkPass" placeholder="请再次输入密码" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="item-width" size="medium" type="primary" @click="onSubmit('form')">{{active==0?'下一步':active==1?'确认修改':'去登录'}}</el-button>
            </el-form-item>
          </el-form>
        </div>


      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">


import constant, { K_TOKEN } from '../../constant'
import request, { httpGet } from '../../http/request'
import { setStore, getStore, removeStore } from '../../utils/storage'
import { CodeToText, TextToCode, regionData } from 'element-china-area-data'

export default {
  components: {  },
  data () {
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('手机号格式不正确'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号格式不正确'))
          }
        }
      }, 100)
    }
    var validateOldPsw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (value.length<8||value.length>20){
          callback(new Error('密码不正确'));
          return
        }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (value.length<8||value.length>20){
          callback(new Error('请输入8-20位密码'));
          return
        }
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      active:0,
      form: {
        phone: '',
        password: '',

        captcha: '', //验证码
        captcha_key:'', // 验证码对应的key
        timer: null, //定时器的开关
        showloginCode: true, //获取成功验证码后倒计时跟获取不成功后没有倒计时按钮的开关
        count: '', //倒计时
        isRetry: false,
      },
      userBean: {},
      rules1: {
        phone: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        captcha: [{
          required: true,
          message: '请输入手机验证码',
          trigger: 'blur'
        },
          { min: 4, max: 6, message: '请输入正确的验证码', trigger: 'blur' }
        ],
      },
      rules2: {
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },

    }
  },
  created () {

    // request.httpGet('/api/circle/user/get_my_info', {}, success => {
    //   this.userBean = success
    //
    //
    // }, error => {
    //   this.$message({
    //     message: '网络请求失败',
    //     type: 'error',
    //     offset: 80
    //   })
    // })

  },

  methods: {
    getLoginPhoneCode (formName) {

      this.$refs[formName].validateField('phone', (phoneValid) => {
        if (!phoneValid) {
          let phone = this.form.phone
          request.httpPost('/api/sms/forget_password', { 'phone': phone }, success => {
            this.$message({
              message: '验证码发送成功',
              type: 'success',
              offset: 80
            })
            this.form.captcha_key=success.key
            // 当验证码发送成功，开始60秒倒计时
            const time = 60
            if (!this.form.timer) {
              this.form.showloginCode = false
              this.form.count = time
              this.form.timer = setInterval(() => {
                if (this.form.count > 0 && this.form.count <= time) {
                  this.form.count -= 1
                } else {
                  this.form.showloginCode = true
                  clearInterval(this.form.timer)
                  this.form.timer = null
                  this.form.isRetry = true
                }
              }, 1000)
            }
          }, error => {
            this.$message({
              message: error,
              type: 'error',
              offset: 80
            })
            console.log(error)
          })
        }else {
          this.$message({
            message: '请先输入正确的手机号！',
            type: 'error',
            offset: 80
          })
        }
      })

    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.active==0){
              this.httpValidatePwd()
            // this.active = 1
          }else if (this.active==1){
              this.httpResetPwd()
          }else if (this.active ==2){
            this.$router.push('/')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },


    httpModifyInfo () {

      var params = {
        avatar: this.form.avatar,
        name: this.form.name,
        gender: this.form.gender,
        birthday: this.form.birthday,
        marital_status: this.form.marital_status,
        height: this.form.height,
        education: this.form.education,
        monthly_income: this.form.monthly_income
      }

      if (this.selectedOptions.length > 0) {
        params.province = CodeToText[this.selectedOptions[0]]
        params.city = CodeToText[this.selectedOptions[1]]
        if (params.province.lastIndexOf('省')>0){
          params.province = params.province.substring(0, params.province.lastIndexOf('省'))
        }
        if (params.province.lastIndexOf('市')>0){
          params.province = params.province.substring(0, params.province.lastIndexOf('市'))
        }
        if (params.city.lastIndexOf('市')>0){
          params.city = params.city.substring(0, params.city.lastIndexOf('市'))
        }
        params.address = params.province + ' ' + params.city
      }

      if (this.options) {
        request.httpPost('/api/user/modify_info', params, success => {
          this.$router.push('/mine/detail_info')
          this.$message({
            message: '保存成功',
            type: 'success',
            offset: 80
          })
        }, error => {
          this.$message({
            message: error,
            type: 'error',
            offset: 80
          })
          console.log(error)
        })
      }
    },
    httpValidatePwd () {
      var params = {
        phone: this.form.phone,
        captcha: this.form.captcha,
        captcha_key: this.form.captcha_key,
        password:'1111111111'
      }
      request.httpPost('/api/user/validate_code', params, success => {
        this.active = 1
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },
    httpResetPwd () {
      var params = {
        phone: this.form.phone,
        password: this.form.password
      }
      request.httpPost('/api/user/find_password', params, success => {
        this.active = 2
        // this.$alert('新密码需要重新登录', '密码找回成功', {
        //   confirmButtonText: '确定',
        //   showClose: false,
        //   callback: action => {
        //     removeStore(K_TOKEN);
        //     this.$router.push('/')
        //   }
        // });
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },

  },

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../../common/stylus/mixin.styl"

.personal-center-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);

}


.user-detail-card
  margin 20px 0


.avatar-uploader .el-upload
  border: 1px solid #d9d9d9;
  border-radius: 60px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .avatar-uploader-icon
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  .avatar
    width: 120px;
    height: 120px;
    display: block;
    object-fit: cover;


.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}



</style>
