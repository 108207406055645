<template>
  <div class="container">

      <div  class="activity-hot-item">
        <el-image class="cover" fit="cover" :src="activity.cover"></el-image>
        <div class="activity-item-content">
          <div class="title">{{activity.title}}</div>
          <div class="info" style="color: #999999">{{ activity.desc }}</div>
          <div class="info">时间：{{ activity.time }}</div>
          <div class="info">地点：{{ activity.address }}</div>
          <div class="info1">{{ activity.join_nums }}人已参加过</div>
          <div class="info1">已报名会员：</div>
          <div class="join-in">
            <el-image v-for="user in joinMembers" :key="user.id" class="join-avatar" fit="cover" :src="user.avatar"></el-image>
            <div class="join-avatar-more">···</div>
            <div class="flex1"></div>
            <el-button class="btn" type="primary" disabled>因疫情暂停报名</el-button>
          </div>

        </div>


    </div>

    <div class="activity-detail">
      <div class="activity-left-content">
        <div class="item-module">
          <div class="i-label">活动介绍</div>
          <div class="i-content">{{activity.desc}}</div>
        </div>
        <div class="item-module">
          <div class="i-label">温馨提示</div>
          <div class="i-content">{{activity.tip}}</div>
        </div>
        <div class="item-module">
          <div class="i-label">活动地点</div>
          <div class="i-content">
            <div>{{activity.address}}</div>
            <baidu-map class="ditu" :scroll-wheel-zoom="true" :center="location" :zoom="zoom" ak="sOwhibZUh1YpYUfDiA8cM27isU6b7Yhw">
              <bm-view style="width: 100%; height:500px; flex: 1"></bm-view>
              <bm-marker
                         :position="{lng:activity.lng, lat: activity.lat}"
                         :dragging="false"
                         animation="BMAP_ANIMATION_BOUNCE">
              </bm-marker>
            </baidu-map>
          </div>
        </div>
      </div>
      <recuser :recUserArr="recUserArr"></recuser>
    </div>


    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="25%"
      center>
      <span>你确定要退出登录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onClickLogout">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">

import constant from '../constant'
import request, { httpGet } from '../http/request'
import { CodeToText } from 'element-china-area-data'
import { setStore, getStore } from '../utils/storage'
import recuser from '@/components/RecUser'
import Vue from 'vue'
// import BaiduMap from 'vue-baidu-map'
let BAIDU_AK="sOwhibZUh1YpYUfDiA8cM27isU6b7Yhw"

import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'

export default {
  components: {
    recuser,
    BaiduMap,
    BmView,
    BmMarker
  },
  data () {

    return {
      centerDialogVisible: false,
      activity: [],
      activeName: 'second',
      token: '',
      userInfo: {},
      recUserArr:[],
      joinMembers:[],
      location: {
        lng: 117.18946,
        lat: 31.770601
      },
      zoom: 12.8,
      addressKeyword: "你我啊",
    }
  },
  created () {
    let id = this.$route.query.id
    request.httpPost('/api/web/activity/detail', {id: id}, data => {
      this.activity = data
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    request.httpPost('/api/web/activity/join/members', {id: id}, data => {
      this.joinMembers = data
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    request.httpPost('/api/web/meet/rec-side-users', {
      type: 1,
      is_circle: 1
    }, success => {
      this.recUserArr = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80,
      })
    })

  },

  methods: {
    onClickLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            phone: this.form.phone,
            password: this.form.password
          }
          request.httpPost('/api/login', params, data => {
            setStore(constant.K_TOKEN, data.token)
            this.token = data.token
            this.userInfo = data
            this.$router.go(0)
          }, error => {
            this.$message({
              message: error,
              type: 'error',
              offset: 80
            })
            console.log(error)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onClickLogout () {
      this.centerDialogVisible = false
      this.token = ''
      this.userInfo = {}
      setStore(constant.K_TOKEN, '')
      this.$router.go(0)

    },

  },

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.container {
  width 100%
  display flex
  flex-direction column
  align-items center
}

.hot-top-header
  width 1120px
  display flex
  flex-direction row
  align-items center

  .line-mid
    flex 1
    height 2px
    background-color #eeeeee
    margin 0 20px

  h1 {
    color #666666 !important
    font-size 38px !important
  }

.activity-hot-item
  width 1120px
  height 340px
  margin-right 12px
  margin-bottom 12px
  display flex
  flex-direction row
  background-color white
  border-radius 5px
  box-sizing border-box
  padding 20px

  .cover
    width 450px
    height 300px
    border-radius 5px

  .activity-item-content
    width 700px
    display flex
    flex-direction column
    margin-left 20px

    .title
      font-size 26px
      color #1f1f1f
      box-sizing border-box

    .info
      font-size 16px
      color #666666
      margin-top 10px
      line-height 1.8
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

    .info1
      font-size 16px
      color #454545
      line-height 1.8
      margin-top 10px

    .join-in
      width 100%
      display flex
      flex-direction row
      align-items center

      .join-avatar
        width 56px
        height 56px
        border-radius 50%
        margin-right 8px

      .join-avatar-more
        font-size 20px
        color #999999
        margin-left 10px
        font-weight 800

      .hor-empty
        width 300px

      .btn
        width: 168px;
        height: 36px;
        line-height 36px
        text-align center
        padding 0
        font-size 16px
        font-weight 400
        margin-left 56px
        margin-top 16px
        border-radius 2px

.activity-detail
  width 1130px
  display flex
  flex-direction row
  .activity-left-content
    width 810px
    margin-right 20px
    margin-top 20px
    display flex
    flex-direction column
    .item-module
      display flex
      flex-direction row
      background-color white
      box-sizing border-box
      padding 20px
      border-radius 10px
      margin-bottom 20px

      .i-label
        width 120px
        font-size: 24px;
        color: #000000;
      .i-content
        flex 1
        font-size: 18px;
        color: #545454;
        text-align left
        .ditu
          width: 100%;
          height: 500px
          border-radius 10px
          margin-top 20px






</style>
