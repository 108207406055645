<template>
  <div class="capture-box">
    <div v-if="dataArr.length>=1" class="item" @click="onClickDetail(0)">
      <el-image class="cover"  fit="cover" :src="dataArr[0].type==1? dataArr[0].faceImage:dataArr[0].backgroundImage"/>
      <div class="center-box">
        <div  class="device-name">{{ dataArr[0].deviceName }}</div>
        <div class="time">{{formattedHMTime(dataArr[0].captureTime)}}</div>
      </div>
      <div class="name">{{dataArr.length>0?dataArr[0].shopName:'--'}}</div>
    </div>
    <div v-else class="item">
    </div>
    <div v-if="dataArr.length>=2" class="item" @click="onClickDetail(1)">
      <el-image class="cover"  fit="cover" :src="dataArr[1].type==1? dataArr[1].faceImage:dataArr[1].backgroundImage"/>
      <div class="center-box">
        <span class="device-name">{{ dataArr[1].deviceName }}</span>
        <span class="time">{{formattedHMTime(dataArr[1].captureTime)}}</span>
      </div>
      <div class="name">{{dataArr.length>1?dataArr[1].shopName:'--'}}</div>
    </div>
    <div v-else class="item">
    </div>
    <div class="item" v-if="dataArr.length>=3" @click="onClickDetail(2)">
      <el-image class="cover"  fit="cover" :src="dataArr[2].type==1? dataArr[2].faceImage:dataArr[2].backgroundImage"/>
      <div class="center-box">
        <span  class="device-name">{{ dataArr[2].deviceName }}</span>
        <span class="time">{{formattedHMTime(dataArr[2].captureTime)}}</span>
      </div>
      <div class="name">{{dataArr.length>2?dataArr[2].shopName:'--'}}</div>
    </div>
    <div v-else class="item">
    </div>
    <div class="item" v-if="dataArr.length>=4" @click="onClickDetail(3)">
      <el-image class="cover"  fit="cover" :src="dataArr[3].type==1? dataArr[3].faceImage:dataArr[3].backgroundImage"/>
      <div class="center-box">
        <span class="device-name">{{ dataArr[3].deviceName }}</span>
        <span class="time">{{formattedHMTime(dataArr[3].captureTime)}}</span>
      </div>
      <div class="name">{{dataArr.length>3?dataArr[3].shopName:'--'}}</div>
    </div>
    <div v-else class="item">
    </div>

  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CapturePhotoView',
  props: {
    dataArr: {
      type: Array
    }
  },
  computed: {

  },
  methods: {
    formattedHMTime(time) {
      // return moment(this.time).format('YYYY-MM-DD HH:mm:ss'); // 这里的'time'为传递到组件的时间数据
      return moment(time).format('HH:mm'); // 这里的'time'为传递到组件的时间数据
    },
    onClickDetail (index) {
      this.$emit("onClickVideoPlay", this.dataArr[index])
    },
  }
}
</script>

<style lang='stylus' scoped rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"
.capture-box
  width 100%
  display flex;
  flex-direction column;
  box-sizing border-box
  .item
    width 22.5%
    display flex
    flex-direction column
    align-items flex-start
    height 14vh
    margin-right 2%
    margin-bottom 12px
    background-color #014263
    box-sizing border-box
    .cover
      width 100%
      height 10vh

    .center-box
      display flex
      width 100%
      flex-direction row
      align-items flex-start
      font-size 11px
      color white
      padding 5px 2px
      box-sizing border-box
      .device-name
        text-align left
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box; //将对象作为弹性伸缩盒子模型显示
        -webkit-box-orient:vertical;//从上到下垂直排列子元素
        -webkit-line-clamp:1; //这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数
      .time
        width 56px
        text-align right

    .name
      font-size 12px
      color white
      margin-left 2px
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box; //将对象作为弹性伸缩盒子模型显示
      -webkit-box-orient:vertical;//从上到下垂直排列子元素
      -webkit-line-clamp:1; //这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数
.item:first-child
   margin-left 2%
.item-empty
   background-color transparent !important
  //transition: all 1.5s ease-in-out;

  .item:hover
    //-webkit-transform:translateY(-100px);
    //transform: translateY(-100px);
    transition: all 1.5s ease-in-out;


</style>
