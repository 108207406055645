<template>
  <div class="container personal-center-container">
    <div class="personal-center-content">
      <LeftSiderBar :userBean="userBean" navIndex="/mine/base_info"></LeftSiderBar>
      <div class="right-content">

        <div class="ih4" style="margin-bottom: 15px;">
          <div class="tag-line"></div>
          <span class="tag-title">个人资料</span>
        </div>



        <el-form status-icon ref="form" :model="form" label-width="100px" label-position="left">
          <el-form-item label="我的头像">
            <el-upload
              class="avatar-uploader"
              action
              :show-file-list="false"
              :http-request="uploadHttp"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="form.avatar" :src="form.avatar"  class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-tooltip  v-if="userBean.avatar&&userBean.avatar_status==0"   effect="dark" content="头像审核通过后，方可展示给用户" placement="bottom">
              <span style="font-size: 14px;color:red;margin-left: 30px;">头像审核中</span>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="我的昵称">
            <el-input class="item-width" v-model="form.name" placeholder="请输入昵称" autocomplete="off"></el-input>
            <el-tooltip  v-if="userBean.name&&userBean.name_status==0"   effect="dark" content="名称审核通过后，方可展示给用户" placement="bottom">
              <span style="font-size: 14px;color:red;margin-left: 20px;">审核中</span>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="我是一位">
            <el-radio-group v-model="form.gender">
              <el-radio label="1">男士</el-radio>
              <el-radio label="2">女士</el-radio>
            </el-radio-group>

          </el-form-item>

          <el-form-item label="我的生日">
            <el-date-picker class="item-width" type="date" value-format="yyyy-MM-dd" placeholder="请选择出生日期"
                            default-value="1995-01-01" v-model="form.birthday"
                            :picker-options="pickerOptions"></el-date-picker>
          </el-form-item>


          <el-form-item label="婚姻状态">
            <el-radio-group v-model="form.marital_status">
              <el-radio label="1">未婚</el-radio>
              <el-radio label="2">离异有小孩</el-radio>
              <el-radio label="3">离异无小孩</el-radio>
            </el-radio-group>
          </el-form-item>


          <el-form-item label="我的身高">
            <el-slider style="width: 500px;margin-bottom: 10px" v-model="form.height" :format-tooltip="formatTooltip"
                       :min="120" :max="230" :marks="marks"></el-slider>
          </el-form-item>

          <el-form-item label="我的常住地">
            <el-cascader
              class="item-width"
              :options="options"
              v-model="selectedOptions"
              :placeholder="addressPlaceholder"
              @change="handleChange"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="我的学历">
            <el-select class="item-width" v-model="form.education" placeholder="请选择你的学历" size="medium">
              <el-option label="高中以下" value="1"></el-option>
              <el-option label="高中/中专" value="2"></el-option>
              <el-option label="本科/大专" value="3"></el-option>
              <el-option label="硕士" value="4"></el-option>
              <el-option label="博士/博士后" value="5"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="我的月收入">
            <el-select class="item-width" v-model="form.monthly_income" placeholder="请选择月收入">
              <el-option label="5千以下" value="1"></el-option>
              <el-option label="5千到1万" value="2"></el-option>
              <el-option label="1万到2万" value="3"></el-option>
              <el-option label="2万到3万" value="4"></el-option>
              <el-option label="3万以上" value="5"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button class="item-width" size="medium" type="primary" @click="onSubmit('form')">保存并继续</el-button>
          </el-form-item>
        </el-form>

      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">

import ossClient from '../../aliyun.oss.client'

import constant from '../../constant'
import request, { httpGet } from '../../http/request'
import { setStore, getStore } from '../../utils/storage'
import LeftSiderBar from '../../components/LeftSiderBar'
import { CodeToText, TextToCode, regionData } from 'element-china-area-data'

export default {
  components: { LeftSiderBar },
  data () {
    return {
      form: {
        avatar:'',
        name: '',
        gender: '1',
        birthday: '',
        marital_status: '0',
        height: 170,
        education: '',
        monthly_income: ''
      },
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
      monthlyIncomeArray: constant.monthlyIncomeArray,
      carStatusArray: constant.carStatusArray,
      housingArray: constant.housingArray,
      marks: {
        130: '130cm',
        170: '170cm',
        220: '220cm'
      },
      addressPlaceholder: '请选择',
      options: regionData,
      selectedOptions: [],

      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },
      userBean: {},
      fileList: [],
      imageUrl:'',

    }
  },
  created () {

    request.httpGet('/api/circle/user/get_my_info', {}, success => {
      this.userBean = success
      this.form.avatar = success.avatar
      this.form.name = success.name
      this.form.gender = success.gender
      this.form.birthday = success.birthday
      this.form.education = success.education==0?'':success.education

      if (success.user_info) {
        this.form.marital_status = success.user_info.marital_status==0?'':success.user_info.marital_status
        if (success.user_info.height) {
          this.form.height = parseInt(success.user_info.height)
        }
        this.form.monthly_income = success.user_info.monthly_income==0?'':success.user_info.monthly_income
      }
      if (success.province) {
        console.log(success.province)
        // let options = [ TextToCode(success.province),TextToCode(success.city)]
        // this.selectedOptions = options
        this.addressPlaceholder = success.province + '/' + success.city

      }

    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {
    splitStr (photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },

    formatTooltip (val) {
      if (val == 120) {
        return '120cm以下'
      } else if (val == 230) {
        return '230cm以上'
      }
      return val + 'cm'
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.name){
            this.httpTxtCensor(this.form.name)
          }else {
            this.httpModifyInfo()
          }

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChange () {
      var loc = ''
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]]
      }
      // alert(loc);
      // console.log(this.selectedOptions)

    },
    httpTxtCensor(txt){
      var params = {
        txt: txt
      }

      request.httpPost('/api/censor/txt', params, success => {
        if (success['conclusionType']==1){
          this.httpModifyInfo()
        }else {
          this.$message({
            message: '您填写的的内容不适合传播，请修改后，重新提交',
            type: 'error',
            offset: 80
          })
        }
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },

    httpModifyInfo () {

      var params = {
        avatar: this.form.avatar,
        name: this.form.name,
        gender: this.form.gender,
        birthday: this.form.birthday,
        marital_status: this.form.marital_status,
        height: this.form.height,
        education: this.form.education,
        monthly_income: this.form.monthly_income,
        is_web:1
      }

      if (this.selectedOptions.length > 0) {
        params.province = CodeToText[this.selectedOptions[0]]
        params.city = CodeToText[this.selectedOptions[1]]
        if (params.province.lastIndexOf('省')>0){
          params.province = params.province.substring(0, params.province.lastIndexOf('省'))
        }
        if (params.province.lastIndexOf('市')>0){
          params.province = params.province.substring(0, params.province.lastIndexOf('市'))
        }
        if (params.city.lastIndexOf('市')>0){
          params.city = params.city.substring(0, params.city.lastIndexOf('市'))
        }
        params.address = params.province + ' ' + params.city
      }

      if (this.options) {
        request.httpPost('/api/user/modify_info', params, success => {
          this.$router.push('/mine/detail_info')
          this.$message({
            message: '保存成功',
            type: 'success',
            offset: 80
          })
        }, error => {
          this.$message({
            message: error,
            type: 'error',
            offset: 80
          })
          console.log(error)
        })
      }
    },

    /**
     * 阿里云OSS上传
     */
    uploadHttp ({ file }) {
      const that = this

      async function multipartUpload () {
        let temporary = file.name.lastIndexOf('.')
        let fileNameLength = file.name.length
        let fileFormat = file.name.substring(
          temporary + 1,
          fileNameLength
        )
        let fileName = ossClient.getFileNameUUID() + '.' + fileFormat
        var filePath = 'user/avatar/' + new Date().getTime() + '-' + fileName

        ossClient.put(filePath, file).then((result) => {
          // 文件上传成功后，获取返回值中的文件名name，并把其放入fileList数组中，表示当前已上传的文件
          // that.fileList.push(result.name);

          // that.form.avatar = ossClient.fileHost + result.name
          that.httpImgCensor(ossClient.fileHost + result.name)
          console.log(result)
        }).catch(err => {
          console.log('err:', err)
          that.$message({
            message: '图片上传失败',
            type: 'error',
            offset: 80
          })
        })

      }

      multipartUpload()
    },
    httpImgCensor(url){
      var params = {
        url: url
      }

      request.httpPost('/api/censor/img', params, success => {
        if (success['conclusionType']==1){
          this.form.avatar = url
        }else {
          this.$message({
            message: '图片审核失败，请重新上传',
            type: 'error',
            offset: 80
          })
        }
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },
    handleAvatarSuccess (res, file) {
      console.log(res)
      console.log(file)
      // this.imageUrl = URL.createObjectURL(file.raw);
    },

    /**
     * 图片限制
     */
    beforeAvatarUpload (file) {
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG && !isJPEG && !isPNG) {
        this.$message({
          message: '上传图片只能是 JPEG/JPG/PNG 格式!',
          type: 'error',
          offset: 80
        })
      }
      if (!isLt2M) {
        this.$message({
          message: '单张图片大小不能超过 500KB!',
          type: 'error',
          offset: 80
        })
      }
      return (isJPEG || isJPG || isPNG) && isLt2M
    },

  },

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../../common/stylus/mixin.styl"

.personal-center-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);

}


.user-detail-card
  margin 20px 0


.avatar-uploader .el-upload
  border: 1px solid #d9d9d9;
  border-radius: 60px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .avatar-uploader-icon
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  .avatar
    width: 120px;
    height: 120px;
    display: block;
    object-fit: cover;


.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}





</style>
