<template>
  <div class="container">
    <div class="login-parent">
      <el-card v-if="token" class="user-box login-box">
        <el-row type="flex" class="row-bg">
          <el-image v-if="userInfo.avatar" style="width: 82px;height: 82px;border-radius: 41px;" class="cover"
                    fit="cover" :src="userInfo.avatar"></el-image>
          <img v-else style="width: 82px;height: 82px;border-radius: 41px;" src="../images/icon_avatar_default.png"/>
          <el-col class="flex1 user-info">
            <el-row type="flex" justify="space-between">
              <el-col class="flex1 user-name"><span>{{ userInfo.name ? userInfo.name : '未填写昵称' }}</span></el-col>
              <button class="signout" @click="centerDialogVisible = true">[退出]</button>
            </el-row>
            <el-row type="flex">
              <img src="../images/icon_vip_on.png" width="20" height="20"/>
              <img class="margin-s" src="../images/icon_vip_msg_un.png" width="20" height="20"/>
            </el-row>
            <el-row>
              <span
                class="info"> {{
                  userInfo.city ? userInfo.city : '城市未填'
                }} | {{
                  userInfo.birthday ? getAge(userInfo.birthday) : '年龄未填'
                }} | {{ userInfo.occupation ? userInfo.occupation : '职业未填' }}</span>
            </el-row>
          </el-col>
        </el-row>
        <el-row type="flex" class="ziliao">
          <div class="flex1">资料整整度{{ getUserInfoCompletion(userInfo) }}%</div>
          <router-link style="text-decoration: none"  to="/mine/base_info">
            <div class="right">编辑资料</div>
          </router-link>
        </el-row>
        <el-progress class="progress" :text-inside="true" :stroke-width="20" :percentage="getUserInfoCompletion(userInfo)"
                     status="exception"></el-progress>
        <el-row class="login-btn-box" type="flex" justify="space-between">
          <div class="btn" v-on:click="onClickLoveMe">
            <img src="../images/icon_view.png" width="20" height="16"/>
<!--            <el-badge is-dot class="txt">谁看过我</el-badge>-->
            <el-badge class="txt">谁看过我</el-badge>
          </div>
          <div class="btn" v-on:click="onClickMsg">
            <img src="../images/icon_msg.png" width="18" height="14"/>
            <el-badge  class="txt">收信箱</el-badge>
          </div>
        </el-row>
      </el-card>
      <el-card v-else class="login-box">
        <div class="login-title">登录风筝缘</div>
        <el-form class="login-form" status-icon :rules="rules" ref="form" :model="form">
          <el-form-item prop="phone" label-width="auto">
            <el-input class="item-width" type="tel" v-model="form.phone" placeholder="请输入手机号"
                      autocomplete="on"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px" prop="password" label-width="auto">
            <el-input class="item-width" type="password" v-model="form.password" placeholder="请输入密码"
                      autocomplete="on"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="form.isRuleChecked" name="type">
            </el-checkbox>
            <span class="login-tip">下次自动登录</span>
            <router-link style="text-decoration: none"  to="/password">
             <div class="btn-forget-pwd" >忘记密码？</div>
            </router-link>

          </el-form-item>
          <el-form-item style="margin-bottom: 10px">
            <el-button class="item-width btn-login" size="medium" type="primary" @click="onClickLogin('form')">立即登录
            </el-button>
          </el-form-item>
        </el-form>
        <router-link style="text-decoration: none" class="more" to="/register">
          <div class="btn-register">免费注册</div>
        </router-link>

      </el-card>

    </div>

    <el-image style="width: 100%; height: 480px;" fit="cover" src="https://img.cxzai.com/fzy/banner/banner.jpg"></el-image>
    <div class="hot-box hot-recommend-box">
      <div class="hot-header">
        <img class="icon" src="../images/icon_cat_hot.png">
        <h2>缘分广场</h2>
        <!--        <el-tabs class="tab" v-model="activeName" @tab-click="handleClick">-->
        <!--          <el-tab-pane label="今日推荐" name="first"></el-tab-pane>-->
        <!--          <el-tab-pane label="最新相亲" name="second"></el-tab-pane>-->
        <!--          <el-tab-pane label="90后" name="third"></el-tab-pane>-->
        <!--          <el-tab-pane label="80后" name="four"></el-tab-pane>-->
        <!--        </el-tabs>-->
        <div class="flex1"></div>
        <router-link class="more" to="/find_fate">
          <span>更多</span> <i class="el-icon-arrow-right" style="font-weight: bold"></i>
        </router-link>
      </div>

      <div class="hot-content" style="min-height: 1000px">
        <div class="hot-item" v-for="user in recUserArr" :key="user.id" >
          <router-link :to="`/user_detail/${user.id}`" >
            <el-image class="avatar" fit="cover" :src="user.avatar"></el-image>
            <span class="name">{{ user.name }} <img v-if="user.is_vip==1" class="rec-vip-logo" src="../images/icon_vip.png"/></span>
          </router-link>
            <div class="info-box">
              <div class="info-name">{{ user.name }} <img v-if="user.is_vip==1" class="rec-vip-logo" src="../images/icon_vip.png"/></div>
              <div class="info">{{ getAge(user.birthday) }}岁|{{ user.user_info.height }}cm|{{ user.city }}</div>

              <el-button  class="btn"  type="primary" @click="onClickSendMsg(user.id)" icon="el-icon-chat-round" round>联系TA</el-button>

            </div>

        </div>
      </div>

    </div>

    <div class="hot-box hot-marry-box">
      <div class="hot-header">
        <img class="icon" src="../images/icon_cat_marry.png">
        <h2>VIP专区推荐</h2>
        <div class="flex1"></div>
        <router-link class="more" :to="{name:'FindFate',query:{isVip:1}}">
          <span>更多</span> <i class="el-icon-arrow-right" style="font-weight: bold"></i>
        </router-link>
      </div>

      <div class="hot-content">
        <div class="hot-item" v-for="user in recVipUserArr" :key="user.id">
          <router-link :to="`/user_detail/${user.id}`">
            <el-image class="avatar" fit="cover" :src="user.avatar"></el-image>
            <span class="name">{{ user.name }} <img v-if="user.is_vip==1" class="rec-vip-logo" src="../images/icon_vip.png"/></span>
          </router-link>
            <div class="info-box">
              <div class="info-name">{{ user.name }} <img v-if="user.is_vip==1" class="rec-vip-logo" src="../images/icon_vip.png"/></div>
              <div class="info">{{ getAge(user.birthday) }}岁|{{ user.user_info.height }}cm|{{ user.city }}</div>

              <el-button class="btn" type="primary" @click="onClickSendMsg(user.id)" icon="el-icon-chat-round" round>联系TA</el-button>

            </div>

        </div>
      </div>

    </div>
<!--    <div class="hot-box hot-activity-box">-->
<!--      <div class="hot-header">-->
<!--        <img class="icon" src="../images/icon_cat_activity.png">-->
<!--        <h2>热门活动</h2>-->
<!--        <div class="flex1"></div>-->

<!--        <router-link class="more" to="activity">-->
<!--          <span>更多</span> <i class="el-icon-arrow-right" style="font-weight: bold"></i>-->
<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="hot-activity-content">-->
<!--        <div v-for="(activity, index) in activityArr" :key="index">-->
<!--          <div v-if="index<4" class="hot-item">-->
<!--            <el-image class="cover" fit="cover" :src="activity.cover"></el-image>-->
<!--            <div class="title">{{activity.title}}}</div>-->
<!--            <div class="info">{{ activity.desc }}</div>-->
<!--            <router-link :to="{path: 'activity_detail', query: { id: activity.id }}">-->
<!--              <el-button class="btn" type="primary">查看详情</el-button>-->
<!--            </router-link>-->
<!--          </div>-->
<!--          -->
<!--        </div>-->
<!--        -->
<!--      </div>-->

<!--    </div>-->

<!--    <div class="hot-box hot-marry-box">-->
<!--      <div class="hot-header">-->
<!--        <img class="icon" src="../images/icon_cat_marry.png">-->
<!--        <h2>幸福时刻</h2>-->
<!--        <div class="flex1"></div>-->
<!--        <a class="more">-->
<!--          <span>更多</span> <i class="el-icon-arrow-right" style="font-weight: bold"></i>-->
<!--        </a>-->
<!--      </div>-->

<!--      <div class="hot-content">-->

<!--        <el-image class="hot-marry-item" v-for="(item, index) in happyArr" :key="index" fit="cover" :src="item.logo"-->
<!--                  lazy></el-image>-->

<!--      </div>-->

<!--    </div>-->
    <div class="vip-index-box">
      <div class="vip-spec-title">
        <div class="vip-title-line"></div>
        <div >会员权益</div>
        <div class="vip-title-line"></div>
      </div>

      <div class="vip-spec">
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_re_msg.png">
          <div class="vip-spec-item-label">解锁收信</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_send_msg.png">
          <div class="vip-spec-item-label">解锁发信</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_rec.png">
          <div class="vip-spec-item-label">首页推荐展示</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_logo.png">
          <div class="vip-spec-item-label">获取vip会员勋章</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_view.png">
          <div class="vip-spec-item-label">查看谁看过我</div>
        </div>
      </div>
      <el-button class="btn-open-vip" @click="doOpenVip(4)">立即去开通</el-button>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="25%"
      center>
      <span>你确定要退出登录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-btn-left" @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" class="dialog-footer-btn-right" @click="onClickLogout">确 定</el-button>
      </span>
    </el-dialog>
    <VerificationCode :visibleDialog="visibleVerify" @closeDialog="closeDialog" @onVerifySuccess="onVerifySuccess"></VerificationCode>
    <el-dialog
      title="提示"
      :visible.sync="vipCenterDialogVisible"
      width="25%"
      center>
      <span style="text-align: center">你还没有开通会员</span>
      <br>
      <span style="text-align: center">开通钻石会员后，才能查看, 是否去开通会员？</span>
      <span slot="footer" class="dialog-footer">
        <el-button  class="dialog-footer-btn-left" @click="vipCenterDialogVisible = false">取 消</el-button>
        <el-button type="primary"  class="dialog-footer-btn-right" @click="doOpenVip(4)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">

import constant from '../constant'
import request, { httpGet } from '../http/request'
import { CodeToText } from 'element-china-area-data'
import { setStore, getStore } from '../utils/storage'
import { getAge, getUserInfoCompletion } from '../utils/util'
import VerificationCode from '../components/VerificationCode'

export default {

  data () {
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('手机号格式不正确'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号格式不正确'))
          }
        }
      }, 100)
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error('请输入8-20位密码'))
          return
        }
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    return {
      centerDialogVisible: false,
      vipCenterDialogVisible: false,
      form: {
        phone: '',
        password: '',
        isRuleChecked: false
      },
      recUserArr: [],
      recVipUserArr: [],
      activityArr: [],
      activeName: 'second',
      happyArr: [],
      rules: {
        phone: [
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ]
      },
      token: '',
      userInfo: {},
      visibleVerify: false,  // 图形验证码是否显示

    }
  },
  created () {
    // let tab = getStore(constant.K_TAB_VIEW_BEFORE)
    // console.log('tab:' + tab)
    this.token = getStore(constant.K_TOKEN)
    if (this.token) {
      request.httpGet('/api/user/get_my_info', {}, data => {
        this.userInfo = data
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }
    request.httpPost('/api/web/meet/rec-users', {
      type: 1,
      is_circle: 1
    }, success => {
      this.recUserArr = success
      this.httpGetRecVipUsers()
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })


    request.httpPost('/api/web/activity/all', {}, data => {
      this.activityArr = data
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    request.httpGet('/api/happy/monent', {}, data => {
      this.happyArr = data
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {
    closeDialog(){
      this.visibleVerify = false
      console.log('visible'+this.visibleVerify)
    },
    onClickLoveMe(){
      if (this.userInfo.is_vip!=1){
        this.vipCenterDialogVisible = true
        return
      }
      this.$router.push('/user/love_me')
    },
    onClickMsg(){
      if (this.userInfo.is_vip!=1){
        this.vipCenterDialogVisible = true
        return
      }
      this.$router.push('/msg')
    },
    onClickLogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visibleVerify = true
          // var params = {
          //   phone: this.form.phone,
          //   password: this.form.password
          // }
          // request.httpPost('/api/login', params, data => {
          //   setStore(constant.K_TOKEN, data.token)
          //   this.token = data.token
          //   this.userInfo = data
          //   this.$router.go(0)
          // }, error => {
          //   this.$message({
          //     message: error,
          //     type: 'error',
          //     offset: 80
          //   })
          //   console.log(error)
          // })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onVerifySuccess(){
      var params = {
        phone: this.form.phone,
        password: this.form.password
      }
      request.httpPost('/api/login', params, data => {
        setStore(constant.K_TOKEN, data.token)
        this.token = data.token
        this.userInfo = data
        this.$router.go(0)
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },
    onClickForgetPwd(){
      this.$router.push('/password')
    },
    onClickLogout () {
      this.centerDialogVisible = false
      this.token = ''
      this.userInfo = {}
      setStore(constant.K_TOKEN, '')
      this.$router.go(0)

    },
    doOpenVip(type){
      this.$router.push('/vip')
    },

    onClickSendMsg(id){
      if (!this.token){
        this.centerDialogVisible = true
        return
      }
      if (this.userInfo.is_vip!=1){
        this.$message({
          message: '开通钻石会员后，才能发消息',
          type: 'error',
          offset: 80,
        })
        this.$router.push({
          path: '/vip'
        })
        return
      }
      this.$router.push(`/msg_send/${id}`)
    },
    httpGetRecVipUsers(){
      var ids = []
      if (this.recUserArr){
        this.recUserArr.forEach((user)=>{
          ids.push(user.id)
        })
      }
      request.httpPost('/api/web/meet/rec-vip-users', {
        type: 1,
        ids:ids
      }, success => {
        this.recVipUserArr = success
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    },
    getAge(birthday){
      return getAge(birthday)
    },

    getUserInfoCompletion(userInfo){
      return getUserInfoCompletion(userInfo)
    }

  },
  components: {VerificationCode}
}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.container {
  width 100%
  display flex
  flex-direction column
  align-items center
}

.login-parent
  position: relative;
  width 1200px


  .user-box
    height 290px !important

    .user-info
      padding-left 8px

      .user-name
        font-size 18px
        color #1f1f1f
        font-weight bold

      .signout
        font-size 16px
        color #8D8787
        background-color white !important
        border none !important

      .info
        font-size 14px
        color #525252
        margin-top 5px

    .ziliao
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      margin-top 28px

      .left
        font-size 16px
        color #1f1f1f

      .right
        font-size 16px
        color #ff4666

    .progress
      margin-top 12px

    .login-btn-box
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      margin-top 28px

      .btn
        display flex
        flex-direction row
        align-items center
        box-sizing border-box
        border-radius 5px
        border 1px solid #FF4666
        padding 0 16px
        height 34px
        line-height 34px

        .txt
          font-size 16px
          color #ff4666
          margin-left 8px
          font-weight normal


.hot-box {
  width 1200px
  box-sizing border-box
  display flex;
  flex-direction column;
  background-color white
  padding 10px 10px 20px 20px
  margin-top 20px
}

.hot-recommend-box {
  margin-top 0px !important
}

.hot-box .hot-header {
  display flex
  flex-direction row
  align-items center
}

.hot-box .hot-header .icon {
  width 48px
  height 35px
  margin-right 10px
  object-fit contain
}

.hot-box .hot-header .tab {
  width 300px
  margin-left 30px
}

.hot-box .hot-header .more {
  color #ff4666
}


.rec-vip-logo{
  margin-left 8px;
  width 18px
  height 18px
  margin-bottom 2px
}

.hot-content
  display flex;
  flex-direction row;
  flex-wrap wrap;

  .hot-item
    width 225px
    height 300px
    margin-right 9px
    margin-bottom 9px
    position relative

    .avatar
      position absolute
      left 0
      top 0
      width 100%
      height 100%
      border-radius 5px

    .name
      position absolute
      font-size 15px
      color white
      left 15px
      bottom 20px
      display flex
      flex-direction row
      align-items center

    .info-box
      display none

  //transition: all 1.5s ease-in-out;

  .hot-item:hover
    .name
      display none

    .info-box
      position absolute
      left 0px
      bottom 0px
      width: 100%;
      height: 122px;
      background: linear-gradient(180deg, rgba(222, 112, 112, 0) 0%, #DE7070 100%);
      display flex
      flex-direction column
      color white
      //-webkit-transform:translateY(-100px);
      //transform: translateY(-100px);
      //transition: all 1.5s ease-in-out;

      .info-name
        font-size 15px
        margin-left 10px
        margin-top 10px
        display flex
        flex-direction row
        align-items center

      .info
        font-size 14px
        margin-left 10px
        margin-top 10px

      .btn
        width: 80px;
        height: 28px;
        line-height 28px
        text-align center
        padding 0
        font-size 12px
        margin-left 72px
        margin-top 12px

        .el-icon-chat-round
          font-size 14px
          font-weight bold


  .hot-marry-item
    width 222px
    height 222px
    margin-right 10px
    margin-bottom 10px
    border-radius 5px

    &:first-child
      width 454px

.hot-activity-content
  display flex;
  flex-direction row;
  flex-wrap wrap;

  .hot-item
    width 280px
    height 345px
    margin-right 12px
    margin-bottom 12px
    display flex
    flex-direction column
    background-color #F6F6F6
    border-radius 5px
    padding-bottom 15px

    .cover
      width 100%
      height 245px
      border-radius 5px

    .title
      font-size 16px
      color #1f1f1f
      padding 10px 10px 0 10px
      box-sizing border-box

    .info
      font-size 14px
      margin-left 10px
      color #666666
      margin-top 6px
      line-height 1.8
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;


    .btn
      width: 168px;
      height: 36px;
      line-height 36px
      text-align center
      padding 0
      font-size 16px
      font-weight 400
      margin-left 56px
      margin-top 16px
      border-radius 2px
.vip-index-box
  width 1200px
  box-sizing border-box
  display flex;
  flex-direction column;
  justify-content center
  align-items center
  padding 10px 10px 20px 20px
  background-color white
  margin-top 20px;
  .vip-spec-title
    width 1200px
    font-size: 36px;
    font-weight: 600;
    color: #A27702;
    text-align center
    display flex
    flex-direction row
    align-items center
    margin-top 30px
    margin-bottom 30px
    .vip-title-line
      height 1px
      flex 1
      background-color #A27702
      margin 0 20px


  .vip-spec
    width 1200px
    display flex
    flex-direction row
    align-items center
    justify-content space-around
    .vip-spec-item
      display flex
      flex-direction column
      align-items center
      .vip-spec-item-logo
        width 96px
        height 96px
      .vip-spec-item-label
        font-size: 20px;
        color: #3B3B3B;
        margin-top 28px
  .btn-open-vip
    width 180px
    height 60px
    background linear-gradient(90deg, #FFF353 0%, #F2BB00 100%)
    border-radius 35px
    margin 53px 0 0 18px
    font-size 24px
    font-weight 400
    color #2A2A2A
    margin-bottom 30px
</style>
