function random() {
  return Math.round(300 + Math.random() * 700) / 10;
}

export default function getData(xData, chartData, amountChartData, subsidyAmountChartData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    // backgroundColor: "#ffffff",
    // title: {
    //   text: '发现问题数量走势曲线',
    //   left: 'left'
    // },
    legend: {
      top:2, //调整位置
      right:10 ,//调整位置
      // data: ['助餐次数','助残金额',"运营补贴"],
      textStyle:{
        color: '#fff'
      }

    },
    grid: { //控制边距大小
      left: '10%',
      right: '10%',
      bottom: '8%',
      top: '20%',
      containLabel: false
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      name: "时间",
      // boundaryGap: false,
      data: xData,
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
      },
    },
    yAxis: [{
      x: 'left',
      type: 'value',
      position: 'left',
      name: '助餐次数(次)    ',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
        formatter: function(value) {
          if (value >= 10000 && value < 9999500) { // 根据需求调整条件
            return Math.round((value / 10000)) + 'w';
          } else if (value >= 1000000) {
            return Math.round((value / 1000000)) + 'm';
          } else {
            return value;
          }
        },
      }
      // show: false
    }, {
      x: 'right',
      name: '           金额(元)',
      type: 'value',
      position: 'right',
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
        formatter: function(value) {
          if (value >= 10000 && value < 9999500) { // 根据需求调整条件
            return Math.round((value / 10000)) + 'w';
          } else if (value >= 1000000) {
            return Math.round((value / 1000000)) + 'm';
          } else {
            return value;
          }
        },
      },
      // show: false
    }],
    detail: {
      formatter: '{value}个'
    },
    series: [{
      name: '助餐金额',
      type: 'bar',
      barWidth:12,
      yAxisIndex: 1,
      data: amountChartData,
      label: {
        normal: {
          show: true,
          position: 'top'
        }
      },
      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius:[2, 2, 2, 2],
          color: '#5581FD',
          label: {
            show: true, //开启显示数值
            // position: 'center', //数值在上方显示
            textStyle: {  //数值样式
              color: '#ffffff',   //字体颜色
              fontSize: 13  //字体大小
            },
            formatter: function(params) {
              if (params.value >= 10000 && params.value < 9999500) { // 根据需求调整条件
                return Math.round((params.value / 10000)) + 'w';
              } else if (params.value >= 1000000) {
                return Math.round((params.value / 1000000)) + 'm';
              } else {
                return params.value;
              }
            },
          }
        }
      },
    }, {
      name: '补贴金额',
      type: 'bar',
      barWidth:12,
      yAxisIndex: 1,
      barGap: '0',
      barCategoryGap: '0',
      data: subsidyAmountChartData,
      label: {
        normal: {
          show: false,
          position: 'inside'
        }
      },
      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius:[2, 2, 2, 2],
          color: '#00F2EB',
          label: {
            show: true, //开启显示数值
            // position: 'center', //数值在上方显示
            textStyle: {  //数值样式
              color: '#ffffff',   //字体颜色
              fontSize: 13  //字体大小
            },
            formatter: function(params) {
              if (params.value >= 10000 && params.value < 9999500) { // 根据需求调整条件
                return Math.round((params.value / 10000)) + 'w';
              } else if (params.value >= 1000000) {
                return Math.round((params.value / 1000000)) + 'm';
              } else {
                return params.value;
              }
            },

          }
        }
      },
    },{
      name: '助餐次数',
      type: 'line',
      smooth: true,
      showAllSymbol: true,
      symbol: 'emptyCircle',
      symbolSize: 12,
      markPoint: {
        data: [
          {type: 'max', name: 'Max'},
          {type: 'min', name: 'Min'}
        ],
      },
      data: chartData
    }]
  };
}
