<template>
  <div class="vip-item">
    <div class="top-box">
      <span class="name">钻石会员</span>
      <span class="month">自助服务，免费看信、发信，各项特权祝你找</span>
      <img class="icon" src="../images/vip/icon_vip_white.png">
    </div>
    <el-radio-group class="vip-radio-group" v-model="vipRadio" text-color="#F7B500">
      <el-radio class="vip-radio" :label="4">
        <div class="vip-money">￥288</div>
        <span class="vip-desc">12个月 日均1.06元</span></el-radio>
      <el-radio class="vip-radio" :label="2">
        <div class="vip-money">￥188</div>
        <span class="vip-desc">6个月  日均1.58元</span></el-radio>
      <el-radio class="vip-radio" :label="1">
        <div class="vip-money">￥88</div>
        <span class="vip-desc">1个月  日均2.93元</span></el-radio>
    </el-radio-group>
    <el-button class="btn" @click="doOpenVip" round>立即开通</el-button>
  </div>
</template>

<script>
export default {
  name: 'Vip',
  props: {

  },
  data(){
    return {
      vipRadio: 4,
    }
  },
  methods: {
    doOpenVip(){
      console.log(this.vipRadio)
      this.$router.push({
        path: '/vip',
        query:{
          level: this.vipRadio
        }
      })
    }
  }
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

.vip-item
  width 290px
  height 333px
  display flex
  flex-direction column
  align-items center
  background-color white
  box-shadow: 0px 2px 6px 2px rgba(192, 192, 192, 0.5);
  border-radius: 10px;

  .top-box
    position relative
    width 100%
    height 124px
    background linear-gradient(170deg, #FFFD0F 0%, #F7B500 100%)
    border-radius 10px

    .name
      font-size: 32px;
      font-weight: 600;
      color: #A27702;
      position absolute
      left 66px
      top 12px

    .month
      font-size: 16px;
      color: #A27702;
      position absolute
      left 20px
      right 20px
      top 64px
      text-align center

    .icon
      width 82px
      height 73px
      position absolute
      right 0
      bottom 0

  .vip-radio-group
    padding 10px 20px

    .vip-radio
      width 250px
      height 38px
      line-height 38px
      font-size 16px
      color #545454
      margin-right 0 !important

      .vip-money
        width 68px !important
        font-size 18px
        font-weight bold
        margin-right 15px
        display inline-block

      .vip-desc
        font-size 16px

      &.is-checked
        .el-radio__input.is-checked .el-radio__inner
          border-color: #F7B500;
          background: #F7B500;

        .vip-money
          color #F7B500

        .vip-desc
          color #F7B500


  .btn
    width: 168px;
    height: 40px;
    text-align center
    padding 0
    margin-top 12px
    background: linear-gradient(78deg, #FFF026 0%, #FFCC1E 100%);
    font-size: 16px;
    font-weight: 500;
    color: #2A2A2A;


</style>
