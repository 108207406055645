import router from "./router";
import constant from "./constant"
import { setStore, getStore, removeStore } from './utils/storage'

 /**
  * to：router即将进入的路由对象。
  from：当前导航正要离开的路由。
  next：一个function，一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  next() : 进行管道中的下一个钩子。如果全部钩子执行完了，则导航的状态就是 confirmed （确认的）。
  next(false) : 中断当前的导航。如果浏览器的 URL 改变了（可能是用户手动或者浏览器后退按钮），那么 URL 地址会重置到 from 路由对应的地址。
  next('/') 或者 next({ path: '/' }): 跳转到一个不同的地址。当前的导航被中断，然后进行一个新的导航。 你可以向 next 传递任意位置对象，且允许设置诸如 replace: true、name: ‘home' 之类的选项以及任何用在 router-link 的 to prop 或 router.push 中的选项，注意，next可以通过query传递参数。
  next(error) : (2.4.0+) 如果传入 next 的参数是一个 Error 实例，则导航会被终止且该错误会被传递给 router.onError() 注册过的回调。
  */
 router.beforeEach((to, from, next) => {
   /* 路由发生变化修改页面title */
   if (to.meta.title) {
     document.title = to.meta.title
   }

   //根据字段判断是否路由过滤
   if (to.matched.some(record => record.meta.auth)) {

     if (getStore(constant.K_TOKEN) !== null) {
       next()
     } else {
       //防止无限循环
       if (to.name === 'login') {
         next();
         return
       }
       setStore(constant.K_URL_LOGIN_BEFORE, to.fullPath)
       next({
         path: '/login',
       });
     }
   } else {
     next()//若点击的是不需要验证的页面,则进行正常的路由跳转
   }
 });
