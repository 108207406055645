export default function getData(legendData, chartData){
  return{
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    toolbox: {
      show: true,
      top:'5',
      right:'5',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}人<br/> 占比：({d}%)"
    },
    color: [
      '#FE2B78',
      '#9E41EC',
      // '#04AEC8',
      '#00EFE6',
      '#00EFE6',
      '#FFE200',
      '#9E41EC'
    ],

    // grid: {
    //   left: '0%',
    //   right: '0%',
    //   top:'2%',
    //   bottom: '0%',
    //   containLabel: false
    // },
    legend: {
      data: legendData,
      itemGap: 16, //间隔
      padding: [
        0, 30, 0,0
      ],
      itemWidth: 26,
      itemHeight: 15,
      bottom: '3%',
      left:'5%',
      textStyle: {
        color:'#fff',
        fontSize: 12
      }
    },

    // label: {
    //   fontSize: 14,
    //   formatter: '年龄段：{b}\n人数：{c}'
    // },
    series: [
      {
        // center: ['50%', '30%'],
        // radius: ['30%', '50%'],

        radius: ['20%', '56%'],
        center: ['50%', '46%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 4,
          borderColor: '#04133F',
          borderWidth: 1
        },
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowOffsetX: 0
          }
        },
        type: "pie",
        name: '儿童类型分布：',
        label: {
          // alignTo: 'edge',
          formatter: '{per|{d}%}\n{name|{b}}: {time|{c}人}',
          // formatter: '{a|{a}}{abg|}\\n{hr|}\\n  {b|{b}：}{c}  {per|{d}%}',
          minMargin: 0,
          edgeDistance: 0,
          lineHeight: 20,
          color: '#fff',
          rich: {
            per:{
              fontSize: 18,
              color: '#00EFE6'
            },
            name: {
              fontSize: 12,
              color: '#ffffff'
            },
            time: {
              fontSize: 12,
              color: '#ffffff'
            }
          }
        },
        labelLine: {
          length: 15,
          length2: 15,
          maxSurfaceAngle: 145
        },
      }
    ]
  }

};
