import moment from "moment/moment";

function formatData(dataArr) {
  let resultDataArr = [];
  for (let i = 0; i< dataArr.length; i ++){
    let itemArr = []
    let item = dataArr[i]

    itemArr.push('<span style="color:#FF0101;">'+item.questionTypeName + '</span>')
    itemArr.push('<span style="color:'+ (item.status==2?'#11FF00':'#FFEA01')  + ';">' + (item.status==2?'已处理':'处理中') + '</span>')
    itemArr.push(moment(item.createTime).format('HH:mm  MM月DD日') )  //
    itemArr.push(item.recordedName)  //
    itemArr.push(item.townName +'.' +item.villageName ) //
    itemArr.push(item.recorderName) //
    itemArr.push(item.recorderPhone) //

    resultDataArr.push(itemArr)
  }
  return resultDataArr;
}
export default function getData(dataArr) {
  return {
    header: ['问题类型', '状态', '发现时间', '儿童姓名', '所在乡镇', '发责任', '电话'],
    // data: [
    //   ['<span style="color:#37a2da;">行1列1</span>', '行1列2', '行1列3'],
    //   ['行2列1', '<span style="color:#32c5e9;">行2列2</span>', '行2列3'],
    //   ['行3列1', '行3列2', '<span style="color:#67e0e3;">行3列3</span>'],
    //   ['行4列1', '<span style="color:#9fe6b8;">行4列2</span>', '行4列3'],
    //   ['<span style="color:#ffdb5c;">行5列1</span>', '行5列2', '行5列3'],
    //   ['行6列1', '<span style="color:#ff9f7f;">行6列2</span>', '行6列3'],
    //   ['行7列1', '行7列2', '<span style="color:#fb7293;">行7列3</span>'],
    //   ['行8列1', '<span style="color:#e062ae;">行8列2</span>', '行8列3'],
    //   ['<span style="color:#e690d1;">行9列1</span>', '行9列2', '行9列3'],
    //   ['行10列1', '<span style="color:#e7bcf3;">行10列2</span>', '行10列3']
    // ],
    data: formatData(dataArr),
    index: true,
    rowNum:6,
    // headerBGC:'linear-gradient(92deg, #0A208A, #01CCFF)',
    oddRowBGC:'rgba(89, 83, 210, 0.12)',
    evenRowBGC:'rgba(89, 83, 210, 0)',
    headerBGC:'rgba(0,0,0,0)',
    columnWidth:[50],
    // columnWidth: [200, 200,200,200,200,200,200],
    align: ['center','center','center','center','center','center','center']
  }
}
