import moment from "moment/moment";

function formatData(dataArr) {
  let resultDataArr = [];
  for (let i = 0; i< dataArr.length; i ++){
    let itemArr = []
    let item = dataArr[i]

    itemArr.push(item.name)
    itemArr.push(item.totalPersonNum)
    itemArr.push(item.visitsNum)  //
    itemArr.push((item.totalPersonNum>0?(item.visitPersonNum/item.totalPersonNum*100).toFixed(2):0) + '' +'%')  //
    itemArr.push(item.allQuestionNum)  //
    itemArr.push('<span style="color:#FF0000;">' +item.completedQuestionNum + '</span>') //
    itemArr.push('<span style="color:#11FF00;">'+ (item.allQuestionNum - item.completedQuestionNum) +   '</span>') //

    resultDataArr.push(itemArr)
  }
  return resultDataArr;
}
export default function getData(dataArr) {
  return {
    header: ['乡镇', '人数', '随访次数', '完成率', '发现问题', '已处理', '处理中'],
    // data: [
    //   ['<span style="color:#37a2da;">行1列1</span>', '行1列2', '行1列3'],
    //   ['行2列1', '<span style="color:#32c5e9;">行2列2</span>', '行2列3'],
    //   ['行3列1', '行3列2', '<span style="color:#67e0e3;">行3列3</span>'],
    //   ['行4列1', '<span style="color:#9fe6b8;">行4列2</span>', '行4列3'],
    //   ['<span style="color:#ffdb5c;">行5列1</span>', '行5列2', '行5列3'],
    //   ['行6列1', '<span style="color:#ff9f7f;">行6列2</span>', '行6列3'],
    //   ['行7列1', '行7列2', '<span style="color:#fb7293;">行7列3</span>'],
    //   ['行8列1', '<span style="color:#e062ae;">行8列2</span>', '行8列3'],
    //   ['<span style="color:#e690d1;">行9列1</span>', '行9列2', '行9列3'],
    //   ['行10列1', '<span style="color:#e7bcf3;">行10列2</span>', '行10列3']
    // ],
    data: formatData(dataArr),
    index: true,
    rowNum:7,
    headerBGC:'rgba(0,0,0,0)',
    // headerBGC:'linear-gradient(92deg, #0A208A, #01CCFF)',
    oddRowBGC:'rgba(89, 83, 210, 0.12)',
    evenRowBGC:'rgba(89, 83, 210, 0)',
    columnWidth: [40,100, 100,110,120,110,100,100],
    align: ['center','center','center','center','center','center','center','center']
  }
}
