<template>
  <div class="user-center-left-content">
    <el-image fit="cover" style="margin-top: 20px; width: 120px; height: 120px;border-radius: 60px;" :src="userBean.avatar">
      <div slot="error" class="image-slot">
        <img src="../images/icon_avatar_default.png"/>
      </div>
    </el-image>

    <el-tooltip  v-if="userBean.avatar&&userBean.avatar_status==0" style="margin-top: -20px;"  effect="dark" content="头像审核通过后，方可展示给用户" placement="bottom">
      <el-button style="font-size:14px;padding:0;margin:0;height:24px;line-height:24px;z-index: 99;color: white;background-color: red;text-align:center;width: 90px;border-radius: 60px;">审核中</el-button>
    </el-tooltip>
    <el-row class="user-info">
      <span>{{ userBean.name ? userBean.name : '未填写昵称' }}</span>
      <el-tooltip  v-if="userBean.name&&userBean.name_status==0"   effect="dark" content="名称审核通过后，方可展示给用户" placement="bottom">
        <span style="font-size: 13px;color:#aaaaaa;">(审核中)</span>
      </el-tooltip>
      <img style="margin-left: 10px;" src="../images/icon_vip.png" width="20" height="20"/>
    </el-row>
    <el-row type="flex" class="ziliao">
      <div class="flex1">资料整整度{{getUserInfoCompletion()}}%</div>
      <router-link style="text-decoration: none"  to="/mine/base_info">
        <div class="right">编辑资料</div>
      </router-link>
    </el-row>
    <el-progress class="progress" :text-inside="true" :stroke-width="20" :percentage="getUserInfoCompletion()"
                 status="exception"></el-progress>

    <el-menu
      :default-active="navIndex"
      class="el-menu-vertical"
      @open="handleOpen"
      @close="handleClose"
      @select="onMenuSelect"
      text-color="#282828"
      active-text-color="#FF4666"
      :router="true">
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span>个人资料</span>
        </template>
        <el-menu-item index="/mine/base_info">基本资料</el-menu-item>
        <el-menu-item index="/mine/detail_info">详细资料</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>交友中心</span>
        </template>
        <el-menu-item index="/user/my_love">我喜欢的</el-menu-item>
        <el-menu-item index="/user/love_me">喜欢我的</el-menu-item>
      </el-submenu>
      <el-menu-item index="/msg">
        <i class="el-icon-document"></i>
        <span slot="title">消息中心</span>
      </el-menu-item>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-setting"></i>
          <span>系统设置</span>
        </template>
        <el-menu-item index="/mine/modfiy_pwd">修改密码</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import constant from '../constant'
import util from '../utils/util'

export default {
  name: 'LeftSiderBar',
  props: {
    userBean: Object,
    navIndex: String
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
  },

  methods: {
    splitStr (photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },
    // 根据出生日期计算年龄周岁 参数2019-12-09 小于10补0
    getAge (birthday) {
      return util.getAge(birthday);

    },
    getUserInfoCompletion () {
      return util.getUserInfoCompletion(this.userBean)

    },

    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    onMenuSelect(key, keyPath){
      console.log(key, keyPath)
      // this.$router.push(key)
    }
  }
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

.user-center-left-content
  padding 20px
  box-sizing border-box
  width 290px
  display flex
  flex-direction column
  align-items center
  background-color white
  border-radius 6px
  box-shadow: 0px 2px 6px 2px rgba(192, 192, 192, 0.5);

  .user-info
    font-size 20px
    color #1f1f1f
    font-weight bold
    display flex
    flex-direction row
    align-items center
    margin-top 15px

  .ziliao
    width 250px
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    margin-top 36px

    .left
      font-size 16px
      color #333333

    .right
      font-size 16px
      color #ff4666

  .progress
    width 250px
    margin-top 12px

  .el-menu-vertical
    width 290px
    margin-top 50px

    .el-submenu__title
      font-size 16px !important

    .el-menu-item
      font-size 16px !important

    .el-menu-item:focus, .el-menu-item:hover
      outline: 0;
      background-color: rgba(140, 197, 255, 0.2) !important;
      color: #FF4666 !important;

    .el-menu-item:hover
      text-decoration: none !important;


</style>
