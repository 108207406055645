
function  getSeries(dataArr){
  let serData = []
  dataArr.forEach(item => {
    serData.push({
      name: item.name,
      type: "line",
      showSymbol: true,
      symbolSize: 6,
      symbol: "circle",
      lineStyle: {
        color: item.color,
      },
      itemStyle: {
        color: item.color,
        borderColor: item.color,
        borderWidth: 1,
      },
      data: item.data,
    })
  })
  return serData;
}

export default function getData(xData, chartDataArr) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    toolbox: {
      show: true,
      top:'5',
      right:'5',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    legend: {
      itemGap: 10, //间隔
      padding: [
        0, 40, 0,0
      ],
      itemWidth: 26,
      itemHeight: 15,
      top: '50px',
      left:'20%',
      textStyle: {
        color:'#fff',
        fontSize: 12
      }
    },
    grid: { //控制边距大小
      left: '30px',
      right: '2%',
      bottom: '4%',
      top: '120px',
      containLabel: true
    },
    // tooltip: {
    //   show: true,
    //   trigger: 'axis'
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      name: "时间",
      // boundaryGap: false,
      data: xData,
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
      },
    },
    yAxis: [{
      type: 'value',
      name: '问题数(个)',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding:2,
        },
      }
      // show: false
    }],
    detail: {
      formatter: '{value}个'
    },
    series: getSeries(chartDataArr)
  };
}
