<template>
  <div class="h3-box">
    <img  class="bg-h3" src="../images/bg_h3_left.png" />
    <div class="title-txt">{{title}}</div>
    <img  class="bg-h3" src="../images/bg_h3_right.png" />
  </div>
</template>

<script>
export default {
  name: 'H3View',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.h3-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.h3-box .bg-h2{
  width: 104px;
  height: 22px;
}
.h3-box .title-txt{
  font-size: 24px;
  font-weight: bold;
  color: #FFFFFF;
  margin: 0 15px;
  z-index: 1;
}
</style>
