<template>

  <div class="container">

    <div class="full-icon">
<!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click"/>-->
      <i :class="isFullscreen?'el-icon-top-left':'el-icon-full-screen'"  style="color: white" @click="click"></i>

    </div>

    <div class="left-box">
      <v-chart
        autoresize
        :option="option_column"
        style="height: 400px"
        theme="ovilia-green">

      </v-chart>

    </div>
    <div class="center-box">center</div>
    <div class="right-box">right</div>
  </div>
</template>

<script type="text/ecmascript-6">


// custom theme
import theme from "./theme.json";

import screenfull from 'screenfull'
import constant from '../constant'
import request, {httpGet} from '../http/request'
import {setStore, getStore} from '../utils/storage'

import {
  registerTheme
} from "echarts/core";
// registering custom theme
registerTheme("ovilia-green", theme);
export default {
  components: {},

  data() {

    return {
      isFullscreen: false,
      option_column: {
        backgroundColor: 'transparent',
        title: { text: "Column Chart" },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: true },
            magicType: { type: ["line", "bar"] },
            saveAsImage: {},
          },
        },
        // grid: {
        //   left: "2.8%",
        //   right: "0.5%",
        //   bottom: "6%",
        // },
        xAxis: {
          data: ["衬衫", "羊毛衫", "雪纺衫", "裤子", "高跟鞋", "袜子"],
        },
        yAxis: {},
        series: [
          {
            name: "销量",
            type: "bar",
            data: [5, 20, 36, 10, 10, 20],
          },
        ],
      },
    }
  },

  created() {
    this.userId = this.$route.params.id
    console.log(this.$route.params)
    this.token = getStore(constant.K_TOKEN)
    request.httpGet(`/api/web/user/get_info?user_id=${this.userId}&type=1`, {}, success => {
      this.userBean = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    if (this.token) {
      request.httpGet('/api/user/get_my_info', {}, data => {
        this.myInfo = data
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }
    request.httpPost('/api/web/meet/rec-side-users', {
      type: 1,
      is_circle: 1
    }, success => {
      this.recUserArr = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.destroy()
  },

  methods: {
    splitStr(photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },

    click() {
      if (!screenfull.isEnabled) {
        this.$message({message: '你的浏览器不支持全屏', type: 'warning'})
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change)
      }
    }


  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.container {
  width 100vw
  display flex
  flex-direction row
  align-items center
  justify-content center
  background: #3e5d8b
}

.full-icon {
  display: inline-block
  cursor: pointer
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.left-box
  flex 1
  //background-color #8cc5ff

.center-box
  flex 2
  //background-color #42b983

.right-box
  flex 1
  //background-color #787878



figure {
  display: inline-block;
  position: relative;
  margin: 2em auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
  padding: 1.5em 2em;
  min-width: calc(40vw + 4em);
  background-color rgba(0,0,0,0)

  .echarts {
    width: 100%;
    width: 40vw;
    min-width: 400px;
    height: 300px;
  }
}

</style>
