<template>
  <div class="tab-box">
    <el-button   v-for="(item, i) in dataArr" class="tab-item" :class="i==index?'tab-item-sel':'tab-item-nor'" v-bind:key="i"  @click="onClickItemListener(i, item)">
      <div class="name">{{item.name}}  {{item.deviceNum}}</div>
      <el-image class="icon" v-if="item.icon" :src="item.icon" fit="contain"/>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    dataArr: {
      type: Array,
      value: []
    }
  },
  data(){
    return{
      index:0
    }
  },
  methods:{
    onClickItemListener(index, item){
      this.index = index
      this.$emit('onClickItemListener', item); // 触发自定义事件，并传递参数
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.tab-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
  .tab-item{
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0 12px;
    font-size: 15px;
    margin-right: 4px;
    box-sizing: border-box;
    .icon{
      width: 12px;
      height: 13px;
    }
  }
  .tab-item-nor{
    color: #51D2EE;
    background-color: #0A4168;
  }
  .tab-item-sel{
    color: white;
    background-color: #057FD4;
  }

}
</style>
