<template>
  <div class="monitor-visit-child-container" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">

    <img class="bg-img" src="../images/institution/bg_institution_video.jpg"/>
    <div class="full-icon">
      <!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click"/>-->
      <!--      <i :class="isFullscreen?'el-icon-top-left':'el-icon-full-screen'" style="color: white"-->
      <!--         ></i>-->
      <img v-if="isFullscreen" src="../images/icon_compress.png" width="20" height="20" @click="clickScreenFull">
      <img v-else src="../images/icon_expend.png" width="20" height="20" @click="clickScreenFull">
    </div>

    <div class="video-header-box">
      <img class="bg-header" src="../images/institution/bg_header.png"/>
      <div class="logo-txt" @click="onClickSwitchMonitor">木子信息科技</div>

      <div class="title-txt">{{ curGroupName }}</div>

      <div class="btn-exit" @click="onClickLogout">
        <img class="icon-exit" src="../images/icon_exit_green.png"/>
        <span>退出</span>
      </div>

    </div>
    <div class="video-content-box">
      <div class="video-left-box">
        <chart-com-view class="my-chart-box" title="儿童数量统计分布" :isHeaderPlaceHolder="false">
          <v-chart
            :option="pieVisitChildUser"
            :init-options="initOptions"
            ref="pieVisitChildUser"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 100%;width: 100%"
          />
        </chart-com-view>
        <chart-com-view class="my-chart-box" title="发现问题走势" :isHeaderPlaceHolder="false">
          <v-chart
            :option="barLineProblemByMonth"
            :init-options="initOptions"
            ref="barLineProblemByMonth"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
          />
        </chart-com-view>
        <chart-com-view class="my-chart-box" title="问题分类统计" :isHeaderPlaceHolder="false">
          <v-chart
            :option="barVisitProblemType"
            :init-options="initOptions"
            ref="barVisitUserActionAbility"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 100%;width: 100%"
          />
        </chart-com-view>

      </div>
      <div class="video-center-box">
        <div class="empty-ver1"></div>
        <h2-view title="本月统计" style="margin-top: 12px"> </h2-view>
        <div class="empty-ver1"></div>
        <section class="center-box-module">
          <num-view-child title="本月探视次数" :num="monthTotalBean.visitsNum" unit="次"
                          dot-color="#0581CE"></num-view-child>
          <num-view-child title="本月任务次数" :num="monthTotalBean.totalPersonNum" unit="次"
                          dot-color="#0581CE"></num-view-child>
          <v-chart
            :option="gaugeMonthVisitRate"
            :init-options="initOptions"
            ref="gauge"
            theme="ovilia-green"
            autoresize
            style="height: 180px;width: 180px"
          />
          <v-chart
            :option="gaugeMonthVisitCompleteRate"
            :init-options="initOptions"
            ref="gauge"
            theme="ovilia-green"
            autoresize
            style="height: 180px;width: 180px"
          />
          <num-view-child title="本月发现问题" :num="monthTotalBean.allQuestionNum" unit="个"
                          dot-color="#CFB006"></num-view-child>
          <num-view-child title="本月已处理问题" :num="monthTotalBean.completedQuestionNum" unit="个"
                          dot-color="#CFB006"></num-view-child>

        </section>
        <div class="empty-ver3"></div>
        <div class="num-view-box">
          <num-view class="num-item" title="总人数" :num="monthTotalBean.totalPersonNum" unit="人"
                    :icon="require('../images/child/icon_child_num.png')"></num-view>
          <num-view class="num-item" title="新增儿童数" :num="monthTotalBean.addPersonNum" unit="人"
                    :icon="require('../images/child/icon_child_add_num.png')"></num-view>
          <num-view class="num-item" title="核减儿童数" :num="monthTotalBean.reducePersonNum" unit="人"
                    :icon="require('../images/child/icon_child_reduce_num.png')"></num-view>
          <num-view class="num-item" title="处理中问题数" :num="monthTotalBean.processingQuestionNum" unit="个"
                    :icon="require('../images/child/icon_problem_num.png')"></num-view>
        </div>
        <div class="empty-ver4"></div>
        <h2-view title="近一年度统计"></h2-view>
        <div class="empty-ver1"></div>
        <section class="center-box-module">
          <num-view-child title="年度探视次数" :num="visitTotalBean.visitsNum" unit="次"
                          dot-color="#00D1C2"></num-view-child>
          <num-view-child title="年度任务次数" :num="visitTotalBean.totalPersonNum" unit="次"
                          dot-color="#00D1C2"></num-view-child>
          <v-chart
            :option="gaugeVisitRate"
            :init-options="initOptions"
            ref="gauge"
            theme="ovilia-green"
            autoresize
            style="height: 180px;width: 180px"
          />
          <v-chart
            :option="gaugeVisitCompleteRate"
            :init-options="initOptions"
            ref="gauge"
            theme="ovilia-green"
            autoresize
            style="height: 180px;width: 180px"
          />
          <num-view-child title="年度发现问题" :num="visitTotalBean.allQuestionNum" unit="个"
                          dot-color="#F9031F"></num-view-child>
          <num-view-child title="年度已处理问题" :num="visitTotalBean.completedQuestionNum" unit="个"
                          dot-color="#F9031F"></num-view-child>

        </section>
        <div class="empty-ver3"></div>
        <div class="num-view-box">
          <num-view class="num-item" title="总人数" :num="visitTotalBean.totalPersonNum" unit="人"
                    :icon="require('../images/child/icon_child_num.png')"></num-view>
          <num-view class="num-item" title="新增儿童数" :num="visitTotalBean.totalPersonNum" unit="人"
                    :icon="require('../images/child/icon_child_add_num.png')"></num-view>
          <num-view class="num-item" title="核减儿童数" :num="visitTotalBean.reducePersonNum" unit="人"
                    :icon="require('../images/child/icon_child_reduce_num.png')"></num-view>
          <num-view class="num-item" title="处理中问题数" :num="visitTotalBean.processingQuestionNum" unit="个"
                    :icon="require('../images/child/icon_problem_num.png')"></num-view>
        </div>
        <div class="empty-ver4"></div>
        <chart-com-view class="my-chart-box" title="实时发现问题列表">
          <dv-scroll-board :config="visitLatestProblemListConfig" ref="scrollBoardLatestProblemList"
                           style="width:100%;height:25vh"/>
        </chart-com-view>
        <div class="empty-ver2"></div>
      </div>
      <div class="video-right-box">
<!--        <div style="font-size: 20px; margin-bottom: 10px">全县乡镇列表统计</div>-->
        <chart-com-view title="当月各乡镇统计列表" style="margin-top: 6px;">
          <dv-scroll-board :config="visitGroupListConfig" style="width:100%;height:30vh"/>
        </chart-com-view>
        <chart-com-view   style="margin-top: 10px; height: 28vh;" title="儿童随访方式统计" :isHeaderPlaceHolder="false">
          <v-chart
            :option="pieVisitModer"
            :init-options="initOptions"
            ref="pieVisitModer"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 100%;width: 100%"
          />
        </chart-com-view>

        <chart-com-view title="问题分类走势"  :isHeaderPlaceHolder="false" style="margin-top: 10px;height: 28vh;">
          <v-chart
            :option="lineProblemTypeByMonth"
            :init-options="initOptions"
            ref="lineProblemTypeByMonth"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
            style="width: 100%;height: 32vh"
          />
        </chart-com-view>

      </div>
    </div>
    <dialog-select-monitor :show="selectMonitorShow"  @onClose="selectMonitorShow=false"></dialog-select-monitor>

  </div>

</template>

<script type="text/ecmascript-6">
/* eslint-disable no-console */
import qs from "qs";
// import VChart from "../ECharts";
import {registerTheme, use} from "echarts/core";
import {
  BarChart,
  EffectScatterChart,
  LineChart,
  LinesChart,
  MapChart,
  PieChart,
  RadarChart,
  ScatterChart
} from "echarts/charts";
import {
  DatasetComponent,
  DataZoomComponent,
  GeoComponent,
  GridComponent,
  LegendComponent,
  PolarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent
} from "echarts/components";

import {CanvasRenderer, SVGRenderer} from "echarts/renderers";
import "echarts-liquidfill";
// import logo from "./data/logo";
import getPeiChildUser from "./data/pie-child-user";
import getPieChildVisitMode from "./data/pie-child-visit-mode";

import getTabBarData from "./data/tab-bar-data";

import getGauge from "./data/gauge-child";
import getBarLineProblem from "./data/bar-line-visit-child-problem";
import getBarQuestionType from "./data/bar-question-type";
// import getBarPieVisitProblemType from "./data/bar_line_visit_child_problem";
import getBarPieVisitProblemType from "./data/bar-visit-child-problem-type-3d";
import getLineProblemByTypeMonth from "./data/line-visit-child-problem-type-month";
import getVisitGroupListConfig from "./data/scroll-child-visit-group"
import getVisitLatestProblemListConfig from "./data/scroll-child-visit-problem"

import NumView from "../components/NumViewChildIcon.vue";

import ChartComView from "../components/ChartComView.vue";


// custom theme
import theme from "./theme.json";

// Map of China
// import chinaMap from "./china.json";
// import worldMap from "./world.json";
import screenfull from 'screenfull'
import constant from '../constant'
import request from '../http/request'
import {getStore, setStore} from '../utils/storage'
import H2View from "@/components/H2ChildView.vue";
import NumViewChild from "@/components/NumViewChild.vue";
import moment from "moment";
import DialogSelectMonitor from "@/components/DialogSelectMonitor.vue";


use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent
]);

// registering map data
// registerMap("china", chinaMap);
// registerMap("world", worldMap);

// registering custom theme
registerTheme("ovilia-green", theme);

export default {
  name: "vue-echarts-demo",
  components: {
    DialogSelectMonitor,
    H2View: H2View,
    ChartComView, NumView, NumViewChild
  },


  data() {
    const options = qs.parse(location.search, {ignoreQueryPrefix: true});
    return {
      timer : null,
      loading: false,
      selectMonitorShow: false,
      curGroupName: '',
      queryMonth: '',
      recentlyYearArr: [],
      userType: '',
      userId: '',
      provinceId: '',
      cityId: '',
      districtId: '',
      groupId: '',
      villageId: '',
      monitorChildCategoryId: 120,     // 监控当前选择的分类 120 大门口
      curSelectUserType: -1,
      filterGroupText: '',
      groupData: [],
      videoTypeArr: getTabBarData().videoTypeArr,
      isShowSelectGroupDialog: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isFullscreen: false,
      options,
      pieVisitChildUser: getPeiChildUser([], []),
      pieVisitModer: getPieChildVisitMode([], []),
      barLineProblemByMonth: getBarLineProblem([], [], [], []),
      lineProblemTypeByMonth: getLineProblemByTypeMonth([], []),
      gaugeVisitRate: getGauge("年度探视完成率", 0, "#00D1C2"),
      gaugeVisitCompleteRate: getGauge("年度问题处理率", 0, "#F9031F"),
      gaugeMonthVisitRate: getGauge("本月探视完成率", 0, "#2B8ECC"),
      gaugeMonthVisitCompleteRate: getGauge("本月问题处理率", 0, "#F3A900"),
      barVisitQuestionType: getBarQuestionType([], [], [], [], [], []),
      barVisitProblemType: getBarPieVisitProblemType([], [], [], []),
      visitGroupListData: [],
      visitGroupListConfig: getVisitGroupListConfig([]),
      visitLatestProblemListConfig: getVisitLatestProblemListConfig([]),
      visitTotalBean: {},
      visitAloneUserNum: 0,
      monthTotalBean: [],
      initOptions: {
        renderer: options.renderer || "canvas"
      },
      polarTheme: "dark",
      seconds: -1,
      asyncCount: false,
      connected: true,
      metricIndex: 0,
      open: false,
      img: {},
      barLoading: false,
      barLoadingOptions: {
        text: "Loading…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0)"
      },
    };
  },

  created() {
    this.userId = this.$route.params.id
    this.token = getStore(constant.K_TOKEN)
    if (!this.token) {
      this.$router.push({
        path: '/login'
      })
      return
    }
    this.queryMonth = this.getNowDate()
    this.getRecentlyYear()
    this.provinceId = getStore(constant.K_PROVINCE_ID)
    this.cityId = getStore(constant.K_CITY_ID)
    this.districtId = getStore(constant.K_DISTRICT_ID)
    this.groupId = getStore(constant.K_GROUP_ID)
    this.villageId = getStore(constant.K_VILLAGE_ID)
    this.userType = getStore(constant.K_USER_TYPE)
    this.userId = getStore(constant.K_UID)
    this.curSelectUserType = parseInt(this.userType)

    var times = 0;
    // 设置整点定时发送请求
    this.timer = setInterval(() => {
      // const now = new Date();
      // if (now.getMinutes() === 0 && now.getSeconds() === 0) {
      //   this.httpRefreshCurData();
      // }
      // this.currentTime = now.toLocaleString();
      this.httpRefreshCurData();

      if (times >= 1) {
        this.httpRefresh10sData(false);
        times = 0;
      } else {
        times++;
      }

    }, 5000);

    this.refreshAllData();
    this.httpRefreshCurData();


    // this.httpGroupList()
    this.httpGetCurGroupDetail()
  },


  beforeDestroy() {
    this.destroyScreenFull()
    clearInterval(this.timer);
    this.timer = null
  },
  watch: {
    filterGroupText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {

  },

  methods: {
    onClickSwitchMonitor(){
      this.selectMonitorShow = true
    },
    onClickLogout() {
      // this.centerDialogVisible = false
      this.token = ''
      this.userInfo = {}
      setStore(constant.K_TOKEN, '')
      setStore(constant.K_APP_ID, '')
      setStore(console.K_UID, '')
      clearInterval(this.timer);
      this.timer = null
      this.$router.replace('/login')
    },
    onClickMoreDevice() {
      this.isShowAllShop = true
    },
    onVideoTabChangeListener(item) {
      // console.log(item)
      this.monitorChildCategoryId = item.id
      this.isActiveDeviceSectionLoading = true
      this.httpActiveDeviceList()
    },
    onClickVideoPlay(item) {
      this.isCurLive = true
      this.curDevice = item
      this.curDeviceId = item.id
      if (item) {
        this.curCapture = item.captureRecord
      } else {
        this.curCapture = null
      }
      this.isShowVideoLive = true
    },
    onClickVideoCenterPlay(item) {
      this.isCurLive = true
      this.curDevice = null
      this.curDeviceId = item.id
      this.curCapture = null
      this.isShowVideoLive = true
    },
    onClickVideoPlayBack(item) {
      this.isCurLive = false
      this.curCapture = item
      this.curDevice = null
      this.curDeviceId = item.deviceId
      this.isShowVideoLive = true
    },

    onCloseVideoLiveDialog() {
      this.isShowVideoLive = false
    },
    onClosePlayCenterDialog() {
      this.isShowAllShop = false
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    onClickItemMeal(item) {
      if (this.curSelectUserType != 5) {
        let id = item.id
        let data = this.getSelectedItem(id)
        if (data) {
          this.handleCheckChange(data, true, null)
        }
      } else {
        this.$message.error('不能再往下查看了');
      }

    },
    onClickItemVisit(item) {
      if (this.curSelectUserType != 6) {
        let id = item.id
        let data = this.getSelectedItem(id)
        if (data) {
          this.handleCheckChange(data, true, null)
        }
      } else {
        this.$message.error('不能再往下查看了');
      }
    },

    getSelectedItem(id) {
      for (let itemBean of this.groupData) {
        if (id == itemBean.id) {
          return itemBean
        }
        if (itemBean.children) {
          for (let childItem of itemBean.children) {
            if (id == childItem.id) {
              return childItem
            }
            if (childItem.children) {
              for (let child2Item of childItem.children) {
                if (id == child2Item.id) {
                  console.log("======3=" + child2Item.id)
                  return child2Item
                }
                if (child2Item.children) {
                  for (let child3Item of child2Item.children) {
                    if (id == child3Item.id) {
                      console.log("======4=" + child3Item.id)
                      return child3Item
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    handleCheckChange(data, checked, indeterminate) {
      if (checked) {
        this.dirId = data.id
        this.$refs.tree.setCheckedKeys([data.id])
        this.curGroupName = data.label
        this.provinceId = ''
        this.cityId = ''
        this.districtId = ''
        this.groupId = ''
        this.villageId = ''
        switch (data.level) {
          case 0:
            this.curSelectUserType = 11
            this.provinceId = data.id.toString()
            break
          case 1:
            this.curSelectUserType = 12
            this.cityId = data.id.toString()
            break
          case 2:
            this.curSelectUserType = 4
            this.districtId = data.id.toString()
            break
          case 3:
            this.curSelectUserType = 5
            this.groupId = data.id.toString()
            break
          case 4:
            this.curSelectUserType = 6
            this.villageId = data.id.toString()
            break
        }
        this.refreshAllData()
      } else {
        // 如何取消当前选项，就重新选择
        if (this.dirId == data.id) {
          this.dirId = data.id
          this.$refs.tree.setCheckedKeys([data.id])
        }
      }

      this.$refs.popover4.showPopper = false;
    },
    handleClick(...args) {
      console.log("click from echarts", ...args);
    },
    handleZrClick(...args) {
      console.log("click from zrender", ...args);
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      // const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      const NOW_MONTHS_AGO = `${year}-${month}`
      return NOW_MONTHS_AGO
    },

    //  获取近一年的年份
    getRecentlyYear() {
      var dataArr = [];
      var data = new Date();
      var year = data.getFullYear();
      data.setMonth(data.getMonth() + 1, 1)
      for (var i = 0; i < 12; i++) {
        data.setMonth(data.getMonth() - 1);
        var m = data.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        dataArr.push({
          name: data.getFullYear() + "年" + (m) + "月",
          value: data.getFullYear() + "-" + (m)
        })
      }
      this.recentlyYearArr = dataArr
    },


    splitStr(photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },

    clickScreenFull() {
      if (!screenfull.isEnabled) {
        this.$message({message: '你的浏览器不支持全屏', type: 'warning'})
        return false
      }
      screenfull.toggle()
    },
    changeScreen() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.changeScreen)
      }
    },
    destroyScreenFull() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.changeScreen)
      }
    },

    refreshAllData() {
      this.loading = true
      this.httpVisitChildUserType()
      this.httpVisitChildMode()
      this.httpProblemByMonth()
      this.httpVisitProblemByType()
      this.httpVisitProblemByTypeMonth()

      // 获取最新设备列表

      this.httpRefresh10sData(true);

      //
      this.httpVisitListByArea()


      this.loading = false
    },

    httpRefresh10sData(isFirst) {
      this.httpLatestProblemList(isFirst)
    },

    // 更新探视走访数据


    // 儿童分类
    httpVisitChildUserType() {
      request.httpPost('/v1/business/child/visit/statistics/user/count/by_type', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var chatData = []
        var legendData = []
        orginData.forEach((itemBean) => {
          if (itemBean.id != 4) {
            chatData.push({
              name: itemBean.name,
              value: itemBean.nums
            })
            legendData.push(itemBean.name)
          }
        });
        this.pieVisitChildUser = getPeiChildUser(legendData, chatData)
      }, error => {
      })
    },

    // 随访类型分布
    httpVisitChildMode() {
      request.httpPost('/v1/business/child/visit/statistics/visit/count/by_visit_mode', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var chatData = []
        var legendData = []
        orginData.forEach((itemBean) => {
          if (itemBean.id != 4) {
            chatData.push({
              name: itemBean.name,
              value: itemBean.nums
            })
            legendData.push(itemBean.name)
          }
        });
        this.pieVisitModer = getPieChildVisitMode(legendData, chatData)
      }, error => {
      })
    },

    // 发现问题走势
    httpProblemByMonth() {
      request.httpPost('/v1/business/child/visit/statistics/question/by/month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId
      }, success => {
        let xData = []
        let chatData = []
        let processingData = []
        let completedData = []
        let orginData = success
        if (orginData.length != 12) {
          return
        }
        for (let i = 6; i < 12; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          chatData.push(orginData[i].allQuestionNum ? orginData[i].allQuestionNum : 0)
          processingData.push(orginData[i].processingQuestionNum)
          completedData.push(orginData[i].allQuestionNum - orginData[i].processingQuestionNum)
          // processingData.push(i)
          // completedData.push(20-i)
        }
        this.barLineProblemByMonth = getBarLineProblem(xData, chatData, processingData, completedData)
      }, error => {
      })
    },


    // 问题类型统计
    httpVisitProblemByType() {
      request.httpPost('/v1/business/child/visit/statistics/question/by/type', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        var orginData = success
        var xData = []
        var chatDataOn = []
        var chatDataOff = []
        var totalNum = orginData[0].nums
        for (var i = 1; i < orginData.length; i++) {
          xData.push(orginData[i].name)
          chatDataOn.push(orginData[i].completedQuestionNum)
          chatDataOff.push(orginData[i].allQuestionNum - orginData[i].completedQuestionNum)

          // chatDataOn.push(10)
          // chatDataOff.push(20)
        }
        this.barVisitProblemType = getBarPieVisitProblemType(xData, xData, chatDataOn, chatDataOff)
      }, error => {
      })
    },

    // 问题分类统计走势
    httpVisitProblemByTypeMonth() {
      request.httpPost('/v1/business/child/visit/statistics/question/by/type_month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        let orginData = success
        let xData = []
        let chatData = []
        let chatData11 = []
        let chatData12 = []
        let chatData13 = []
        let chatData14 = []
        let chatData15 = []
        let chatData16 = []
        let chatData17 = []
        for (let i = 0; i < orginData.length; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          chatData11.push(orginData[i].question11Num)
          chatData12.push(orginData[i].question12Num)
          chatData13.push(orginData[i].question13Num)
          chatData14.push(orginData[i].question14Num)
          chatData15.push(orginData[i].question15Num)
          chatData16.push(orginData[i].question16Num)
          chatData17.push(orginData[i].question17Num)
        }
        chatData.push({
          name: '身体问题',
          color: '#2B80FF',
          data: chatData11
        })
        chatData.push({
          name: '精神问题',
          color: '#04CDF4',
          data: chatData12
        })
        chatData.push({
          name: '学习问题',
          color: '#900FF4',
          data: chatData13
        })
        chatData.push({
          name: '生活问题',
          color: '#ABA611',
          data: chatData14
        })
        chatData.push({
          name: '监护人问题',
          color: '#FD98CF',
          data: chatData15
        })
        chatData.push({
          name: '突发事件',
          color: '#00EFE6',
          data: chatData16
        })
        chatData.push({
          name: '生活费问题',
          color: '#ffa500',
          data: chatData17
        })

        this.lineProblemTypeByMonth = getLineProblemByTypeMonth(xData, chatData)
      }, error => {
      })
    },


    // 时时问题列表
    httpLatestProblemList(isFirst) {
      request.httpPost('/v1/business/child/visit/problem/list', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId,
        status: -1,
        currentPage: 1,
        pageSize: 10
      }, success => {
        if (isFirst) {
          this.visitLatestProblemListConfig = getVisitLatestProblemListConfig(success.list)
        } else {
          this.$refs['scrollBoardLatestProblemList'].updateRows(this.formatVisitLatestProblemData(success.list()))
        }

      }, error => {
      })
    },

    formatVisitLatestProblemData(dataArr) {
      let resultDataArr = [];
      for (let i = 0; i < dataArr.length; i++) {
        let itemArr = []
        let item = dataArr[i]

        itemArr.push('<span style="color:#FF0101;">' + item.questionTypeName + '</span>')
        itemArr.push('<span style="color:' + (item.status == 2 ? '#11FF00' : '#FFEA01') + ';">' + (item.status == 2 ? '已处理' : '处理中') + '</span>')
        itemArr.push(moment(item.createTime).format('HH:mm  MM月DD日'))  //
        itemArr.push(item.recordedName)  //
        itemArr.push(item.townName + '.' + item.villageName) //
        itemArr.push(item.recorderName) //
        itemArr.push(item.recorderPhone) //

        resultDataArr.push(itemArr)
      }
      return resultDataArr;
    },

    // 助餐按照区域统计列表
    httpVisitListByArea() {
      let userType = parseInt(this.curSelectUserType)
      switch (userType) {
        case 11:
          this.httpVisitListByCity()
          break
        case 12:
          this.httpVisitListByDistrict()
          break
        case 4:
          this.httpVisitListByTown()
          break
        case 5:
          // this.httpMealListByShop()
          break
        case 6:
          break
      }
    },


    // 助餐-按城市分组统计列表
    httpVisitListByCity() {
      request.httpPost('/v1/business/child/visit/statistics/list/by/city', {
        provinceId: this.provinceId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
        this.visitGroupListConfig = getVisitGroupListConfig(success)
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
    // 助餐-按区县分组统计列表
    httpVisitListByDistrict() {
      request.httpPost('/v2/business/meal/statistics/list/by/district', {
        cityId: this.cityId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
        this.visitGroupListConfig = getVisitGroupListConfig(success)
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpVisitListByTown() {
      request.httpPost('/v1/business/child/visit/statistics/list/by/group', {
        districtId: this.districtId,
        queryMonth: this.queryMonth
      }, success => {
        this.visitGroupListData = success
        this.visitGroupListConfig = getVisitGroupListConfig(success)
        this.loading = false
      }, error => {
        this.loading = false
      })
    },


    // 助餐-按店铺统计列表
    httpMealListByShop() {
      request.httpPost('/v2/business/meal/statistics/list/by/shop', {
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.visitGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    httpGroupList(parentGroup) {
      request.httpPost('/v1/common/group/all', {}, success => {

        if (success) {
          var allGroupArr = []
          var groupArr = []

          let name = ""
          let suffix = ""
          if (this.userId == 64921) {
            name = "市县区数字养老监管中心(演示)"
            suffix = "(演示)"
          } else {
            name = parentGroup.name + "数字养老监管中心"
          }

          for (let itemBean of success) {
            var childArr = []
            if (itemBean.child) {
              for (let childItem of itemBean.child) {
                var child2Arr = []
                if (childItem.child) {
                  for (let child2Item of childItem.child) {
                    child2Arr.push({
                      id: child2Item.id,
                      label: child2Item.name + "数字养老监管中心",
                      parentId: child2Item.id,
                      level: child2Item.level
                    })
                  }
                }
                childArr.push({
                  id: childItem.id,
                  label: childItem.name + "数字养老监管中心" + suffix,
                  parentId: itemBean.id,
                  level: itemBean.level,
                  children: child2Arr,
                })
              }
            }

            groupArr.push({
              id: itemBean.id,
              label: itemBean.name + "数字养老监管中心" + suffix,
              parentId: parentGroup.id,
              level: itemBean.level,
              children: childArr,
            })
          }
          allGroupArr.push({
            id: parentGroup.id,
            label: name,
            level: parentGroup.level,
            children: groupArr,
          })


          this.groupData = allGroupArr
        }
      }, error => {
      })
    },

    httpGetCurGroupDetail() {
      let id = this.groupId
      switch (parseInt(this.userType)) {
        case 11:
          id = this.provinceId
          break
        case 12:
          id = this.cityId
          break
        case 4:
          id = this.districtId
          break
        case 5:
          id = this.groupId
          break
        case 6:
          id = this.villageId
          break
      }

      request.httpPost(`/v1/business/group/detail/${id}`, {}, success => {
        if (success) {
          this.curGroupName = success.name + "儿童随访监控中心"

          this.httpGroupList(success)
        }
      }, error => {
      })
    },


    httpRefreshCurData() {


      // 本月总体统计数据
      request.httpPost('/v1/business/child/visit/statistics/total', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.monthTotalBean = success

        let visitRate = (success && success.totalPersonNum != 0) ? (success.visitPersonNum / success.totalPersonNum * 100).toFixed(2) : 0;
        if (visitRate > 100.00) {
          visitRate = 100.00
        }
        let completeRate = (success && success.allQuestionNum != 0) ? (success.completedQuestionNum / success.allQuestionNum * 100).toFixed(2) : 0;
        if (completeRate > 100.00) {
          completeRate = 100.00
        }
        this.gaugeMonthVisitRate = getGauge("本月探视率完成率", visitRate, "#2B8ECC")
        this.gaugeMonthVisitCompleteRate = getGauge("本月问题处理率", completeRate, "#F3A900")
      }, error => {

      })

      // 总体统计数据
      request.httpPost('/v1/business/child/visit/statistics/total', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        // queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.visitTotalBean = success

        let visitRate = (success && success.totalPersonNum != 0) ? (success.visitPersonNum / (success.totalPersonNum *12)  * 100).toFixed(2) : 0;
        if (visitRate > 100.00) {
          visitRate = 100.00
        }
        let completeRate = (success && success.allQuestionNum != 0) ? (success.completedQuestionNum / success.allQuestionNum * 100).toFixed(2) : 0;
        if (completeRate > 100.00) {
          completeRate = 100.00
        }
        this.gaugeVisitRate = getGauge("年度探视率完成率", visitRate, "#00D1C2")
        this.gaugeVisitCompleteRate = getGauge("年度问题处理率", completeRate, "#F9031F")

      }, error => {

      })

    }
  }
}
</script>


<style lang="stylus" rel="stylesheet/stylus">
@import "../common/stylus/mixin.styl"
@import "../styles/monitor-center.css"


*,
*::before,
*::after {
  box-sizing: border-box;
}


::-webkit-scrollbar {
  display: none
}


html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
}

.monitor-visit-child-container
  width: 100%;
  //height: 100%;
  background-color: #070536 !important;
  display: flex;
  flex-direction: column;
  align-items center
  justify-content center

  .video-header-box
    position: relative;
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .bg-header
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

    .title-txt
      font-size: 24px;
      color: white;
      font-weight: bold;
      background: linear-gradient(0deg, #D7D7D7 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 1;
      padding-right 30px

    .logo-txt
      font-size: 20px;
      color: #00FCF9;
      z-index: 1;
      margin-left: 10px;
      margin-bottom 12px;
      font-weight: bold;

    .btn-exit
      display flex
      flex-direction row
      align-items center
      font-size 16px
      color #00FCF9
      margin-left 30px
      margin-bottom: 24px;
      margin-right 10px
      z-index 10

      .icon-exit
        width: 27px;
        height: 27px;
        padding: 5px;
        margin-left 0px


  .video-content-box
    width: 100vw;
    height 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 1;

    .video-left-box
      width 27vw
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left 16px
      box-sizing border-box

      .my-chart-box
        margin-top 20px
        width: 100%;
        height: 29.4vh;

    .my-chart-box:first-child
      margin-top 8px;

    .video-center-box
      margin 0 20px
      width 46vw
      height 92vh
      display flex
      flex-direction column
      box-sizing border-box

      .center-box-module
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items flex-end
        justify-content: space-around;
        margin-top -16px

      .num-view-box
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        box-sizing border-box
        padding 5px
        margin-top 30px
        margin-bottom 40px

        .num-item
          margin-right 38px

          &:first-child
            margin-left 38px

      .video-tab-bar
        margin-top 20px
        margin-bottom 12px
        margin-left 6px

      .capture-box
        display flex
        flex-direction row
        align-items center
        margin-top 14px

    .video-right-box
      width 27vw
      display: flex;
      flex-direction: column
      align-items: center
      padding-right 16px
      box-sizing border-box

      .h2
        font-size 20px
        color #ffffff
        font-weight bold

      .my-chart-box
        margin-top 20px
        height 30vh

      .my-chart-box:first-child
        margin-top 8px;


.dv-scroll-board
  margin-top 10px

  .header
    background: linear-gradient(92deg, #0A208A, #01CCFF)
    opacity: 0.7
    color #03FFDE

  .rows
    .row-item
      font-size 12px


.full-icon {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index 9999
}


a {
  color: inherit;
  text-decoration: none;
}

#logo {
  display: inline-block;
  width: 128px;
  height: 128px;
  pointer-events: none;
}

</style>
