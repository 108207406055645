
export default function getData() {
  return{
    videoTypeArr:[{
      id:'',
      name:"全部",
      deviceNum:''
    },{
      id:120,
      name:"大门口",
      deviceNum:''
    },{
      id:121,
      name:"通道",
      deviceNum:''
    },{
      id:122,
      name:"消防主机",
      deviceNum:''
    },{
      id:123,
      name:"餐厅",
      deviceNum:''
    },{
      id:124,
      name:"厨房",
      deviceNum:''
    },{
      id:125,
      name:"活动区",
      deviceNum:''
    },{
      id:126,
      name:"打荷台",
      deviceNum:''
    },{
      id:127,
      name:"储物间",
      deviceNum:''
    }]
  } ;
}
