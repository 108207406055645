module.exports = {
      // wx_app_id: 'wxd26f2ee0972036b4',  // 抖来
      wx_app_id: 'wx83344776b5a4b354',  // 快来
      // host_url: 'http://127.0.0.1:8081/smartpension/api',  // 本地服务器
      // host_url: 'https://fzy.cxzai.com',     // 远程服务器
      // host_url: 'https://api.iotaep.com/smartpension/api',     // 远程服务器
      host_url: 'https://api.iotccb.com/smartpension/api',     // 远程服务器
      // host_url: 'https://apiuat.iotccb.com/smartpension/api',     // 远程服务器
      // host_url: '',    // 远程服务器，本地代理调试
}
