<template>
  <el-dialog
    :visible.sync="visibleDialog"
    custom-class="slidingPictures"
    :close="closeDialog"
    :before-close="closeDialog"
    :opened="onOpened"
  >
    <div class="sliding-pictures">
      <div class="vimg">
<!--        <img class="vimg-bg" src="../images/code.jpg">-->
        <canvas id="sliderBlock"></canvas>
        <canvas id="codeImg"></canvas>

      </div>
      <div class="slider">
        <div class="track" :class="{ pintuTrue: puzzle }">
          {{ tips }}
        </div>
        <div class="button el-icon-s-grid" @mousedown.prevent="drag"></div>
      </div>
      <div class="operation">
          <span
            title="关闭验证码"
            @click="closeDialog"
            class="el-icon-circle-close"
          ></span>
        <span
          title="刷新验证码"
          @click="canvasInit"
          class="el-icon-refresh-left"
        ></span>
      </div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'VerificationCode',
  props: {
    visibleDialog: {
      type: Boolean
    }
  },
  data () {
    return {
      tips: '拖动左边滑块完成上方拼图',
      // visibleDialog: false,
      //滑块x轴数据
      slider: {
        mx: 0,
        bx: 0
      },
      //拼图是否正确
      puzzle: false
    }
  },
  created () {
  },
  updated () {
    this.canvasInit()
  },
  watch: {
    visibleDialog (e) {
      // if (e === true) {
      //   this.canvasInit()
      //   this.puzzle = false
      // }

      setTimeout(_ => {
        this.canvasInit()
        this.puzzle = false
        this.tips='拖动左边滑块完成上方拼图'
        // this.slider={mx:0,bx:0}
      }, 50)
    }
  },
  methods: {
    closeDialog(){
      this.$emit('closeDialog')
    },
  }
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

</style>
