<template>
  <div class="num-box">
    <div class="num">
<!--      <dv-digital-flop :config="{number:[num?num:0]}" />-->
      {{num?num:'0'}}<span class="unit">{{unit}}</span></div>
    <div class="title-txt"><div class="dot" :style="{'background-color':dotColor}"></div>{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'NumViewChild',
  props: {
    title: String,
    num:Number,
    unit:{
      type:String,
      default:'个'
    },
    dotColor:{
      type:String,
      default: '#0581CE'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.num-box{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.num-box .num{
  font-size: 26px;
  font-weight: 800;
  color: #56EDFB;
}

.num-box .num .unit{
  font-size: 16px;
  font-weight: 600;
  color: #56EDFB;
  margin-left: 2px;
  padding-bottom: 4px;
  box-sizing: border-box;
}

.num-box .title-txt{
  font-size: 14px;
  color: white;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.num-box .title-txt .dot{
  width: 12px;
  height: 12px;
  margin-right: 6px;
}
</style>
