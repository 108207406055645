const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}


/**
 * 将小程序的API封装成支持Promise的API
 * @params fn {Function} 小程序原始API，如wx.login
 */
const wxPromisify = fn => {
  return function (obj = {}) {
    return new Promise((resolve, reject) => {
      obj.success = function (res) {
        resolve(res)
      }
      obj.fail = function (res) {
        reject(res)
      }
      fn(obj)
    })
  }
}

//时间戳转换成日期时间
function formatDateTime(unixtime) {
  var dateTime = new Date(parseInt(unixtime))
  var year = dateTime.getFullYear();
  var month = dateTime.getMonth() + 1;
  var day = dateTime.getDate();
  var hour = dateTime.getHours();
  var minute = dateTime.getMinutes();
  var second = dateTime.getSeconds();
  var now = new Date();
  var now_new = Date.parse(now.toDateString()); //typescript转换写法
  var milliseconds = now_new - dateTime;
  var timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  return timeSpanStr;
}

function getGapTime(date) { //传入的date为‘yyyy-mm-dd hh:mm:ss’形式的
  var result = "00"
  var timestamp = getDate().getTime(); //wxs中不能使用js中的Date()函数，只能使用getDate()来获取当前标准时间，getTime()函数可以用来将标准时间转换为时间戳，单位为ms
  timestamp = parseInt(timestamp / 1000);
  //发表文章的时间戳 s
  var publicstamp = getDate(date).getTime();
  publicstamp = parseInt(publicstamp / 1000)
  //时间差s
  var gap = timestamp - publicstamp
  var time = getDate(date);
  console.log('time:' + time)
  var year = time.getFullYear()
  var month = time.getMonth() + 1
  var day = time.getDay()
  var monthOfDay = time.getDate()
  var hour = time.getHours()
  var minute = time.getMinutes()

  var divstamp = getDate(year + '-' + month + '-' + day + ' 00:00:00').getTime()
  divstamp = parseInt(divstamp / 1000)
  var divgap = timestamp - divstamp
  if (gap < 60) {
    result = "刚刚"
  } else if (divgap < 60 * 60 * 24) {
    result = hour + ':' + minute
  } else if (divgap < 60 * 60 * 24 * 2) {
    result = "昨天"
  } else if (divgap < 60 * 60 * 24 * 7) {
    if (day == 0) {
      result = "星期一"
    } else if (day == 1) {
      result = "星期二"
    } else if (day == 2) {
      result = "星期三"
    } else if (day == 3) {
      result = "星期四"
    } else if (day == 4) {
      result = "星期五"
    } else if (day == 5) {
      result = "星期六"
    } else {
      result = "星期日"
    }

  } else {
    result = month + '月' + monthOfDay + '日'
  }
  return result
};

//传入的date为‘yyyy-mm-dd hh:mm:ss’形式的
function getChatTime(time, lastTime) {
  var time = time.replace(/-/g, '/')
  if (lastTime != '') {
    var lastTime = lastTime.replace(/-/g, '/')
    var oDate1 = new Date(lastTime);//转换成时间戳（上一个日期）
    var oDate2 = new Date(time);//转换成时间戳（本日期）
    if(oDate2.getTime() - oDate1.getTime() > 1000*60*30){
      return getTimeText(time);
    }
    return '';
  } else {
    return getTimeText(time);
  }
}

// var time = '2017年06月02日 10:43:24';

// 时间统一函数
function getTimeText(argument) {
  var timeS = argument;
  var todayT = ''; //
  var yestodayT = '';
  var timeCha = getTimeS(timeS);
  todayT = new Date().getHours() * 60 * 60 * 1000 + new Date().getMinutes() * 60 * 1000 + new Date().getSeconds() * 1000;
  yestodayT = todayT + 24 * 60 * 60 * 1000;

  var time = new Date(argument);
  var year = time.getFullYear()
  var month = time.getMonth() + 1
  var day = time.getDay()
  var monthOfDay = time.getDate()
  var hour = time.getHours()
  var minute = time.getMinutes()

  var hm = [hour, minute].map(formatNumber).join(':')
  if (timeCha > yestodayT) {
    // return argument.slice(0, 16);
    return year + '年' + month + '月' + monthOfDay + '日 ' + hm
  }
  if (timeCha > todayT && timeCha < yestodayT) {
    return '昨天 ' + hm;
  }
  if (timeCha < todayT) {
    return hm;
  }

}

// 时间戳获取
function getTimeS(argument) {
  var timeS = argument;
  // timeS = timeS.replace(/[年月]/g, '/').replace(/[日]/, '');
  return new Date().getTime() - new Date(timeS).getTime() - 1000; //有一秒的误差
}

// 获取遇见的过时时间
function getStorageTimeS() {
  var date = new Date();
  var hour = date.getHours()
  var minute = date.getMinutes()
  if (hour < 20) {
    return 20*60- hour*60-minute;
  } else {
    return 20*60 + 24*60 - hour*60-minute;
  }
}


function getAge (birthday) {
  if (!birthday) {
    return ''
  }

  var returnAge = ''
  var mouthAge = ''
  var strBirthdayArr = birthday.split('-')
  var birthYear = strBirthdayArr[0]
  var birthMonth = strBirthdayArr[1]
  var birthDay = strBirthdayArr[2]
  var d = new Date()
  var nowYear = d.getFullYear()
  var nowMonth = d.getMonth() + 1
  var nowDay = d.getDate()
  if (nowYear == birthYear) {
    // returnAge = 0; //同年 则为0岁
    var monthDiff = nowMonth - birthMonth //月之差
    if (monthDiff < 0) {
    } else {
      mouthAge = monthDiff + '个月'
    }
  } else {
    var ageDiff = nowYear - birthYear //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        var dayDiff = nowDay - birthDay //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1 + '岁'
        } else {
          returnAge = ageDiff + '岁'
        }
      } else {
        var monthDiff = nowMonth - birthMonth //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1 + '岁'
        } else {
          mouthAge = monthDiff + '个月'
          returnAge = ageDiff + '岁'
        }
      }
    } else {
      returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
    }
  }
  // return returnAge + mouthAge; //返回周岁年龄+月份
  return returnAge //返回周岁年龄+月份
}


function getUserInfoCompletion (userInfo) {
  let percent = 10
  let step = 5
  if (userInfo) {
    if (userInfo.name) {
      percent = percent + step
    }
    if (userInfo.avatar) {
      percent = percent + step
    }
    if (userInfo.province) {
      percent = percent + step
    }
    if (userInfo.birthday) {
      percent = percent + step
    }
    if (userInfo.education) {
      percent = percent + step
    }
    if (userInfo.gender) {
      percent = percent + step
    }
    if (userInfo.occupation) {
      percent = percent + step
    }
    if (userInfo.phone) {
      percent = percent + step
    }
    if (userInfo.user_info) {
      if (userInfo.user_info.province) {
        percent = percent + step
      }
      if (userInfo.user_info.height) {
        percent = percent + step
      }
      if (userInfo.user_info.car_status) {
        percent = percent + step
      }
      if (userInfo.user_info.housing) {
        percent = percent + step
      }
      if (userInfo.user_info.marital_status) {
        percent = percent + step
      }
      if (userInfo.user_info.monthly_income) {
        percent = percent + step
      }

      if (userInfo.user_info.life_photos) {
        percent = percent + step
      }
      if (userInfo.user_info.hobbies) {
        percent = percent + step
      }
      if (userInfo.user_info.mate_selection_desc) {
        percent = percent + step
      }
      if (userInfo.user_info.your_detail_desc) {
        percent = percent + step
      }
    }
  }
  return percent
}


module.exports = {
  formatTime: formatTime,
  wxPromisify: wxPromisify,
  formatDateTime: formatDateTime,
  getChatTime: getChatTime,
  getGapTime:getGapTime,
  getStorageTimeS: getStorageTimeS,
  getAge:getAge,
  getUserInfoCompletion: getUserInfoCompletion
}
