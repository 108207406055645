
let OSS = require('ali-oss');
import Buffer from "vue-buffer";


let client = new OSS({
  accessKeyId: 'LTAI5t7mLgZ13rBPLaJAFikz',   // 你创建的Bucket时获取的
  accessKeySecret: 'jyEQEzyL7oPXLppvNds4w890VfGmmt',  // 你创建的Bucket时获取的
  bucket: 'smartpension',  // 你创建的Bucket名称
  region: 'oss-cn-shanghai'   //  你所购买oss服务的区域，默认oss-cn-hangzhou
});

// 上传
export async function put (filePath, file) {
  try {
    let result = await client.put(filePath, file)
    return result
  } catch (err) {
    console.log(err)
  }
}

export async function putBase64 (filePath, base64Data) {
  var baseBlom = Buffer.from(base64Data.replace(/^data:image\/\w+;base64,/, ""), 'base64')
  try {
    let result = await client.put(filePath,baseBlom)
    return result
  } catch (err) {
    console.log(err)
  }
}

async function uploadImage(base64Data) {
  const fileName = Date.now() + '.png'; // 生成新的文件名

  try {
    await client.put('path/to/' + fileName, Buffer.from(base64Data, 'base64'));

    console.log("Upload success!");
    return `https://<your_domain>/${fileName}`; // 返回上传后的URL
  } catch (error) {
    console.error("Upload failed!", error);
    throw error;
  }
}

// 删除
export async function remove (filePath) {
  try {
    let result = await client.delete(filePath)
    return result
  } catch (err) {
    console.log(err)
  }
}


/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}


export default {
  fileHost:'https://img.iotccb.com/',
  put,
  putBase64,
  remove,
  getFileNameUUID
}
