function random() {
  return Math.round( Math.random() * 100) ;
}

export default function getData(name, value, color) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    // backgroundColor: "#ffffff",
    series: [{
      name: '业务指标',
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false
      },
      itemStyle: {
        color: color
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          borderWidth: 6,
          borderColor: color
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          width: 5
        }
      },
      splitLine: {
        show: false,
        distance: 0,
        length: 10
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        distance: 50
      },
      title: {
        fontSize: 12,
        color: '#ffffff',
        offsetCenter: [0, '50%']
      },
      detail: {
        valueAnimation: true,
        width: '60%',
        lineHeight: 40,
        borderRadius: 8,
        offsetCenter: [0, '-10%'],
        fontSize: 22,
        fontWeight: 'bolder',
        formatter: '{value}%',
        color: '#00FFF6'
      },
      data: [{
        value: value,
        name: name,
      }]

    }]
  };
}
