<template>
<!--  destroy-on-close 暂时只能设置 false  设置 true 会报错-->
  <el-dialog
    :visible="show"
    custom-class="dialog-container"
    @open="onOpen"
    @close="onClose"
    :modal-append-to-body="false"
    :append-to-body="false"
    :fullscreen="false"
    :show-close="false"
    :destroy-on-close="false"
    :close-on-click-modal="false"
    width="1120px"
    top="5vh">
    <!--    <div class="title">测试</div>-->
    <div class="title">{{ title }}</div>

    <i class="el-icon-close close" @click="closeDialog"></i>
    <el-main class="dialog-box" v-loading="loading"
             element-loading-text="拼命加载中"
             element-loading-background="rgba(0, 0, 0, 1.0)">
      <div id="video-container" style="width:1080px;height:720px"></div>
      <div class="btn-box">
        <!--        <button class="btn-item" v-on:click="stop">-->
        <!--          <img class="icon" src="../images/institution/icon_stop.png"/>-->
        <!--          <span>暂停</span>-->
        <!--        </button>-->
        <!--        <button class="btn-item" v-on:click="play">-->
        <!--          <img class="icon" src="../images/institution/icon_play.png"/>-->
        <!--          <span>播放</span>-->
        <!--        </button>-->

        <button v-if="iIsLive" class="btn-item" @click="onClickPlayBack">
          <img class="icon" src="../images/institution/icon_playback.png"/>
          <span>回放</span>
        </button>
        <button v-else class="btn-item" @click="onClickLive">
          <img class="icon" src="../images/institution/icon_live.png"/>
          <span>回到监控</span>
        </button>
        <button class="btn-item" v-on:click="onClickCaptureHygieneInspect">
          <img class="icon" src="../images/institution/icon_capture.png"/>
          <span>截图巡查</span>
        </button>
        <!--        <button  class="btn-item" v-on:click="fullScreen">-->
        <!--          <img class="icon" src="../images/institution/icon_full_screen.png"/>-->
        <!--          <span>全屏</span>-->
        <!--        </button>-->
      </div>


      <!--      <div class="btn-box">-->

      <!--        <button v-on:click="openSound">openSound</button>-->
      <!--        <button v-on:click="closeSound">closeSound</button>-->
      <!--        <button v-on:click="startSave">startSave</button>-->
      <!--        <button v-on:click="stopSave">stopSave</button>-->

      <!--        <button v-on:click="getOSDTime">getOSDTime</button>-->
      <!--        <button v-on:click="ezopenStartTalk">开始对讲</button>-->
      <!--        <button v-on:click="ezopenStopTalk">结束对讲</button>-->
      <!--        <button v-on:click="destroy">销毁</button>-->
      <!--      </div>-->

    </el-main>
    <chart-com-view title="最新抓拍/巡查照片" style="margin-top: 12px">
      <div class="capture-box">
        <div class="item-box" :class="selectCaptureId==item.id?'item-box-selected':''"
             v-for="(item, index) in captureList" :key="index" @click="onClickPlayBack(item)">
          <el-image class="cover" :src="item.type==1? item.faceImage:item.backgroundImage" fit="cover"/>
          <div class="time"><span>{{ formattedHMTime(item.captureTime) }}</span> <span class="capture-type">{{ item.type==1?'AI抓拍':'巡查' }}</span></div>
        </div>
      </div>
    </chart-com-view>
    <dialog-hygiene-inspect :inner-show="isShowInspectDialog" :idevice-id="deviceId" :capture-time="captureTime"
                            :capture-pic-data="capturePicBase64"
                            @onCloseInner="onCloseInnerDialog"></dialog-hygiene-inspect>
  </el-dialog>
</template>


<script>
import EZUIKit from "ezuikit-js";
import request from "@/http/request";
import moment from "moment/moment";
import ChartComView from "@/components/ChartComView.vue";
import DialogHygieneInspect from "@/components/DialogHygieneInspect.vue";

var player = null;

export default {
  name: "DialogVideoLive",
  components: {ChartComView, DialogHygieneInspect},
  props: {
    show: Boolean,
    isLive: Boolean,
    deviceId: Number,
    deviceData: Object,
    captureData: Object
  },
  use(plugin, options) {
    chartComView
  },
  data() {
    return {
      loading: false,
      appKey: '16612202ddd94bc2bd5d0629621f1f65',
      appSecret: '45027a7265660996b1859c3195634195',
      templatePcLive: "4314c098b2e148cfaa1a9054e4bc75f2",
      templatePcRec: "a0fe01a9ec554f40a4b894ce3a4fe58a",
      iIsLive: true,
      title: '',
      deviceBean: {},
      captureList: [],
      captureBean: {},
      deviceSn: String,
      channelNo: Number,
      selectCaptureId: null,
      capturePicBase64: '',
      captureTime: null,
      isShowInspectDialog: false
    }
  },
  mounted: () => {

  },

  methods: {
    formattedHMTime(time) {
      // return moment(this.time).format('YYYY-MM-DD HH:mm:ss'); // 这里的'time'为传递到组件的时间数据
      return moment(time).format('HH:mm'); // 这里的'time'为传递到组件的时间数据
    },
    onOpen() {
      this.iIsLive = this.isLive
      this.deviceBean = this.deviceData
      this.captureBean = this.captureData

      this.httpCaptureList();
      // this.httpDeviceInfo();
      // if (this.deviceBean) {
      //   this.startPlay()
      // }
      // 如果player 存在，先关闭
      if (player) {
        this.destroy();
      }
      this.loading = true
      request.httpPost(`/v1/business/device/monitor/detail/${this.deviceId}`, {}, success => {
        this.deviceBean = success
        this.loading = false
        this.startPlay()
      }, error => {
        this.loading = false
      })

    },
    startPlay() {
      if (this.deviceBean) {
        this.deviceSn = this.deviceBean.deviceSn
        this.channelNo = this.deviceBean.deviceChannelNo?this.deviceBean.deviceChannelNo:1
        // let title = ''
        // if (this.captureBean) {
        //   title = this.captureBean.shopName
        //   if (!this.iIsLive)
        //     this.selectCaptureId = this.captureBean.id
        // }
        this.title =  this.deviceBean ? ( this.deviceBean.ownerName +'(' + this.deviceBean.deviceName + ')'):''
      }
      console.group("onOpen 组件挂载完毕状态===============》");
      var playUrl = 'ezopen://open.ys7.com/' + this.deviceSn + '/' + this.channelNo + '.live'
      // var playUrl = 'ezopen://open.ys7.com/' + '33010038992717698107:33011054991117760282' +  '/' + this.channelNo + '.live'
      console.log(playUrl)
      fetch(`https://open.ys7.com/api/lapp/token/get?appKey=${this.appKey}&appSecret=${this.appSecret}`, {
        method: 'post',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => response.json())
        .then(res => {
          var accessToken = res.data.accessToken;
          if (player == null) {
            player = new EZUIKit.EZUIKitPlayer({
              id: 'video-container', // 视频容器ID
              accessToken: accessToken,
              url: playUrl,
              // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
              template: this.iIsLive ? this.templatePcLive : this.templatePcRec,
              plugin: ['talk'], // 加载插件，talk-对讲
              width: 1080,
              height: 720,
              seekFrequency:5000,
              begin:this.captureBean?moment(this.captureBean.captureTime).format('YYYYMMDDHHmmss'):''
            });
            window.player = player;
          } else {
            // player.url = playUrl
            // this.play()
            let options = {
              type: this.iIsLive ? 'live' : 'rec',
              deviceSerial: this.deviceSn,
              channelNo: this.channelNo
            }
            if (this.captureBean&&this.captureBean.captureTime) {
              options.begin = moment(this.captureBean.captureTime).format('YYYYMMDDHHmmss')
            }

            player.changePlayUrl(options)
              .then(() => {
                let template = this.iIsLive ? this.templatePcLive : this.templatePcRec
                console.log("template：" + template)
                player.Theme.changeTheme(template);
                console.log("切换成功")
              });
          }
        });
    },
    onClose() {
      // console.group("onClose 组件挂载完毕状态===============》");

      // this.destroy()
    },
    closeDialog() {
      this.destroy()
      // this.$props.show = false

    },

    onClickPlayBack(data) {
      this.iIsLive = false
      let template = "pcRec"
      // player.Theme.changeTheme(template)

      let options = {type: "rec"}
      if (data) {
        // type类型为回放有效，开始时间 格式为“YYYYMMDDHHmmss”
        options.begin = moment(data.captureTime).format('YYYYMMDDHHmmss')
        this.selectCaptureId = data.id
      } else {
        this.selectCaptureId = null
        options.begin = moment(new Date()).format('YYYYMMDDHHmmss')
      }

      // 预览切回放场景示例
      player.changePlayUrl(options).then(() => {
        console.log("地址切换成功，开始切换模板主题");
        player.Theme.changeTheme(this.templatePcRec).then((data) => {
          console.log("切换模板主题成功");
        });
      });
    },

    onClickLive() {
      this.iIsLive = true
      this.selectCaptureId = null
      let template = "pcLive"
      // 预览切回放场景示例
      player.changePlayUrl({type: "live"}).then(() => {
        console.log("地址切换成功，开始切换模板主题");
        player.Theme.changeTheme(this.templatePcLive).then((data) => {
          console.log("切换模板主题成功");
        });
      });
    },

    onClickCaptureHygieneInspect() {
      var getOSDTimePromise = player.getOSDTime();
      getOSDTimePromise.then((data) => {
        this.captureTime = moment(new Date(data.data * 1000)).format('YYYY-MM-DD HH:mm:ss')
        console.log("promise 获取 数据", this.captureTime)
        var capturePicturePromise = player.capturePicture(`${new Date().getTime()}`);
        capturePicturePromise.then((data) => {
          console.log("promise 获取 数据", data)
          this.capturePicBase64 = data.data.base64
          this.isShowInspectDialog = true
        })
      })

    },
    onCloseInnerDialog() {
      this.isShowInspectDialog = false
    },

    play() {
      this.loading = true
      var playPromise = player.play();

      playPromise.then((data) => {
        this.loading = false
        console.log("promise 获取 数据", data)
      })
    },
    stop() {
      var stopPromise = player.stop();
      stopPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    getOSDTime() {
      var getOSDTimePromise = player.getOSDTime();
      getOSDTimePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    capturePicture() {
      var capturePicturePromise = player.capturePicture(`${new Date().getTime()}`);
      capturePicturePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    openSound() {
      var openSoundPromise = player.openSound();
      openSoundPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    closeSound() {
      var openSoundPromise = player.closeSound();
      openSoundPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    startSave() {
      var startSavePromise = player.startSave(`${new Date().getTime()}`);
      startSavePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    stopSave() {
      var stopSavePromise = player.stopSave();
      stopSavePromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    ezopenStartTalk() {
      player.startTalk();
    },
    ezopenStopTalk() {
      player.stopTalk();
    },
    fullScreen() {
      player.fullScreen();
    },
    destroy() {
      if (player) {
        var destroyPromise = player.destroy();
        destroyPromise.then((data) => {
          console.log("promise 获取 数据", data)
          this.$emit('onClose')
          player = null
        })
      } else {
        this.$emit('onClose')
      }
    },
    //获取设备信息
    httpDeviceInfo() {
      this.loading = true
      request.httpPost(`/v1/business/device/monitor/detail/${this.deviceId}`, {}, success => {
        this.deviceBean = success
        this.deviceBean ? ( this.deviceBean.ownerName +'(' + this.deviceBean.deviceName + ')'):''
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
    //获取设备抓拍列表
    httpCaptureList() {

      request.httpPost('v1/business/institution_monitor/capture/list', {
        deviceId: this.deviceId
      }, success => {
        this.captureList = success.list
        this.hasNextPage = success.hasNextPage
      }, error => {
      })
    },
  }
};
</script>
<style scoped>


.close {
  color: #03FFDE;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.title {
  font-size: 20px;
  color: white;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
}

.dialog-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  background: rgba(89, 83, 210, 0.1);
  border: 1px solid rgba(0, 255, 255, 0.4);
  border-radius: 2px;


  .btn-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(89, 83, 210, 0.1);
    padding: 12px 20px;
    box-sizing: border-box;

    .btn-item {
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-size: 18px;
      line-height: 40px;
      color: white;

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }

}

.capture-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 170px;
//background: rgba(89, 83, 210, 0.1); //border: 1px solid rgba(0, 255, 255, 0.4); //border-radius: 2px; //margin-top: 12px; padding-right: 10px;
  box-sizing: border-box;

  .item-box {
    display: flex;
    flex-direction: column;
    width: 120px;
    margin-left: 10px;
    background-color: #014263;
    border-radius: 4px;

    &.item-box-selected {
      border: 1px solid #00FCF9;
      background-color: #0BA1F8;
    }

    .cover {
      width: 100%;
      height: 118px;
      border-radius: 4px;
    }

    .time {
      color: #ffffff;
      font-size: 16px;
      height: 30px;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .capture-type{
        font-size: 13px;
      }

    }
  }
}
</style>
