<template>
  <div class="container">
    <el-card class="card-box">
      <div class="ih4">
        <div class="tag-line"></div>
        <span class="tag-title">寻找意中人</span>
      </div>
      <el-row class="item-row" type="flex" align="center">
        <span class="label-title">热门分类：</span>
        <el-button  type="danger" class="cat-tag" @click="onClickSelectType(item.type)"  :class="item.type==selectType?'cat-tag-selected':''" v-for="item in tags" :key="item.label">
          {{ item.label }}
        </el-button>
      </el-row>
      <el-row class="item-row" type="flex" align="center">
        <span class="label-title">条件搜索：</span>
        <el-radio-group v-if="!token" class="gender-radio-group" @change="onSelectGender" v-model="conditions.gender">
          <el-radio label="1">男士</el-radio>
          <el-radio label="2">女士</el-radio>
        </el-radio-group>
        <el-select class="el-width" @change="onSelectConditions" v-model="conditions.birthday" placeholder="年龄不限" size="medium">
          <el-option label="年龄不限" value=""></el-option>
          <el-option label="22-25岁" value="1"></el-option>
          <el-option label="25-30岁" value="2"></el-option>
          <el-option label="30-35岁" value="3"></el-option>
          <el-option label="35-40岁" value="4"></el-option>
          <el-option label="40岁以上" value="6"></el-option>
        </el-select>
        <el-select class="el-width" @change="onSelectConditions" v-model="conditions.education" placeholder="学历不限" size="medium">
          <el-option label="学历不限" value=""></el-option>
          <el-option label="高中以下" value="1"></el-option>
          <el-option label="高中/中专" value="2"></el-option>
          <el-option label="本科/大专" value="3"></el-option>
          <el-option label="硕士" value="4"></el-option>
          <el-option label="博士/博士后" value="5"></el-option>
        </el-select>
        <el-select class="el-width" @change="onSelectConditions" v-model="conditions.marital_status" placeholder="婚姻不限" size="medium">
          <el-option label="婚姻不限" value=""></el-option>
          <el-option label="未婚" value="1"></el-option>
          <el-option label="离异无小孩" value="2"></el-option>
          <el-option label="离异有小孩" value="3"></el-option>
        </el-select>
        <el-select class="el-width" @change="onSelectConditions" v-model="conditions.monthly_income" placeholder="月收入不限" size="medium">
          <el-option label="收入不限" value=""></el-option>
          <el-option label="5千以下" value="1"></el-option>
          <el-option label="5千到1万" value="2"></el-option>
          <el-option label="1万到2万" value="3"></el-option>
          <el-option label="2万到3万" value="4"></el-option>
          <el-option label="3万以上" value="5"></el-option>
        </el-select>
        <el-select class="el-width" @change="onSelectConditions" v-model="conditions.height" placeholder="身高不限" size="medium">
          <el-option label="身高不限" value=""></el-option>
          <el-option label="150cm以下" value="1"></el-option>
          <el-option label="150-160cm" value="2"></el-option>
          <el-option label="160-165cm" value="3"></el-option>
          <el-option label="165-170cm" value="4"></el-option>
          <el-option label="170-175cm" value="5"></el-option>
          <el-option label="175-180cm" value="6"></el-option>
          <el-option label="180-185cm" value="7"></el-option>
          <el-option label="185-190cm" value="8"></el-option>
          <el-option label="190cm以上" value="9"></el-option>
        </el-select>
        <el-button v-if="isConditionsSelected" type="primary" @click="onClickClear" icon="el-icon-close" round size="small">清除条件</el-button>
      </el-row>

    </el-card>
    <div class="search-content">
      <div class="search-item" v-for="user in recUserArr" :key="user.id">
        <router-link :to="`/user_detail/${user.id}`">
          <el-image class="avatar" fit="cover" :src="user.avatar"></el-image>
          <div class="user-info">
            <div class="name">
              <span>{{ user.name }}</span>
              <img v-if="user.is_vip==1" style="margin-left: 10px" width="20" height="20" src="../images/icon_vip.png"/>
            </div>
            <div class="user-tag-box">
              <span class="user-tag">{{ getAge(user.birthday) }}</span>
              <span v-if="user.user_info&&user.user_info.height" class="user-tag">{{ user.user_info.height }}cm</span>
              <span class="user-tag">{{ user.city }}</span>
              <span v-if="user.user_info&&user.user_info.marital_status" class="user-tag">{{
                  maritalStatusArray[user.user_info.marital_status]
                }}</span>
              <span  class="user-tag">{{ user.occupation }}</span>
              <span  class="user-tag">{{ educationArray[user.education] }}</span>
            </div>
            <div class="mate-desc">{{ user.user_info.mate_selection_desc }}</div>
          </div>
        </router-link>
        <div class="info-box">
<!--          <router-link :to="`/msg_send/${user.id}`">-->
            <el-button class="btn" type="primary" @click="onClickSendMsg(user.id)" icon="el-icon-chat-round" round>联系TA</el-button>
<!--          </router-link>-->
        </div>
      </div>
      <el-empty style="margin: 200px 480px;" v-if="recUserArr.length==0" description="暂无该条件嘉宾,选择其他条件试试！"></el-empty>

    </div>
    <el-pagination
      class="el-page"
      background
      layout="prev, pager, next, jumper"
      :total="total"
      :page-size="pageSize"
      :pager-count="5"
      :hide-on-single-page="true"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="25%"
      center>
      <span style="text-align: center">你还没有登录</span>
      <br>
      <span style="text-align: center">登录后才能查看, 是否要登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button  class="dialog-footer-btn-left" @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary"  class="dialog-footer-btn-right" @click="onClickLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">

import constant from '../constant'
import request, { httpGet, httpPostPage } from '../http/request'
import { CodeToText } from 'element-china-area-data'
import { setStore, getStore } from '../utils/storage'
import { getAge } from '../utils/util'
// import { getAge } from '../utils/util'

export default {

  data () {

    return {
      token:'',
      userInfo:{},
      centerDialogVisible: false,
      tags: [
        {
          type: 0,
          label: '全部'
        },
        {
          type: 1,
          label: '同城'
        },
        {
          type: 2,
          label: '会员专区'
        },
        {
          type: 3,
          label: '高学历'
        },
        {
          type: 4,
          label: '90后'
        }
      ],
      isConditionsSelected: false,
      selectType:0,
      conditions: {
        gender: '',
        birthday: '',
        marital_status: '',
        height: '',
        education: '',
        monthly_income: ''
      },
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
      recUserArr: [],
      total: 0,
      pageSize: 20
    }
  },
  created () {
    if (this.$route.query.isVip==1){
      this.selectType = 2
    }
    this.token = getStore(constant.K_TOKEN)
    if (this.token) {
      request.httpGet('/api/user/get_my_info', {}, data => {
        this.userInfo = data
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }
    this.httpGetUsers(1)

  },

  methods: {
    onClickLogin(){
      this.$router.push("/");
    },
    onClickSendMsg(id){
      if (!this.token){
        this.centerDialogVisible = true
        return
      }
      if (this.userInfo.is_vip!=1){
        this.$message({
          message: '开通钻石会员后，才能发消息',
          type: 'error',
          offset: 80,
        })
        this.$router.push({
          path: '/vip'
        })
        return
      }
      this.$router.push(`/msg_send/${id}`)
    },
    onClickSelectType(type){
      if ((!this.token)&&(type==1||type==2)){
        this.centerDialogVisible = true
        return
      }
      if (this.selectType != type){
        this.selectType = type
        this.httpGetUsers(1)
      }
    },

    onSelectGender(value){
      this.httpGetUsers(1)
    },
    onSelectConditions(value){
      if (this.selectType>2){
        this.selectType=0
      }
      this.isConditionsSelected = true
      this.httpGetUsers(1)
    },
    onClickClear(){
      this.isConditionsSelected = false
      this.selectType = 0
      this.conditions.gender ='1'
      this.conditions.birthday = ''
      this.conditions.marital_status = ''
      this.conditions.height = ''
      this.conditions.education = ''
      this.conditions.monthly_income = ''
      this.httpGetUsers(1)
    },

    onPageChange (current) {
      console.log(current)
      this.httpGetUsers(current)
    },
    httpGetUsers (page) {
      request.httpPostPage('/api/web/meet/search-users', {
        type: this.selectType,
        gender:this.conditions.gender,
        birthday: this.conditions.birthday,
        marital_status: this.conditions.marital_status,
        height: this.conditions.height,
        education: this.conditions.education,
        monthly_income: this.conditions.monthly_income,
        page: page,
        per_page: this.pageSize

      }, success => {
        this.recUserArr = success.data
        this.total = success.total
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    },
    getAge(birthday){
      return getAge(birthday)
    }
  },
  components: {}
}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.container {
  width 100%
  display flex
  flex-direction column
  align-items center
}

.card-box
  width 1200px
  margin 20px 0

.ih4
  display flex
  flex-direction row
  align-items center

  .tag-title
    font-size 18px
    color #1f1f1f

.item-row
  margin-top 20px
  align-items center

  .gender-radio-group
    margin-right 16px
    line-height 32px !important
    font-size 14px

    .el-radio
      margin-right 10px

  .label-title
    font-size 15px
    color #282828

  .cat-tag
    height 32px
    line-height 32px
    text-align center
    border-radius 16px
    padding 0 20px
    margin-right 16px
    font-size 14px
    color #323232
    background-color #F0F0F0
    border none
    &.cat-tag-selected
      color white
      background-color #FF4666


.el-width {
  width 124px
  margin-right 20px
}


.search-content
  display flex;
  flex-direction row;
  flex-wrap wrap;
  width 1200px

  .search-item:nth-child(5n)
    margin-right 0

  .search-item
    width 224px
    height 350px
    margin-right 20px
    margin-top 20px
    position relative
    overflow hidden
    background-color white
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;



    .avatar
      position absolute
      left 15px
      top 15px
      width 194px
      height 194px
      border-radius 4px

    .user-info
      position absolute
      left 15px
      right 15px
      top 218px
      display flex
      flex-direction column

      .name
        font-size 18px
        color #282828
        font-weight bold
        display flex
        flex-direction row
        align-items center

      .user-tag-box
        display flex
        flex-direction row
        flex-wrap wrap;

        .user-tag
          height 16px
          padding 0 5px
          line-height 16px
          font-size 12px
          color #8070F1
          margin-right 8px
          margin-top 6px
          border 1px solid #8070F1
          border-radius 5px

      .mate-desc
        color #2B2B2B
        font-size 14px
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-top 10px

  .info-box
    display none

  //transition: all 1.5s ease-in-out;

  .search-item:hover
    .info-box
      position absolute
      left 0px
      bottom 0px
      width: 100%;
      height: 50px;
      background: linear-gradient(180deg, rgba(222, 112, 112, 0) 0%, #DE7070 100%);
      display flex
      flex-direction column
      color white
      //-webkit-transform:translateY(-100px);
      //transform: translateY(-100px);
      //transition: all 1.5s ease-in-out;


      .btn
        width: 80px;
        height: 28px;
        line-height 28px
        text-align center
        padding 0
        font-size 12px
        margin-left 72px
        margin-top 12px

        .el-icon-chat-round
          font-size 14px
          font-weight bold

.el-page
  margin-top 20px

  li
    background-color white !important

  li:not(.disabled).active
    background-color #409EFF !important
</style>
