
export default function getData(leftYData, rightYData,leftChatData, rightChatData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      right:0, //调整位置
      top:-2 ,//调整位置
      data: ['拥有', '不拥有']
    },
    grid: {
      left: '3%',
      right: '0%',
      top:'16%',
      bottom: '0%',
      containLabel: true
    },
    xAxis: {
      data: leftYData,
      name: '行动能力',
      splitArea: { show: false },
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#0478C3',
          fontSize: 14,
          padding:2,
        },
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#0478C3',
          fontSize: 14,
          padding:2,
        },
      },
    },
    series: [
      {
        name: '拥有',
        type: 'bar',
        stack: 'stack',
        label: {
          show: true
        },
        // emphasis: {
        //   focus: 'series'
        // },
        shading: 'lambert',
        emphasis: {
          label: {
            show: false
          }
        },
        itemStyle: {
          //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius:[5, 5, 5, 5],
            color: '#00ACFF',
            label: {
              show: true, //开启显示数值
              // position: 'center', //数值在上方显示
              textStyle: {  //数值样式
                color: '#ffffff',   //字体颜色
                fontSize: 13  //字体大小
              }
            }
          }
        },
        barGap:'0%',
        data: leftChatData
      },
      {
        name: '不拥有',
        type: 'bar',
        barWidth: 30, // 柱子宽度
        stack: 'stack',
        label: {
          show: true,
          position: 'top'
        },
        emphasis: {
          focus: 'series'
        },
        itemStyle: {
          //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius:[5, 5, 5, 5],
            color: '#FFB000',
            label: {
              show: true, //开启显示数值
              position: 'top', //数值在上方显示
              textStyle: {  //数值样式
                color: '#ffffff',   //字体颜色
                fontSize: 13  //字体大小
              }
            }
          }
        },
        barGap:'0%',
        data: rightChatData
      }
    ]
  };
}
