<template>
  <div class="container">
    <img class="bg-img-login" src="../images/login/bg_login.jpg"/>
    <div class="login-header">
      <div class="login-header-title">数字民政管理平台</div>
      <img class="bg-login-header" src="../images/login/bg_login_top.png"/>

    </div>
    <div class="login-box">
      <div class="login-title">欢迎登录</div>
      <el-form class="login-form" status-icon :rules="rules" ref="form" :model="form">
        <el-form-item class="login-form-item" prop="phone">
          <img class="icon-login" src="../images/login/icon_login_user.png">
          <el-input class="item-width-phone" type="tel" maxlength="11" v-model="form.phone" placeholder="请输入手机号"
                    autocomplete="on"></el-input>
        </el-form-item>
        <div class="login-code-container">
          <el-form-item class="login-form-item login-form-item-code">
            <img class="icon-login" src="../images/login/icon_login_code.png">
            <el-input class="item-width-code" maxlength="6" v-model="form.captcha" placeholder="请输入验证码"
                      autocomplete="on"></el-input>
          </el-form-item>
          <el-button class="btn-captcha" v-if="form.showloginCode" type="primary" @click="getLoginPhoneCode('form')">
            {{ this.form.isRetry ? '重新发送' : '获取验证码' }}
          </el-button>
          <div class="btn-captcha-txt" v-else>{{ form.count }}S后重新获取</div>
        </div>


        <el-form-item style="margin-bottom: 10px">
          <el-button class="btn-login" size="medium" type="primary" @click="onClickLogin('form')">登 录
          </el-button>
        </el-form-item>
      </el-form>

    </div>
    <div class="copy-right">Copyright©木子信息科技版权所有</div>
    <el-dialog
      title="请选择用户身份后登录"
      :visible.sync="centerDialogVisible"
      width="400px"
      center>
        <div style="display: flex; flex-direction: column;align-items: center">
          <el-button style="width: 300px; height: 46px; border-radius:23px;font-size:16px;margin-bottom: 30px;margin-left: 0" v-for="(item,index) in userArr" v-bind:key="index" @click="onClickSelectUserType(item.userType)" size="medium"
                     round>{{ item.name }}
          </el-button>
        </div>
    </el-dialog>

    <dialog-select-monitor  :show="selectMonitorShow" @onClose="selectMonitorShow=false"></dialog-select-monitor>
  </div>

</template>

<script type="text/ecmascript-6">

import constant from '../constant'
import request, {httpGet} from '../http/request'
import {CodeToText} from 'element-china-area-data'
import {setStore, getStore} from '../utils/storage'
import {getAge, getUserInfoCompletion} from '../utils/util'
import VerificationCode from '../components/VerificationCode'
import DialogSelectMonitor from "@/components/DialogSelectMonitor.vue";

export default {

  data() {
    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('手机号格式不正确'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号格式不正确'))
          }
        }
      }, 100)
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error('请输入8-20位密码'))
          return
        }
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        if (value.length < 4 || value.length > 26) {
          callback(new Error('请输入4-6位验证码'))
          return
        }
        // if (this.form.checkPass !== '') {
        //   this.$refs.form.validateField('checkPass')
        // }
        callback()
      }
    }
    return {
      centerDialogVisible: false,
      selectMonitorShow:false,
      form: {
        phone: '',
        password: '',
        isRuleChecked: false,
        captcha: '', //验证码
        captcha_key: '', // 验证码对应的key
        timer: null, //定时器的开关
        showloginCode: true, //获取成功验证码后倒计时跟获取不成功后没有倒计时按钮的开关
        count: '', //倒计时
        isRetry: false
      },
      userArr: [],
      rules: {
        phone: [
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            validator: validateCode,
            trigger: 'blur'
          }
        ]
      },
      token: '',
      userInfo: {},
      visibleVerify: false,  // 图形验证码是否显示

    }
  },
  created() {
    // let tab = getStore(constant.K_TAB_VIEW_BEFORE)
    // console.log('tab:' + tab)
    this.token = getStore(constant.K_TOKEN)
    this.form.phone = getStore(constant.K_PHONE)
    /*if (this.token) {
      request.httpGet('/api/user/get_my_info', {}, data => {
        this.userInfo = data
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })
    }*/
  },

  methods: {

    getLoginPhoneCode(formName) {

      this.$refs[formName].validateField('phone', (phoneValid) => {
          if (!phoneValid) {
            // this.$refs[formName].validateField('password', (passwordValid) => {
            //   if (!passwordValid) {

            let phone = this.form.phone
            request.httpPost('/v1/shop/login/sendcode', {'phone': phone}, success => {
              this.$message({
                message: '验证码发送成功',
                type: 'success',
                offset: 80
              })
              this.form.captcha_key = success.key
              // 当验证码发送成功，开始60秒倒计时
              const time = 60
              if (!this.form.timer) {
                this.form.showloginCode = false
                this.form.count = time
                this.form.timer = setInterval(() => {
                  if (this.form.count > 0 && this.form.count <= time) {
                    this.form.count -= 1
                  } else {
                    this.form.showloginCode = true
                    clearInterval(this.form.timer)
                    this.form.timer = null
                    this.form.isRetry = true
                  }
                }, 1000)
              }
            }, error => {
              this.$message({
                message: error,
                type: 'error',
                offset: 80
              })
              console.log(error)
            })
            // } else {
            //   this.$message({
            //     message: '请输入8-20位密码！',
            //     type: 'error',
            //     offset: 80
            //   })
            // }
            //   }
            // )
          } else {
            this.$message({
              message: '请先输入正确的手机号！',
              type: 'error',
              offset: 80
            })
          }
        }
      )
    },

    onClickLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visibleVerify = true
          setStore(constant.K_PHONE, this.form.phone)
          this._httpLogin(-1)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    onClickSelectUserType(userType) {
      this.centerDialogVisible = false
      this._httpLogin(userType)
    }
    ,
    onClickLogout() {
      this.centerDialogVisible = false
      this.token = ''
      this.userInfo = {}
      setStore(constant.K_TOKEN, '')
      this.$router.go(0)
    },

    _httpLogin(type) {
      var params = {
        userType: type,    // 首次登陆不需要用户类型或者传-1. 返回的accountNum>1，确认用户类型时要传
        phone: this.form.phone,
        code: this.form.captcha
      }
      request.httpPost('/v1/shop/login/phone_code_screen', params, success => {
        if (success.accountList == null || success.accountList.length==0 ) {
          this._loginSuccess(success)
        } else {
            this.userArr=success.accountList,
            this.centerDialogVisible = true
        }
      }, error => {
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
      })
    },
    _loginSuccess(userInfo) {
      setStore(constant.K_TOKEN, userInfo.token)
      setStore(constant.K_APP_ID, userInfo.appId)
      setStore(constant.K_PROVINCE_ID, userInfo.provinceId?userInfo.provinceId:"")
      setStore(constant.K_CITY_ID, userInfo.cityId?userInfo.cityId:"")
      setStore(constant.K_DISTRICT_ID, userInfo.districtId?userInfo.districtId:"")
      setStore(constant.K_GROUP_ID, userInfo.groupId?userInfo.groupId:"")
      setStore(constant.K_VILLAGE_ID, userInfo.villageId?userInfo.villageId:"")
      setStore(constant.K_USER_TYPE, userInfo.userType)
      setStore(constant.K_UID, userInfo.id)
      // this.$router.replace('/')
      this.selectMonitorShow = true
    },

    getAge(birthday) {
      return getAge(birthday)
    },

    getUserInfoCompletion(userInfo) {
      return getUserInfoCompletion(userInfo)
    }

  },
  components: {DialogSelectMonitor}
}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"

.container {
  width 100%
  display flex
  flex-direction column
  align-items center
}

.bg-img-login {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
}


.login-box
  position: absolute;
  right: 12%;
  top: 36%;
  width: 641px;
  height: 492px;
  background-image: url("../images/login/bg_login_rect.png");
  background-size: contain;
  z-index 1

  .login-title
    font-size: 36px;
    font-weight: bold;
    font-family: Source Han Sans CN;
    color: #FFFFFF;
    text-align center
    margin-top 52px

  .login-form
    display flex
    flex-direction column
    align-items center
    margin-top 40px


    .login-form-item
      width: 420px;
      height: 48px;
      display flex
      flex-direction row
      align-items center
      background: rgba(11, 161, 248, 0.3);
      border 1px solid #0BA1F8
      margin-top 20px

      .icon-login
        width 20px
        height 20px
        margin-left 15px

      .item-width-phone
        width 380px
        height: 48px;
        text-align center
        line-height 48px

      .el-input__inner
        background-color transparent
        border none
        color white
        font-size 18px

    .login-form-item-code
      width: 230px !important;

      .item-width-code
        width 180px
        height: 48px;
        text-align center
        line-height 48px


  .btn-login
    width 261px
    height 73px
    line-height 56px
    text-align center
    font-weight 400 !important
    color: #49E3F9 !important;
    font-size: 26px;
    margin-top 30px
    background-image: url("../images/login/btn_login.png") !important;
    background-size: cover !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border none

    &:hover {
      color: #b3ebf3 !important;
      background-image: url("../images/login/btn_login.png") !important;
      background-size: cover !important;
    }

  .btn-captcha {
    width: 163px;
    height: 48px;
    margin-left 30px
    background-color: #193A75 !important;
    border 1px solid #0BA1F8 !important
    font-size 18px !important
    color: #49E3F9 !important;
  }

  .btn-captcha:hover {
    color: #b3ebf3 !important;
  }

  .btn-captcha-txt {
    width: 163px;
    height: 48px;
    line-height 48px
    margin-left 30px
    background-color: #193A75 !important;
    border 1px solid #0BA1F8 !important
    font-size 18px !important
    color: #49E3F9 !important;
  }


.login-code-container {
  display flex
  flex-direction row
  align-items center
}

  .login-header{
    position: absolute;
    left: 0;
    top: 20px;
  }
.login-header .bg-login-header{
  width 80%
  height 83px
  object-fit contain
  margin-top -35px
}
.login-header .login-header-title{
  font-size: 32px;
  font-weight: bold;
  color: #7DFCFE;
  z-index 100
  background: linear-gradient(0deg, rgba(255,255,255,0.73) 44.7%, rgba(68,123,235,0.4) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(125, 252, 254, 0.36);
}


.copy-right{
  width 100%
  position fixed
  z-index 10
  font-size: 16px;
  color: #FFFFFF;
  line-height: 30px;
  opacity: 0.5;
  bottom 10px
  left 0
  text-align center
}



</style>
