<template>
  <div class="container personal-center-container">
    <div class="personal-center-content">
      <LeftSiderBar :userBean="userBean" navIndex="/user/my_love"></LeftSiderBar>
      <div class="right-content">

        <div class="ih4">
          <div class="tag-line"></div>
          <span class="tag-title">我关注的</span>
        </div>
        <MyLoveUser :userArr="userArr" :isNeedVip="1"></MyLoveUser>
      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">
import request, { httpGet } from '../../http/request'
import LeftSiderBar from '../../components/LeftSiderBar'
import MyLoveUser from '../../components/MyLoveUser'

export default {
  components: {LeftSiderBar, MyLoveUser},
  data () {

    return {
      recUserArr: [],
      userArr: [],
      userId: 12,
      userBean: {},
      vipRadio: 3,
    }
  },
  created () {

    request.httpGet(`/api/circle/user/get_my_info`, {}, success => {
      this.userBean = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

    var currentPage = 1
    request.httpGet(`/api/user/my_loves?page=${currentPage}&type=1`, {}, success => {
    // request.httpGet(`/api/user/love_mes?page=${currentPage}&type=1`, {}, success => {

      this.userArr = success
    }, error => {
      this.$message({
        message: '网络请求失败',
        type: 'error',
        offset: 80
      })
    })

  },

  methods: {
    onPageChange (current) {
      console.log(current)
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },

  },

}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../../common/stylus/mixin.styl"

.personal-center-container {
  width 100%
  display flex
  flex-direction column
  align-items center
  background: linear-gradient(180deg, #FF4666 0%, #FF809B 120px, #FF86A7 320px, #f6f6f6 321px, #f6f6f6 100%);

}


.user-detail-card
  margin 20px 0

.ih4
  display flex
  flex-direction row
  align-items center

  .tag-title
    font-size 18px
    color #1f1f1f



</style>
