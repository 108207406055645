var legendData = [
  {
    name: '未处理',
    itemStyle: {
      color: 'rgba(248, 189, 0, 0.8)'
    },
    textStyle: {
      color: 'rgba(255,255,255,0.9)'
    }
  },
  {
    name: '已处理',
    itemStyle: {
      color: 'rgba(0, 195, 255, 0.8)'
    },
    textStyle: {
      color: 'rgba(255,255,255,0.9)'
    }
  }];
var color = [
  {
    type: 'linear',
    x: 0,
    x2: 1,
    y: 0,
    y2: 0,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(255,217,120,0.9)'
      },
      {
        offset: 0.5,
        color: 'rgba(255,217,120,0.9)'
      },
      {
        offset: 0.5,
        color: 'rgba(249,198,0,0.8)'
      },
      {
        offset: 1,
        color: 'rgba(249,198,0,0.8)'
      }
    ]
  },
  {
    type: 'linear',
    x: 0,
    x2: 1,
    y: 0,
    y2: 0,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(96,210,255,0.8)'
      },
      {
        offset: 0.5,
        color: 'rgba(96,210,255,0.8)'
      },
      {
        offset: 0.5,
        color: 'rgba(12,170,212,0.9)'
      },
      {
        offset: 1,
        color: 'rgba(12,170,212,0.9)'
      }
    ]
  },
  {
    type: 'linear',
    x: 0,
    x2: 1,
    y: 0,
    y2: 0,
    colorStops: [
      {
        offset: 0,
        color: 'rgba(0,188,255,0)'
      },
      {
        offset: 0.5,
        color: 'rgba(0,188,255,0)'
      },
      {
        offset: 0.5,
        color: 'rgba(0,138,186,0)'
      },
      {
        offset: 1,
        color: 'rgba(0,138,186,0)'
      }
    ]
  }
];
var barWidth = 16;
function getBar2(dataArr, dataArr2) {
  let resultDataArr = [];
  for (let i = 0; i< dataArr.length; i ++){
    resultDataArr.push(Number(dataArr[i]) + Number(dataArr2[i]))
  }
  return resultDataArr;
}
export default function getData(leftYData, rightYData,leftChatData, rightChatData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    toolbox: {
      show: true,
      top:'5',
      right:'5',
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },

    legend: {
      itemGap: 16, //间隔
      padding: [
        0, 30, 0,0
      ],
      itemWidth: 26,
      itemHeight: 15,
      bottom: '3%',
      left:'5%',
      textStyle: {
        color:'#fff',
        fontSize: 12
      }
    },
    grid: { //控制边距大小
      left: '50px',
      right: '2%',
      bottom: '25%',
      top: '70px',
      containLabel: false
    },
    xAxis: {
      data: leftYData,
      name: '问题类型',
      splitArea: { show: false },
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        interval: 0,      // 坐标轴刻度标签的显示间隔
        rotate: 26,        // 标签倾斜的角度
        textStyle: {
          color: '#ffffff',
          fontSize: 12,
          padding:0,
        },
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#ffffff',
          fontSize: 12,
          padding:2,
        },
      },
    },


    series: [
      // data2本体
      {
        z: 1,
        name: legendData[1].name,
        type: 'bar',
        barWidth: barWidth,
        stack: '总量',
        color: color[1],
        label: {
          show: true,
          textStyle: {  //数值样式
            color: '#ffffff',   //字体颜色
            fontSize: 13  //字体大小
          }
        },
        data: leftChatData
      },
      // data1本体
      {
        z: 2,
        name: legendData[0].name,
        type: 'bar',
        barWidth: barWidth,
        stack: '总量',
        color: color[0],
        label: {
          show: true,
          position: 'top',
          textStyle: {  //数值样式
            color: '#ffffff',   //字体颜色
            fontSize: 13  //字体大小
          }
        },
        data: rightChatData
      },
      // 底部立体
      {
        z: 3,
        name: legendData[1].name,
        type: 'pictorialBar',
        data: rightChatData,
        symbol: 'diamond',
        symbolOffset: ['0%', '50%'],
        symbolSize: [barWidth, 10],
        itemStyle: {
          normal: {
            color: color[1]
          }
        },
        tooltip: {
          show: false
        }
      },
      // data2头部
      {
        z: 5,
        name: legendData[1].name,
        type: 'pictorialBar',
        symbolPosition: 'end',
        itemStyle: {
          normal: {
            color: color[1]
          }
        },
        data: leftChatData,
        symbol: 'diamond',
        symbolOffset: ['0%', '-50%'],
        symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
        tooltip: {
          show: false
        }
      },
      // data1头部
      {
        z: 4,
        name: legendData[0].name,
        type: 'pictorialBar',
        data: getBar2(leftChatData, rightChatData),
        symbol: 'diamond',
        symbolPosition: 'end',
        symbolOffset: ['0%', '-50%'],
        symbolSize: [barWidth, 10],
        itemStyle: {
          normal: {
            color: color[0]
          }
        },
        tooltip: {
          show: false
        }
      }
    ],
  };
}
