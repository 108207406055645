<template>
  <div class="container vip-container">

    <el-dialog
      title="开通钻石会员"
      :visible.sync="centerDialogVisible"
      width="600px"
      :before-close="handleClose"
      center>
      <div class="vip-dialog-box">
        <div class="vip-header">
          <img src="../images/icon_vip_big.png" width="80" height="80">
          <div class="vip-header-tip">
            <div class="vip-txt">开通服务：<span class="vip-time">钻石会员{{vip_level==4?'12':vip_level==3?'6':vip_level==2?'3':'1'}}个月</span></div>
            <div class="vip-txt">支付金额：<span class="vip-money">{{vip_level==4?'388':vip_level==3?'288':vip_level==2?'188':'88'}}元</span></div>
          </div>
        </div>
        <div class="vip-pay-type">

          <div class="vip-label">支付方式：</div>
          <div :class="selectPayType==1?'vip-pay-type-item-selected':''" class="vip-pay-type-item" v-on:click="onClickCheckPay(1)">
            <img src="../images/icon_pay_wechat.png" width="26" height="22">
            <div class="pay-txt">微信支付</div>
          </div>
          <div class="vip-pay-type-item" :class="selectPayType==0?'vip-pay-type-item-selected':''" v-on:click="onClickCheckPay(0)">
            <img src="../images/icon_pay_ali.png" width="22" height="22">
            <div class="pay-txt">支付宝</div>
          </div>

        </div>
        <div class="qr-box">
          <!-- 生成的二维码会放在这里-->
          <div class="qr-code" v-html="qrCodeHtml"></div>
          <div class="qr-tip">
            请使用微信扫一扫 <br/> 扫描图中二维码支付
          </div>
        </div>
      </div>


      <!--      <span slot="footer" class="dialog-footer">-->
      <!--        <el-button @click="centerDialogVisible = false">取 消</el-button>-->
      <!--        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>-->
      <!--      </span>-->
    </el-dialog>


    <div class="top-container">
      <div class="vip-tip-show" v-if="token&&userInfo&&userInfo.is_vip==1">
        恭喜您！已经成为我们的vip钻石会员，
        会员到期时间：{{(userInfo&&userInfo.vip_end_date)?userInfo.vip_end_date.substring(0,10):''}}
      </div>
      <div class="left-box">
        <div class="title">解锁授信/发信 快速找对象</div>
        <div class="title1">超多实用权限，总有一项能助你快速找到对象</div>
        <el-button class="btn-open-vip" @click="doOpenVip(4)" round>立即开通</el-button>
      </div>
      <img class="bg-logo" src="../images/vip/bg_vip_logo.png">

    </div>

    <div class="vip-box">

      <div class="vip-content">

        <div class="vip-item">
          <div class="top-box">
            <span class="name">钻石会员</span>
            <span class="month">12个月</span>
            <img class="icon" src="../images/vip/icon_vip_white.png">
          </div>
          <div class="title">超值会员价<span class="price">388</span>元</div>
          <div class="info">1.06元/天</div>
          <el-button class="btn" @click="doOpenVip(4)" round>立即开通</el-button>
        </div>
        <div class="vip-item">
          <div class="top-box">
            <span class="name">钻石会员</span>
            <span class="month">6个月</span>
            <img class="icon" src="../images/vip/icon_vip_white.png">
          </div>
          <div class="title">超值会员价<span class="price">288</span>元</div>
          <div class="info">1.58元/天</div>
          <el-button class="btn" @click="doOpenVip(3)" round>立即开通</el-button>
        </div>
        <div class="vip-item">
          <div class="top-box">
            <span class="name">钻石会员</span>
            <span class="month">3个月</span>
            <img class="icon" src="../images/vip/icon_vip_white.png">
          </div>
          <div class="title">超值会员价<span class="price">188</span>元</div>
          <div class="info">2.08元/天</div>
          <el-button class="btn" @click="doOpenVip(2)" round>立即开通</el-button>
        </div>
        <div class="vip-item">
          <div class="top-box">
            <span class="name">钻石会员</span>
            <span class="month">1个月</span>
            <img class="icon" src="../images/vip/icon_vip_white.png">
          </div>
          <div class="title">超值会员价<span class="price">88</span>元</div>
          <div class="info">2.93元/天</div>
          <el-button class="btn" @click="doOpenVip(1)" round>立即开通</el-button>
        </div>
      </div>
      <div class="vip-spec-title">钻石会员特权</div>
      <div class="vip-spec">
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_re_msg.png">
          <div class="vip-spec-item-label">解锁收信</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_send_msg.png">
          <div class="vip-spec-item-label">解锁发信</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_rec.png">
          <div class="vip-spec-item-label">首页推荐展示</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_logo.png">
          <div class="vip-spec-item-label">获取vip会员勋章</div>
        </div>
        <div class="vip-spec-item">
          <img class="vip-spec-item-logo" src="../images/vip/icon_vip_view.png">
          <div class="vip-spec-item-label">查看谁看过我</div>
        </div>
      </div>

      <div class="vip-info">
        <div class="vip-info-title">服务说明</div>
        <div class="vip-info-content">
          1、购买的服务仅限本账号使用，不能转移到其他账号；<br/>
          2、在使用本服务期间，若从事不恰当或不合法的行为，风筝缘保留进行调查并立即终止服务的权利；<br/>
          3、对于本服务和以上条款，在法律允许范围内风筝缘拥有解释权。
        </div>
        <div class="vip-info-title">安全征婚提示</div>
        <div class="vip-info-content">
          在以下情况下请不要轻易透露你的联系方式（如电话、手机号码、邮箱、即时通信、通信地址等）<br/>
          1、在未充分了解对方前，请不要轻易透露你的联系方式。<br/>
          2、当对方无相片或资料不完整时，请不要轻易透露你的联系方式。<br/>
          3、相亲过程中，如遇对方提及借款、投资或索要等行为，请务必提高警惕，谨防受骗，并及时举报。<br/>
          4、为保障征婚安全，你需要完成实名人脸认证，才可发送/回复消息。
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="loginDialogVisible"
      width="25%"
      center>
      <span style="text-align: center">登录后才能加入钻石会员, 是否要登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="loginDialogVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="onClickLogin" size="medium">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">

import constant from '../constant'
import { getStore, removeStore, setStore } from '../utils/storage'
import request, { httpPostPayOnly } from '../http/request'
import { CodeToText } from 'element-china-area-data'

export default {

  data () {
    return {
      timer: '',
      activeName: 'second',
      centerDialogVisible: false,
      loginDialogVisible: false,
      qrCodeHtml: '',
      selectPayType: 1,  // 0--支付宝  1--微信
      isPaying:false, // 是否在扫码支付中，或者支付宝跳转支付返回
      token:'',
      userInfo:'',
      vip_level:4,
      isFirstMyInfoVip: false,
      isPaid: false
    }
  },
  created () {
    // var out_trade_no = this.getUrlParam('out_trade_no')
    var url = window.location.href
    console.log((url))

    if (url.indexOf("out_trade_no") != -1) {
      window.location.href = "https://www.cxzai.com/#/vip?is_pay=1"
      // this.isPaying = true
      console.log(('in'))
    } else {
    }
    let level = this.$route.query.level
    if (level) {
      this.vip_level = level
      this.centerDialogVisible = true
    }
    let isPay = this.$route.query.is_pay
    if (isPay){
      this.isPaid = true
      this.isPaying = true
    }
    this.httpGetMyInfo(true)

  },
  mounted () {
    // this.timer = setInterval(this.get, 5000);
  },

  beforeDestroy() {
    if (this.timer)
      clearInterval(this.timer);
  },

  methods: {
    onClickLogin(){
      this.$router.push('/')
    },
    handleClose(){
      this.centerDialogVisible = false
      if (this.timer){
        clearInterval(this.timer)
      }
    },
    onClickCheckPay (type) {
      this.selectPayType = type
      this.qrCodeHtml=''
      this.getPayQr(type)
    },
    doOpenVip (level) {
      if (this.token){
        this.centerDialogVisible = true
        this.vip_level = level
        this.getPayQr(this.selectPayType)
      }else {
        this.loginDialogVisible = true
      }

    },
    getPayQr(type){
      var params = {
        vip_level: this.vip_level
      }
      if (type == 0) {
        if (this.timer){
          clearInterval(this.timer)
        }
        request.httpPostPayOnly('/api/payment/ali-open-vip-pay', params, success => {
        // request.httpPostPayOnly('/api/payment/ali-open-vip-pay-qr-scan', { order_guid: '782392038978978497' }, success => {
          console.log(success)
          // this.qrCodeHtml = success
          document.write(success)
        }, error => {
          this.$message({
            message: error,
            type: 'error',
            offset: 80
          })
          console.log(error)
        })
      }else if (type==1){

        request.httpPost('/api/payment/wx-open-vip-pay', params, success => {

          // this.$message({
          //   message: '恭喜你，支付成功',
          //   type: 'success',
          //   offset: 80
          // });
          console.log(success)
          this.qrCodeHtml = success['html']
          // $('#qrcode2').html(success)
          var that = this
          this.timer = setInterval(()=>{
            that.httpGetMyInfo()
          }, 4000);
          this.isPaying = true

        }, error => {
          this.$message({
            message: error,
            type: 'error',
            offset: 80
          })
          console.log(error)
        })
      }

    },
    httpGetMyInfo(isFirst){
      this.token = getStore(constant.K_TOKEN)
      if (this.token) {
        request.httpGet('/api/user/get_my_info', {}, data => {

          this.userInfo = data
          if (this.userInfo.is_vip==1){
            if (this.timer){
              clearInterval(this.timer)
            }
            if (isFirst){
              this.isFirstMyInfoVip = true
            }
            if (this.isPaying){
              if (this.isPaid&&isFirst){
                this.$message({
                  message: '恭喜你，已经成为风筝缘vip会员！',
                  type: 'success',
                  offset: 80
                })
                this.isPaid = false
              }
              if (!this.isFirstMyInfoVip){
                this.centerDialogVisible = false
              }

            }
          }
        }, error => {
          this.$message({
            message: '网络请求失败',
            type: 'error',
            offset: 80
          })
        })
      }
    },
    getUrlParam (name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
  },
  components: {}
}
</script>

<style lang="stylus" rel="stylesheet/stylus">

@import "../common/stylus/mixin.styl"


.vip-dialog-box
  display flex
  flex-direction column

  .vip-header
    display flex
    flex-direction row
    align-items center

    .vip-logo
      margin-right 16px

    .vip-header-tip
      display flex
      flex-direction column
      justify-content space-around

      .vip-txt
        display flex
        flex-direction row
        align-content center
        font-size 16px
        color #151515
        margin-left 18px
        padding 5px 0

        .vip-time
          color #FF4666

        .vip-money
          color #FF4666
          font-size 18px

  .vip-pay-type
    display flex
    flex-direction row
    align-items center
    margin-top 40px

    .vip-label
      font-size 18px
      color #1f1f1f
      margin-right 6px

    .vip-pay-type-item
      height 40px
      padding 0 18px
      border-radius 4px
      display flex
      flex-direction row
      align-items center
      justify-content center
      margin-right 22px
      border 1px solid #CCCCCC

      &.vip-pay-type-item-selected
        border 1px solid #0a44f8

      .pay-type-logo
        margin-right 10px

      .pay-txt
        color #333333
        font-size 16px
        margin-left 10px

  .qr-box
    display flex
    flex-direction row
    align-items center
    height 220px
    .qr-code
      margin-left 60px
      width 220px
      height 220px
    .qr-tip
      font-size 18px
      color #151515


.vip-container {
  width 100%
  display flex
  flex-direction column
  align-items center

  background: linear-gradient(180deg, #FF4666 0%, #FF809B 140px, #FF86A7 678px, #f6f6f6 679px, #f6f6f6 100%);
}



.top-container
  width 1200px
  display flex
  flex-direction row
  margin-top 20px
  position relative
  .vip-tip-show
    position absolute
    left 20px;
    top 0px;
    background-color gold;
    border-radius 6px;
    padding 10px 20px;
    box-sizing border-box
    color #845d01
    font-size 16px
    font-weight bold
    z-index 2

  .left-box
    display flex
    flex-direction column
    flex 1
    margin-top 60px
    padding-left 20px

    .title
      font-size 56px

      font-weight 500
      color #FFFFFF
      line-height 78px

    .title1
      font-size 28px

      font-weight 500
      color #FFFFFF
      line-height 40px
      margin-top 22px

    .btn-open-vip
      width 192px
      height 70px
      background linear-gradient(90deg, #FFF353 0%, #F2BB00 100%)
      border-radius 35px
      margin 53px 0 0 18px
      font-size 28px
      font-weight 500
      color #2A2A2A

  .bg-logo
    width 253px
    height 342px
    margin-right 66px


.vip-box
  width 1200px
  box-sizing border-box
  display flex;
  flex-direction column;
  background-color white
  padding 10px 10px 20px 20px
  margin-top 150px
  border-radius 10px

  .vip-content
    display flex;
    flex-direction row;
    align-items center
    justify-content space-around
    margin-top -92px
    z-index 1

    .vip-item
      width 269px
      height 333px
      display flex
      flex-direction column
      align-items center
      background-color white
      box-shadow: 0px 2px 6px 2px rgba(192, 192, 192, 0.5);
      border-radius: 10px;

      .top-box
        position relative
        width 100%
        height 124px
        background linear-gradient(170deg, #FFFD0F 0%, #F7B500 100%)
        border-radius 10px

        .name
          font-size: 32px;
          font-weight: 600;
          color: #A27702;
          position absolute
          left 66px
          top 12px

        .month
          font-size: 36px;
          font-weight: 600;
          color: #A27702;
          position absolute
          left 75px
          top 64px

        .icon
          width 82px
          height 73px
          position absolute
          right 0
          bottom 0

      .title
        font-size: 26px;
        font-weight: 400;
        color: #A27702;
        text-align bottom
        margin-top 10px

        .price
          font-size: 40px;
          font-weight: 600;
          color: #A27702;

      .info
        font-size: 42px;
        font-weight: 400;
        color: #A27702;
        margin-top 6px

      .btn
        width: 168px;
        height: 40px;
        line-height 40px
        text-align center
        padding 0
        margin-top 12px
        background: linear-gradient(78deg, #FFF026 0%, #FFCC1E 100%);
        font-size: 16px;
        font-weight: 500;
        color: #2A2A2A;

  .vip-spec-title
    font-size: 36px;
    font-weight: 600;
    color: #A27702;
    margin 66px 0 52px 20px
  .vip-spec
    display flex
    flex-direction row
    align-items center
    justify-content space-around
    .vip-spec-item
      display flex
      flex-direction column
      align-items center
      .vip-spec-item-logo
        width 96px
        height 96px
      .vip-spec-item-label
        font-size: 20px;
        color: #3B3B3B;
        margin-top 28px
  .vip-info
    display flex
    flex-direction column
    border-radius 20px
    box-sizing border-box
    padding 20px 40px
    border 1px solid #E1E1E1
    margin 70px 20px 50px 20px
    .vip-info-title
      color #2A2a2a
      font-size 18px
      font-weight bold
      line-height 50px
    .vip-info-content
      color #333333
      font-size 16px
      line-height 36px
</style>
