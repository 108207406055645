<template>
  <section class="box-scroll-table" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">
    <div  class="content">
      <span class="txt head-txt">
        <el-dropdown size="mini"  @command="handleCommand">
            <span class="head-txt">
              排名 <span style="color: #00FFF6; font-size: 13px">{{recentlyYearArr[curIndex].name}}</span><i class="el-icon-caret-bottom"   style="color: #00FFF6; font-size: 14px"></i>
            </span>
            <el-dropdown-menu ref="dropdown" @command="handleCommand">
              <el-dropdown-item v-for="(item,index) in recentlyYearArr"  :key="index" :command="index" divided>{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

      </span>
      <span class="txt head-txt">乡镇</span>
      <span class="txt head-txt" >探视人数/总人数</span>
      <span class="txt head-txt">探视率%</span>
      <span class="txt head-txt"> 登记问题</span>
      <span class="txt head-txt">走访次数</span>
      <span class="txt head-txt">走访完成率%</span>
    </div>
    <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp" >
      <div v-for="(item, index) in listData" :key="index"  class="content" @click="onClickItemListener(item)">
        <span class="txt rank">{{index+1}}</span>
        <span class="txt">{{item.name}}</span>
        <span class="txt">{{item.visitPersonNum}}/{{item.totalPersonNum}}</span>
        <span class="txt">{{item.totalPersonNum>0?(item.visitPersonNum/item.totalPersonNum*100).toFixed(2):0}}%</span>
        <span class="txt">{{item.questionNum}}</span>
        <span class="txt">{{item.visitsNum}}</span>
        <span class="txt">{{item.totalPersonNum>0?((item.visitsNum>item.totalPersonNum*4?item.totalPersonNum*4:item.visitsNum)/item.totalPersonNum/4*100).toFixed(2):0}}%</span>
      </div>
    </vue-seamless-scroll>
  </section>

</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import request from "@/http/request";
import helloWorld from "@/components/CapturePhotoView.vue";

export default {
  name: 'AutoScrollTableViewVisit',
  computed: {
    helloWorld() {
      return helloWorld
    }
  },
  components: {
    vueSeamlessScroll
  },
  props: {
    listData: {
      type: Array
    },
    recentlyYearArr:{
      type: Array
    },
    curSelectUserType:{
      type: Number
    },
    provinceId: String,
    cityId: String,
    districtId: String,
    groupId: String
  },
  data () {
    return {
      loading: false,
      curIndex:0,
      queryMonth:'',
      classOption: {
        step: 0.8, // 数值越大速度滚动越快
        limitMoveNum: this.listData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 38, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000
      },
    }
  },
  created() {
    this.curIndex = 0
    this.queryMonth = this.recentlyYearArr[0].value
    // this.httpVisitListByArea()
  },
  methods: {
    handleCommand(command) {
      this.curIndex = command
      this.queryMonth = this.recentlyYearArr[command].value
      this.httpVisitListByArea()
    },

    onClickItemListener(item){
      this.$emit('onClickItem', item);
    },


    // 探视走访按照区域统计列表
    httpVisitListByArea(){
      let userType = parseInt(this.curSelectUserType)
      switch (userType){
        case 11:
          this.httpVisitListByCity()
          break
        case 12:
          this.httpVisitListByDistrict()
          break
        case 4:
          this.httpVisitListByTown()
          break
        case 5:
          this.httpVisitListByVillage()
          break
        case 6:
          break
      }
    },

    // 探视走访-按城市分组统计列表
    httpVisitListByCity(){
      this.loading = true
      request.httpPost('/v1/business/visit/statistics/list/by/city', {
        provinceId:this.provinceId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
    // 探视走访-按区县分组统计列表
    httpVisitListByDistrict(){
      this.loading = true
      request.httpPost('/v1/business/visit/statistics/list/by/district', {
        cityId:this.cityId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 探视走访-按城镇分组统计列表
    httpVisitListByTown(){
      this.loading = true
      request.httpPost('/v1/business/visit/statistics/list/by/town', {
        districtId:this.districtId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },




    // 探视走访-按城镇分组统计列表
    httpVisitListByGroup(){
      this.loading = true
      request.httpPost('/v1/business/visit/statistics/list/by/group', {
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 探视走访-按乡村分组统计列表
    httpVisitListByVillage(){
      this.loading = true
      request.httpPost('/v1/business/visit/statistics/list/by/village', {
        groupId: this.groupId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
  }
}
</script>

<style >
.warp {
  height: 21vh;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li {
      height: 36px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 0 10px;
      background-color: #243978;
      margin-top: 2px;
    }
  }
}
@media (min-height: 1560px) {
  .warp {
    height: 192px;
  }
}

.content {
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  background: rgba(36, 57, 120, 0.5);
  margin-top: 2px;
}

.content .txt {
  color: #57C7ED;
  text-align: center;
  font-size: 14px;
  flex: 1;
}
.content .rank{
  font-weight: bold;
}
.content .head-txt{
  color: #247CE1;
  font-weight: bold;
}
</style>
