<template>
  <div class="num-box">
    <img class="icon" :src="icon"/>
    <div class="num-ver">
      <div class="num">{{ num?num:'0' }}<span class="unit">{{unit}}</span></div>
      <span class="title-txt">{{ title }}</span>
    </div>

  </div>
</template>

<script>

export default {
  name: 'NumViewVideo',
  props: {
    title: String,
    num: Number,
    icon: Object,
    unit:{
      type:String,
      default:'个'
    }
  },
  data() {
    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.num-box {
  flex: 1;
  display: flex;
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url("../images/child/bg_num_view.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.num-box .icon {
  object-fit: contain;
  width: 82px;
  height: 82px;
  margin-right: -10px;
}

.num-ver {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.num {
  font-size: 20px;
  font-weight: 800;
  color: #01C4F9;
}
.unit{
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
  margin-left: 2px;
}

.title-txt {
  font-size: 12px;
  font-weight: bold;
  color: rgba(255,255,255,0.6);
  margin-bottom: 8px;
}

.color1 {
 color: #479FFE;
}

.color2 {
  color: #90FFAD;
}

.color3 {
  color: #FB2677;
}

.color4 {
  color: #E5B55B;
}

.color5 {
  color: #479FFE;
}


.color-gradient1 {
  background: linear-gradient(90deg, rgba(77, 105, 157, 0.7) 0%, rgba(127, 192, 243, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient2 {
  background: linear-gradient(90deg, rgba(107, 231, 212, 0.7) 0%, rgba(48, 161, 163, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient3 {
  background: linear-gradient(90deg, rgba(252, 0, 53, 0.7) 0%, rgba(252, 0, 95, 1) 40%, rgba(255, 219, 233, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient4 {
  background: linear-gradient(90deg, rgba(226, 171, 94, 0.7) 0%, rgba(253, 252, 147, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient5 {
  background: linear-gradient(90deg, rgba(77, 105, 157, 0.7) 0%, rgba(127, 192, 243, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


</style>
