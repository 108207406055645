import { render, staticRenderFns } from "./DialogPlayCenter.vue?vue&type=template&id=a8d827ae&scoped=true"
import script from "./DialogPlayCenter.vue?vue&type=script&lang=js"
export * from "./DialogPlayCenter.vue?vue&type=script&lang=js"
import style0 from "./DialogPlayCenter.vue?vue&type=style&index=0&id=a8d827ae&prod&scoped=true&lang=stylus"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8d827ae",
  null
  
)

export default component.exports