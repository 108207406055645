<template>
<!--  <div class="count-flop" :key="compKey"> 所有数字都转动-->
  <div class="count-flop">
    <div class="label-txt">{{label}}：</div>
    <div :class="item!='.'?'count-flop-box':'count-flop-point'" v-for="(item, index) in value" :key="index">
      <div v-if="item!='.' && item!='-'" class="count-flop-content" :class="['rolling_' + item]">
        <div v-for="(item2,index2) in numberList" :key="index2" class="count-flop-num">{{item2}}</div>
      </div>
      <div v-else-if="item=='-'" class="count-flop-content">-</div>
      <div v-else class="count-flop-content">.</div>
    </div>
    <div v-if="suffix" class="count-flop-unit">{{suffix}}</div>
    <div class="label-txt">&nbsp;元&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
  </div>
</template>
<script>
export default {
  name: 'countFlop',
  data () {
    return {
      value: [],
      numberList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      compKey: 0
    };
  },
  props: ["val", "suffix","label"],
  watch: {
    val () {
      this.value = this.val?this.val.toFixed(1).split(""):"0.0";
      // console.log(this.value);
      this.compKey += 1;
    }
  },
  mounted () {
    this.value = this.val?this.val.toFixed(1).split(""):"0.0";
    // console.log(this.value);
  },
};
</script>
<style scoped>
.count-flop {
  display: inline-block;
  font-size: 0;
  /* 可更改 */
  height: 2.8vw;
  line-height: 2.8vw;
  font-size: 2.0em;
  color: #4898f1;
  font-weight: bold;
  text-align: center;
  color: aqua;
  padding: 14px;
  box-sizing: content-box;
}

@media (max-width: 1600px) {
  .count-flop {
    /* 可更改 */
    height: 3.8vw;
    line-height: 3.8vw;
  }
}

@media (max-width: 1400px) {
  .count-flop {
    height: 4.8vw;
    line-height: 4.8vw;
  }
}

@media (max-width: 980px) {
  .count-flop {
      /* 可更改 */
    height: 6.0vw;
    line-height: 6.0vw;
  }
}

.label-txt{
  //font-size: 1.4rem;
  font-size: 30px;
  color: white;
}

.count-flop>div {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height: 100%;
}

.count-flop-box {
  /* 可更改 */
  width: 2.8vh;
  border: 1px solid rgba(72, 152, 241, 0.3);
  box-shadow: inset 0 0 0.6rem #69b1ff;
  border: 2px solid #66BEF4;
  margin: 0.5vw;
}

.count-flop-point {
  /* 可更改 */
  margin-right: 5px;
  width: 10px;
}

.count-flop-content {
  font-family: MicrosoftYaHei-Bold;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  animation-fill-mode: forwards !important;
}

.rolling_0 {
  animation: rolling_0 2.1s ease;
}

@keyframes rolling_0 {
  from {
    transform: translateY(-90%);
  }

  to {
    transform: translateY(0);
  }
}

.rolling_1 {
  animation: rolling_1 3s ease;
}

@keyframes rolling_1 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10%);
  }
}

.rolling_2 {
  animation: rolling_2 2.1s ease;
}

@keyframes rolling_2 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-20%);
  }
}

.rolling_3 {
  animation: rolling_3 3s ease;
}

@keyframes rolling_3 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-30%);
  }
}

.rolling_4 {
  animation: rolling_4 2.1s ease;
}

@keyframes rolling_4 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-40%);
  }
}

.rolling_5 {
  animation: rolling_5 3s ease;
}

@keyframes rolling_5 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-50%);
  }
}

.rolling_6 {
  animation: rolling_6 2.1s ease;
}

@keyframes rolling_6 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-60%);
  }
}

.rolling_7 {
  animation: rolling_7 3.1s ease;
}

@keyframes rolling_7 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-70%);
  }
}

.rolling_8 {
  animation: rolling_8 2.1s ease;
}

@keyframes rolling_8 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-80%);
  }
}

.rolling_9 {
  animation: rolling_9 3.6s ease;
}

@keyframes rolling_9 {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-90%);
  }
}
</style>

