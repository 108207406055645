import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import Register from '../views/Register'
import VipCenter from '../views/VipCenter'
import FindFate from '../views/FindFate'
import UserDetail from '../views/UserDetail'
import MsgCenter from '../views/MsgCenter'
import MsgSend from '../views/MsgSend'
import MsgReply from '../views/MsgReply'
import EditBaseInfo from '../views/mine/EditBaseInfo'
import EditDetailInfo from '../views/mine/EditDetailInfo'
import Activity from '../views/Activity'
import ActivityDetail from '../views/ActivityDetail'
import ModifyPassword from '../views/mine/ModifyPassword'
import MyLove from '../views/mine/MyLove'
import LoveMe from '../views/mine/LoveMe'
import Article from '../views/Article'
import VerificationCode from '../views/VerificationCode'
import ForgetPassword from '../views/mine/ForgetPassword'
import MonitorCenter1 from '../views/MonitorCenter1.vue'
import Login from '../views/Login.vue'
import MonitorCenter from '../views/MonitorCenter.vue'
import MonitorCenterVertical from "@/views/MonitorCenterVertical.vue";
import MonitorInstitutionVideo from "@/views/MonitorInstitutionVideo.vue";
import MonitorChildVisit from "@/views/MonitorChildVisit.vue";

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: MonitorChildVisit,
  //   meta: {
  //     // 页面标题title
  //     title: '监控中心',
  //     content: {
  //       keywords: '数字养老，监控中心',
  //       description: '数字养老，监控中心'
  //     }
  //   }
  // },
  {
    path: '/',
    name: 'MonitorCenter',
    component: MonitorCenter,
    meta: {
      // 页面标题title
      title: '监控中心',
      content: {
        keywords: '数字养老，监控中心',
        description: '数字养老，监控中心'
      }
    }
  },
  {
    path: '/vertical',
    name: 'MonitorCenterVertical',
    component: MonitorCenterVertical,
    meta: {
      // 页面标题title
      title: '监控中心',
      content: {
        keywords: '数字养老，监控中心',
        description: '数字养老，监控中心'
      }
    }
  },
  {
    path: '/institution/video',
    name: 'MonitorInstitutionVideo',
    component: MonitorInstitutionVideo,
    meta: {
      // 页面标题title
      title: '机构视频监控中心',
      content: {
        keywords: '数字养老,监控中心,视频监控',
        description: '数字养老,监控中心'
      }
    }
  },
  {
    path: '/visit/child',
    name: 'MonitorChildVisit',
    component: MonitorChildVisit,
    meta: {
      // 页面标题title
      title: '儿童随访监控监控中心',
      content: {
        keywords: '儿童随访,监控中心,视频监控',
        description: '儿童随访,监控中心'
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      // 页面标题title
      title: '登录-监控中心',
      content: {
        keywords: '数字养老，监控中心',
        description: '数字养老，监控中心'
      }
    }
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    meta: {
      // 页面标题title
      title: '风筝缘-遇到你的美好',
      content: {
        keywords: '风筝缘,风筝缘相亲,相亲,交友,相亲交友,找对象,恋爱,结婚',
        description: '风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。在风筝缘遇到你的美好，开启真爱趁现在。'
      }
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      // 页面标题title
      title: '用户注册-风筝缘',
      content: {
        keywords: '风筝缘,风筝缘注册',
        description: '风筝缘注册,为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/mine/base_info',
    name: 'EditBaseInfo',
    component: EditBaseInfo,
    meta: {
      // 页面标题title
      title: '编辑用户信息-风筝缘',
      content: {
        keywords: '风筝缘,编辑用户信息',
        description: '编辑用户信息,为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/mine/detail_info',
    name: 'EditDetailInfo',
    component: EditDetailInfo,
    meta: {
      // 页面标题title
      title: '编辑用户信息-风筝缘',
      content: {
        keywords: '风筝缘,编辑用户信息',
        description: '编辑用户信息,为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/mine/modfiy_pwd',
    name: 'ModifyPassword',
    component: ModifyPassword,
    meta: {
      // 页面标题title
      title: '修改密码-风筝缘',
      content: {
        keywords: '风筝缘,修改密码',
        description: '修改密码,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/find_fate',
    name: 'FindFate',
    component: FindFate,
    meta: {
      // 页面标题title
      title: '找缘分-风筝缘',
      content: {
        keywords: '找缘分,风筝缘',
        description: '找缘分,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/user_detail/:id',
    name: 'UserDetail',
    component: UserDetail,
    meta: {
      // 页面标题title
      title: '用户详情-风筝缘',
      content: {
        keywords: '用户详情,风筝缘',
        description: '用户详情,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/msg',
    name: 'MsgCenter',
    component: MsgCenter,
    meta: {
      // 页面标题title
      title: '消息中心-风筝缘',
      content: {
        keywords: '消息中心,风筝缘',
        description: '消息中心,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  }, {
    path: '/msg_send/:id',
    name: 'MsgSend',
    component: MsgSend,
    meta: {
      title: '发消息-风筝缘',
      content: {
        keywords: '发消息,风筝缘',
        description: '发消息,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/msg_reply/:id',
    name: 'MsgReply',
    component: MsgReply,
    meta: {
      title: '回复消息-风筝缘',
      content: {
        keywords: '回复消息,风筝缘',
        description: '回复消息,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },
  {
    path: '/vip',
    name: 'VipCenter',
    component: VipCenter,
    meta: {
      // 页面标题title
      title: '会员中心-风筝缘',
      content: {
        keywords: '会员中心,风筝缘',
        description: '会员中心,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  }, {
    path: '/activity',
    name: 'Activity',
    component: Activity,
    meta: {
      // 页面标题title
      title: '活动中心-风筝缘',
      content: {
        keywords: '风筝缘活动中心,风筝缘',
        description: '活动中心,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  }, {
    path: '/activity_detail',
    name: 'ActivityDetail',
    component: ActivityDetail,
    meta: {
      // 页面标题title
      title: '活动详情-风筝缘',
      content: {
        keywords: '风筝缘活动详情',
        description: '活动中心,风筝缘为同城单身男女创造一个真实可靠的征婚交友相亲平台。'
      }
    }
  },{
    path: '/user/my_love',
    name: 'MyLove',
    component: MyLove,
    meta: {
      title: '我喜欢的-风筝缘'
    }
  },{
    path: '/user/love_me',
    name: 'LoveMe',
    component: LoveMe,
    meta: {
      title: '喜欢我的-风筝缘'
    }
  },{
    path: '/article/:id',
    name: 'Article',
    component: Article,
    meta: {
      title: '协议-风筝缘'
    }
  },{
    path: '/verification',
    name: 'VerificationCode',
    component: VerificationCode,
    meta: {
      title: '验证码-风筝缘'
    }
  },{
    path: '/password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      title: '找回密码-风筝缘'
    }
  }
]

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})

export default router
