<template>
  <div class="h4-box">
    <img  class="bg-h4" src="../images/bg_h4.png" />
    <div class="title-txt">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'H4View',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.h4-box{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 10px;
}
.h4-box .bg-h4{
  width: 8px;
  height: 126px;
  margin-top: 6px;
}
.h4-box .title-txt{
  width: 18px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 1.2em;
  margin-left: 6px;
  z-index: 1;
}
</style>
