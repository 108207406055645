<template>
  <div class="container">
    <el-card class="box-card" style="width: 1000px">
      <div class="ih4">
        <div class="tag-line"></div>
        <h3>填写真实信息</h3>
        <h4>（完整填写将为你推荐更适合的异性）</h4>
      </div>

      <el-form status-icon :rules="rules" ref="form" :model="form" label-width="100px" label-position="left">

        <el-form-item label="我是一位">
          <el-radio-group v-model="form.gender">
            <el-radio label="1">男士</el-radio>
            <el-radio label="2">女士</el-radio>
          </el-radio-group>

        </el-form-item>

        <el-form-item label="我的生日" prop="birthday">
          <el-date-picker class="item-width" type="date" value-format="yyyy-MM-dd" placeholder="请选择出生日期"
                          default-value="1995-01-01" v-model="form.birthday"
                          :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>


        <el-form-item label="婚姻状态">
          <el-radio-group v-model="form.marital_status">
            <el-radio label="1">未婚</el-radio>
            <el-radio label="2">离异有小孩</el-radio>
            <el-radio label="3">离异无小孩</el-radio>
          </el-radio-group>
        </el-form-item>


        <el-form-item label="我的身高">
          <el-slider style="width: 500px;margin-bottom: 10px" v-model="form.height" :format-tooltip="formatTooltip"
                     :min="120" :max="230" :marks="marks"></el-slider>
        </el-form-item>

        <el-form-item label="我的常住地">
          <el-cascader
            class="item-width"
            :options="options"
            v-model="selectedOptions"
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="我的学历">
          <el-select class="item-width" v-model="form.education" placeholder="请选择你的学历" size="medium">
            <el-option label="高中以下" value="1"></el-option>
            <el-option label="高中/中专" value="2"></el-option>
            <el-option label="本科/大专" value="3"></el-option>
            <el-option label="硕士" value="4"></el-option>
            <el-option label="博士/博士后" value="5"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="我的月收入">
          <el-select class="item-width" v-model="form.monthly_income" placeholder="请选择月收入">
            <el-option label="5千以下" value="1"></el-option>
            <el-option label="5千到1万" value="2"></el-option>
            <el-option label="1万到2万" value="3"></el-option>
            <el-option label="2万到3万" value="4"></el-option>
            <el-option label="3万以上" value="5"></el-option>
          </el-select>
        </el-form-item>

        <div class="ih4">
          <div class="tag-line"></div>
          <h3>注册账号</h3>
        </div>
        <el-form-item label="我的手机" prop="phone">
          <el-input class="item-width" type="tel" v-model="form.phone" placeholder="请输入手机号"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="创建密码" prop="password">
          <el-input class="item-width" type="password" v-model="form.password" placeholder="请输入密码，长度8-20位"
                    autocomplete="off"></el-input>
        </el-form-item>
        <!--        <el-form-item label="确认密码" prop="checkPass">-->
        <!--          <el-input class="item-width" type="password" v-model="form.checkPass" placeholder="请再次输入密码"-->
        <!--                    autocomplete="off"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="手机验证码" prop="captcha">
          <el-input class="item-width"  placeholder="请输入手机验证码" v-model="form.captcha">
            <template slot="append">
              <!--  -->
              <el-button class="btn-captcha" v-if="form.showloginCode" type="primary"
                         @click="getloginPhoneCode('form')">{{ this.form.isRetry ? '重新发送' : '获取验证码' }}
              </el-button>
              <div class="btn-captcha-txt" v-else>{{ form.count }}s</div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="form.isRuleChecked" name="type">

          </el-checkbox>
          已阅读和同意风筝缘的
          <el-link type="primary" href="/article/19" target="_blank">《服务协议》</el-link>
          和
          <el-link type="primary" href="/article/16" target="_blank" >《个人信息保护政策》</el-link>
          并同意将本人提供之信息<span>由风筝缘提供线上/线下服务使用</span>
        </el-form-item>
        <el-form-item>
          <el-button class="item-width" size="medium" type="primary" @click="onSubmit('form')">免费注册</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>


<script>

import { regionData, CodeToText } from 'element-china-area-data'
import request from '../http/request'

export default {
  data () {

    var validatePhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('手机号格式不正确'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号格式不正确'))
          }
        }
      }, 100)
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length < 8 || value.length > 20) {
          callback(new Error('请输入8-20位密码'))
          return
        }
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        gender: '1',
        birthday: '',
        marital_status: '',
        height: 170,
        education: '',
        monthly_income: '',
        phone: '',
        password: '',
        checkPass: '',
        isRuleChecked: false,
        captcha: '', //验证码
        captcha_key:'', // 验证码对应的key
        timer: null, //定时器的开关
        showloginCode: true, //获取成功验证码后倒计时跟获取不成功后没有倒计时按钮的开关
        count: '', //倒计时
        isRetry: false,
      },
      marks: {
        130: '130cm',
        170: '170cm',
        220: '220cm'
      },
      options: regionData,
      selectedOptions: [],
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            validator: validatePhone,
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码',
            trigger: 'blur'
          },
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ],
        checkPass: [
          {
            validator: validatePass2,
            trigger: 'blur'
          }
        ],
        // birthday: [
        //   { type: 'date', message: '请选择出生日期', trigger: 'change' }
        // ]
        captcha: [{
          required: true,
          message: '请输入手机验证码',
          trigger: 'blur'
        },
          { min: 4, max: 6, message: '请输入正确的验证码', trigger: 'blur' }
        ],
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now() - 8.64e6
        }
      },

    }
  },
  methods: {
    formatTooltip (val) {
      if (val == 120) {
        return '120cm以下'
      } else if (val == 230) {
        return '230cm以上'
      }
      return val + 'cm'
    },
    getloginPhoneCode (formName) {

      // 如果没有输入手机号，则不往下执行
      // if (this.form.phone == '') {
      //   this.$message({
      //     message: '请先输入正确的手机号！',
      //     type: 'error',
      //     offset: 80
      //   })
      //   return false
      // }
      // let  data = {
      //   username: This.registerForm.username,
      //   captcha: This.registerForm.captcha
      // };
      this.$refs[formName].validateField('phone', (phoneValid) => {
        if (!phoneValid) {
          this.$refs[formName].validateField('password', (passwordValid) => {
            if (!passwordValid) {

              let phone = this.form.phone
              request.httpPost('/api/verification_code', { 'phone': phone }, success => {
                this.$message({
                  message: '验证码发送成功',
                  type: 'success',
                  offset: 80
                })
                this.form.captcha_key=success.key
                // 当验证码发送成功，开始60秒倒计时
                const time = 60
                if (!this.form.timer) {
                  this.form.showloginCode = false
                  this.form.count = time
                  this.form.timer = setInterval(() => {
                    if (this.form.count > 0 && this.form.count <= time) {
                      this.form.count -= 1
                    } else {
                      this.form.showloginCode = true
                      clearInterval(this.form.timer)
                      this.form.timer = null
                      this.form.isRetry = true
                    }
                  }, 1000)
                }
              }, error => {
                this.$message({
                  message: error,
                  type: 'error',
                  offset: 80
                })
                console.log(error)
              })
            }else {
                this.$message({
                  message: '请输入8-20位密码！',
                  type: 'error',
                  offset: 80
                })
            }
          })
        }else {
          this.$message({
            message: '请先输入正确的手机号！',
            type: 'error',
            offset: 80
          })
        }
      })

    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.form.isRuleChecked) {
            this.$message({
              message: '请勾选并同意隐私协议',
              type: 'error',
              offset: 80
            })
            return
          }
          this.httpRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChange () {
      var loc = ''
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]]
      }
      // alert(loc);

    },

    // 若要将“广东省 广州市 番禺区”换算为代号，可以用以下代码：
    // getCityCode(cityText) {
    //   var codeArray = [];
    //   if (cityText != "") {
    //     var cityArray = cityText.trim().split(" ");
    //
    //     if (cityArray.length == 1) {
    //       codeArray.push(TextToCode[cityArray[0]].code);
    //     } else if (cityArray.length == 2) {
    //       codeArray.push(TextToCode[cityArray[0]].code);
    //       codeArray.push(TextToCode[cityArray[0]][cityArray[1]].code);
    //     } else if (cityArray.length == 3) {
    //       codeArray.push(TextToCode[cityArray[0]].code);
    //       codeArray.push(TextToCode[cityArray[0]][cityArray[1]].code);
    //       codeArray.push(
    //         TextToCode[cityArray[0]][cityArray[1]][cityArray[2]].code
    //       );
    //     }
    //   }
    //   return codeArray;
    // },

    httpRegister () {

      var params = {
        gender: this.form.gender,
        birthday: this.form.birthday,
        marital_status: this.form.marital_status ? this.form.marital_status : 0,
        height: this.form.height,
        education: this.form.education ? this.form.education : 0,
        monthly_income: this.form.monthly_income ? this.form.monthly_income : 0,
        phone: this.form.phone,
        password: this.form.password,
        captcha: this.form.captcha,
        captcha_key: this.form.captcha_key
      }

      if (this.selectedOptions.length > 0) {
        params.province = CodeToText[this.selectedOptions[0]]
        params.city = CodeToText[this.selectedOptions[1]]
        if (params.province.lastIndexOf('省') > 0) {
          params.province = params.province.substring(0, params.province.lastIndexOf('省'))
        }
        if (params.province.lastIndexOf('市') > 0) {
          params.province = params.province.substring(0, params.province.lastIndexOf('市'))
        }
        if (params.city.lastIndexOf('市') > 0) {
          params.city = params.city.substring(0, params.city.lastIndexOf('市'))
        }
        params.address = params.province + ' ' + params.city
      }

      if (this.options) {
        request.httpPost('/api/register', params, success => {
          this.$router.go(-1)
          this.$message({
            message: '恭喜你，注册成功',
            type: 'success'
          })
        }, error => {
          this.$message({
            message: error,
            type: 'error',
            offset: 80
          })
          console.log(error)
        })
      }
    }

  }
}
</script>


<style lang="stylus" rel="stylesheet/stylus">
@import "../common/stylus/mixin.styl"

.box-card
  margin-top 20px

.ih4 {
  display flex
  flex-direction row
  align-items center
}

.item-width {
  width 280px !important;
}

.btn-captcha {
  background: #ff4666 !important;
  color white !important;
  width 110px;
}

.btn-captcha:focus, .btn-captcha:hover {
  background: #ff6b85 !important;
  border-color: #ff6b85 !important;
  color: #fff !important;
}

.btn-captcha-txt
  width 90px;
  text-align center


</style>
