import moment from "moment/moment";

function formatData(dataArr) {
  let resultDataArr = [];
  for (let i = 0; i< dataArr.length; i ++){
    let itemArr = []
    let item = dataArr[i]

    itemArr.push(item.shopName)  // 机构
    // 卫生整改：1，卫生差 2，用电危险 3，煤气危险 4 用水安全 10 其他
    itemArr.push(item.problemId==1?'卫生差':item.problemId==2?'用电危险':item.problemId==3?'煤气危险':item.problemId==4?'用水安全':'其他')   // 警告类型  问题类型 设备警告： 0 未知   1，设备警告 2 危险区 3 禁区 4 攀爬   卫生整改：1，卫生差 2，用电危险 3，煤气危险 4 用水安全 10 其他
    // itemArr.push(item.problemId==1?'低':item.problemId==2?'中':item.problemId==3?'高':item.problemId==4?'高':'低')   // 等级
    itemArr.push(item.status==1?'处理中':'已处理')  // 状态

    // itemArr.push(item.deviceName) // 地点
    // itemArr.push(item.officerName +item.officerPhone) // 责任人
    itemArr.push(item.officerName) // 责任人
    itemArr.push(moment(item.warnTime).format('HH:mm MM/DD'))  // 时间
    resultDataArr.push(itemArr)
  }
  return resultDataArr;
}
export default function getData(dataArr) {
  return {
    header: ['机构名称', '整改问题', '状态',  '责任人','时间'],
    // data: [
    //   ['<span style="color:#37a2da;">行1列1</span>', '行1列2', '行1列3'],
    //   ['行2列1', '<span style="color:#32c5e9;">行2列2</span>', '行2列3'],
    //   ['行3列1', '行3列2', '<span style="color:#67e0e3;">行3列3</span>'],
    //   ['行4列1', '<span style="color:#9fe6b8;">行4列2</span>', '行4列3'],
    //   ['<span style="color:#ffdb5c;">行5列1</span>', '行5列2', '行5列3'],
    //   ['行6列1', '<span style="color:#ff9f7f;">行6列2</span>', '行6列3'],
    //   ['行7列1', '行7列2', '<span style="color:#fb7293;">行7列3</span>'],
    //   ['行8列1', '<span style="color:#e062ae;">行8列2</span>', '行8列3'],
    //   ['<span style="color:#e690d1;">行9列1</span>', '行9列2', '行9列3'],
    //   ['行10列1', '<span style="color:#e7bcf3;">行10列2</span>', '行10列3']
    // ],
    data: formatData(dataArr),
    index: false,
    // headerBGC:'linear-gradient(92deg, #0A208A, #01CCFF)',
    oddRowBGC:'rgba(89, 83, 210, 0.12)',
    evenRowBGC:'rgba(89, 83, 210, 0)',
    columnWidth: [200, 200,200,200,200],
    align: ['center','center','center','center','center']
  }
}
