<template>
  <div class="num-box">
    <img class="icon" :src="iconArr[index-1]"/>
    <!--    <img  class="icon" src="../images/institution/icon_instituion.png"/>-->
    <div class="num-ver">
      <span class="title-txt"  :class="'color-gradient'+index">{{ title }}</span>
      <span class="num"  :class="'color'+index">{{ num?num:'0' }}</span>
    </div>

  </div>
</template>

<script>
import icon1 from '../images/institution/icon_instituion.png'
import icon2 from '../images/institution/icon_device_online.png'
import icon3 from '../images/institution/icon_device_warm.png'
import icon4 from '../images/institution/icon_device_unline.png'
import icon5 from '../images/institution/icon_hygiene.png'

export default {
  name: 'NumViewVideo',
  props: {
    title: String,
    num: Number,
    index: String
  },
  data() {
    return {
      iconArr: [
        icon1,
        icon2,
        icon3,
        icon4,
        icon5
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.num-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.num-box .icon {
  object-fit: contain;
  width: 86px;
  height: 86px;
}

.num-ver {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: -10px;
}

.num {
  font-size: 34px;
  font-weight: bold;
  color: #90FFAD;
  margin-top: 0px;
}

.title-txt {
  font-size: 20px;
  font-weight: bold;
}

.color1 {
 color: #479FFE;
}

.color2 {
  color: #90FFAD;
}

.color3 {
  color: #FB2677;
}

.color4 {
  color: #E5B55B;
}

.color5 {
  color: #479FFE;
}


.color-gradient1 {
  background: linear-gradient(90deg, rgba(77, 105, 157, 0.7) 0%, rgba(127, 192, 243, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient2 {
  background: linear-gradient(90deg, rgba(107, 231, 212, 0.7) 0%, rgba(48, 161, 163, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient3 {
  background: linear-gradient(90deg, rgba(252, 0, 53, 0.7) 0%, rgba(252, 0, 95, 1) 40%, rgba(255, 219, 233, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient4 {
  background: linear-gradient(90deg, rgba(226, 171, 94, 0.7) 0%, rgba(253, 252, 147, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gradient5 {
  background: linear-gradient(90deg, rgba(77, 105, 157, 0.7) 0%, rgba(127, 192, 243, 1) 40%, rgba(215, 237, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


</style>
