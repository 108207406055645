<template>
  <div class="monitor-video-container" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">

    <img class="bg-img" src="../images/institution/bg_institution_video.jpg"/>
    <div class="full-icon">
      <!--      <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click"/>-->
      <!--      <i :class="isFullscreen?'el-icon-top-left':'el-icon-full-screen'" style="color: white"-->
      <!--         ></i>-->
      <img v-if="isFullscreen" src="../images/icon_compress.png" width="20" height="20" @click="clickScreenFull">
      <img v-else src="../images/icon_expend.png" width="20" height="20" @click="clickScreenFull">
    </div>

    <div class="video-header-box">
      <img class="bg-header" src="../images/institution/bg_header.png"/>
      <div class="logo-txt" @click="onClickSwitchMonitor">木子信息科技</div>
<!--      <el-popover
        ref="popover4"
        placement="bottom"
        width="600"
        popper-class="pop-select-region-container"
        trigger="click"
        style="z-index: 99">
        <div class="pop-select-region-content" style="overflow: hidden;">
          <el-input
            placeholder="输入关键字"
            v-model="filterGroupText">
          </el-input>
          <el-scrollbar style="height: 500px;margin-top: 10px">
            <el-tree
              class="filter-tree"
              ref="tree"
              node-key="id"
              :data="groupData"
              :props="defaultProps"
              :check-strictly="true"
              show-checkbox
              default-expand-all
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              :check-on-click-node="true"
              @check-change="handleCheckChange"
            >
            </el-tree>

          </el-scrollbar>

        </div>
        &lt;!&ndash;        <el-button slot="reference" > click 激活</el-button>&ndash;&gt;
        <div class="title-txt" slot="reference">{{ curGroupName }} <i class="el-icon-caret-bottom"
                                                                      style="color: white; font-size: 20px"></i></div>
      </el-popover>-->

      <div class="title-txt">{{ curGroupName }}</div>

      <div class="btn-exit" @click="onClickLogout">
        <img class="icon-exit" src="../images/icon_exit_green.png" />
        <span>退出</span>
      </div>

    </div>
    <div class="video-content-box">
      <div class="video-left-box">
        <chart-com-view class="my-chart-box" title="人流量统计" :isHeaderPlaceHolder="false">
          <v-chart
            :option="lineTraffic"
            :init-options="initOptions"
            ref="lineTraffic"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 100%;width: 100%"
          />
        </chart-com-view>
        <chart-com-view class="my-chart-box" title="设备报警统计" :isHeaderPlaceHolder="false">
          <v-chart
            :option="barLineDeviceWarm"
            :init-options="initOptions"
            ref="barLineDeviceWarm"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
          />
        </chart-com-view>
        <chart-com-view class="my-chart-box" title="值班率统计趋势图" :isHeaderPlaceHolder="false">
          <v-chart
            :option="lineDutyRate"
            :init-options="initOptions"
            ref="lineDutyRate"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
            style="height: 100%;width: 100%"
          />
        </chart-com-view>
        <chart-com-view class="my-chart-box" title="食堂卫生整改统计趋势图" :isHeaderPlaceHolder="false">
          <v-chart
            :option="barLineHygieneRectify"
            :init-options="initOptions"
            ref="barLineHygieneRectify"
            theme="ovilia-green"
            autoresize
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            @zr:click="handleZrClick"
            @click="handleClick"
          />
        </chart-com-view>

      </div>
      <div class="video-center-box">
        <div class="center-top-box">
          <div class="empty-ver"></div>
          <num-scroll-view :val="todayTraffic" label="今日实时人流量"></num-scroll-view>
          <div class="empty-ver"></div>
          <div class="num-view-box">
            <num-view title="养老机构" index="1" :num="monthTotalBean.shopNum"></num-view>
            <num-view title="在线" index="2" :num="monthTotalBean.onLineNum"></num-view>
            <num-view title="离线" index="4" :num="monthTotalBean.offLineNum"></num-view>
            <num-view title="报警数" index="3" :num="monthTotalBean.warnNum"></num-view>
            <num-view title="卫生整改数" index="5" :num="monthTotalBean.hygieneNum"></num-view>
          </div>
          <div class="empty-ver"></div>
        </div>

        <chart-com-view class="my-chart-box" title="摄像头实时监控画面" :is-show-more="true" more-value="查看全部摄像头" @onClickMore="onClickMoreDevice">
          <tab-bar class="video-tab-bar" :data-arr="videoTypeArr" @onClickItemListener="onVideoTabChangeListener"/>
          <real-time-monitor-screen v-loading="isActiveDeviceSectionLoading"
                                    element-loading-text="加载中..."
                                    element-loading-background="rgba(0, 0, 0, 0.5)" :data-arr="activeDeviceList" @onClickVideoPlay="onClickVideoPlay"/>
        </chart-com-view>
        <div class="capture-box">
          <chart-com-view title="实时人脸抓拍照片" style="width: 50%">
            <capture-photo-view :data-arr="captureFaceList" @onClickVideoPlay="onClickVideoPlayBack"/>
          </chart-com-view>
          <chart-com-view title="实时卫生整改照片" style="border-left: none; width: 50%">
            <capture-photo-view :data-arr="captureHygieneList" @onClickVideoPlay="onClickVideoPlayBack"/>
          </chart-com-view>
        </div>
        <div class="empty-ver"></div>
      </div>
      <div class="video-right-box">
        <div class="h2">全县服务机构列表</div>
        <chart-com-view class="my-chart-box" :title="('当前选择数据：' + curGroupName)">
          <dv-scroll-board :config="shopStatisticsListConfig" style="width:100%;height:38vh"/>
        </chart-com-view>

        <chart-com-view class="my-chart-box" title="实时警告">
          <dv-scroll-board :config="warnAlarmConfig" style="width:100%;height:18vh"/>
        </chart-com-view>

        <chart-com-view class="my-chart-box" title="最新卫生整改统计">
          <dv-scroll-board :config="warnHygieneConfig" style="width:100%;height:18vh"/>
        </chart-com-view>

      </div>
    </div>
    <!--    视频播放-->
    <dialog-video-live :show="isShowVideoLive" :is-live="isCurLive" :device-id="curDeviceId" :device-data="curDevice" :capture-data="curCapture" @onClose="onCloseVideoLiveDialog"></dialog-video-live>
    <dialog-play-center :show="isShowAllShop" :shop-list="shopStatisticsList" :category-list="videoTypeArr" @onClose="onClosePlayCenterDialog" @onClickVideoPlay="onClickVideoCenterPlay"></dialog-play-center>

    <dialog-select-monitor :show="selectMonitorShow"  @onClose="selectMonitorShow=false"></dialog-select-monitor>
  </div>

</template>

<script type="text/ecmascript-6">
/* eslint-disable no-console */
import qs from "qs";
// import VChart from "../ECharts";
import {registerMap, registerTheme, use} from "echarts/core";
import {
  BarChart,
  EffectScatterChart,
  LineChart,
  LinesChart,
  MapChart,
  PieChart,
  RadarChart,
  ScatterChart
} from "echarts/charts";
import {
  DatasetComponent,
  DataZoomComponent,
  GeoComponent,
  GridComponent,
  LegendComponent,
  PolarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent
} from "echarts/components";

import {CanvasRenderer, SVGRenderer} from "echarts/renderers";
import "echarts-liquidfill";
// import logo from "./data/logo";
import getBar from "./data/bar";
import getPie from "./data/pie";
import getPieUserAlone from "./data/pie-user-alone";
import polar from "./data/polar";
import scatter from "./data/scatter";
import map from "./data/map";
import {c1, c2} from "./data/connect";
import getTabBarData from "./data/tab-bar-data";


import getLineTraffic from "./data/line_traffic"
import getLineDutyRate from "./data/line_duty_rate"

import getGauge from "./data/gauge";
import getBarLineDeviceWarm from "./data/bar_line_device_warm";
import getBarLineHygieneRectify from "./data/bar_line_hygiene_rectify";


import getBarQuestionType from "./data/bar-question-type";
import getBarPieActionAbility from "./data/bar-pie-action-ability";
import getBarLineMealMonth from "./data/bar_line_meal_month";
import getPieMealUserLevel from "./data/pie-meal-user-level";
import getBarMealUserType from "./data/bar_meal_user_type";
import scrollSortConfig from "./data/scroll-sort";
import getWarnAlarmConfig from "./data/scroll-warn-alarm"
import getWarnHygieneConfig from "./data/scroll-warn-hygiene"
import getShopStatisticsListConfig from "./data/scroll-shop-statistics"


import NumView from "../components/NumViewVideo.vue";
import NumScrollView from "../components/NumScrollView2.vue";

import ChartComView from "../components/ChartComView.vue";
import TabBar from "../components/TabBar.vue";
import RealTimeMonitorScreen from "../components/RealTimeMonitorScreen.vue";
import CapturePhotoView from "../components/CapturePhotoView.vue";

import DialogVideoLive from "@/components/DialogVideoLive.vue";


// custom theme
import theme from "./theme.json";

// Map of China
// import chinaMap from "./china.json";
// import worldMap from "./world.json";


import screenfull from 'screenfull'
import constant from '../constant'
import request from '../http/request'
import {getStore, setStore} from '../utils/storage'
import DialogPlayCenter from "@/components/DialogPlayCenter.vue";
import DialogSelectMonitor from "@/components/DialogSelectMonitor.vue";


use([
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent
]);

// registering map data
// registerMap("china", chinaMap);
// registerMap("world", worldMap);

// registering custom theme
registerTheme("ovilia-green", theme);

export default {
  name: "vue-echarts-demo",
  components: {
    DialogSelectMonitor,
    DialogPlayCenter,
    ChartComView, NumView, NumScrollView, TabBar,
    CapturePhotoView, RealTimeMonitorScreen,
    DialogVideoLive
  },


  data() {
    const options = qs.parse(location.search, {ignoreQueryPrefix: true});
    return {
      timer : null,
      loading: false,
      curGroupName: '',
      queryMonth: '',
      recentlyYearArr: [],
      userType: '',
      userId: '',
      provinceId: '',
      cityId: '',
      districtId: '',
      groupId: '',
      villageId: '',
      monitorChildCategoryId: '',     // 监控当前选择的分类 120 大门口 ,暂时 显示全部
      curSelectUserType: -1,
      filterGroupText: '',
      groupData: [],
      videoTypeArr: getTabBarData().videoTypeArr,
      isShowSelectGroupDialog: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      isFullscreen: false,
      options,
      bar: getBar(),
      pie: getPie([], []),
      pieVisitUserAlone: getPieUserAlone([], []),
      lineTraffic: getLineTraffic([], []),
      lineDutyRate: getLineDutyRate([], []),
      barLineDeviceWarm: getBarLineDeviceWarm([], [], [], []),
      barLineHygieneRectify: getBarLineHygieneRectify([], [], [], []),
      gaugeVisitRate: getGauge("本月探视率", 0, "#00D1EE"),
      gaugeVisitCompleteRate: getGauge("本月完成率", 0, "#FC9600"),
      barVisitQuestionType: getBarQuestionType([], [], [], [], [], []),
      barVisitUserActionAbility: getBarPieActionAbility([], [], [], []),
      barLineMealMonth: getBarLineMealMonth([], [], [], []),
      pieMealUserLevel: getPieMealUserLevel([]),
      barMealUserType: getBarMealUserType([]),
      visitGroupListData: [],
      mealGroupListData: [],
      activeDeviceList: [],
      captureFaceList: [],
      captureHygieneList: [],
      warnAlarmConfig: getWarnAlarmConfig([]),
      warnHygieneList: [],
      warnHygieneConfig: getWarnHygieneConfig([]),
      shopStatisticsList: [],
      shopStatisticsListConfig: getShopStatisticsListConfig([]),
      visitTotalBean: {},
      visitAloneUserNum: 0,
      monthTotalBean: [],
      todayTraffic: 0,
      mealCurMonthTotalBean: [],
      scrollSortConfig: scrollSortConfig,
      initOptions: {
        renderer: options.renderer || "canvas"
      },
      polarTheme: "dark",
      seconds: -1,
      asyncCount: false,
      connected: true,
      metricIndex: 0,
      open: false,
      img: {},
      barLoading: false,
      barLoadingOptions: {
        text: "Loading…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0)"
      },
      isCurLive:true,
      curDevice:null,
      curDeviceId:null,
      curDeviceSn:'',
      curChannelNo:1,
      curCapture:null,
      isShowVideoLive: false,
      isShowAllShop: false,    // 全部摄像头弹窗
      selectMonitorShow: false,
      isActiveDeviceSectionLoading:false
    };
  },

  created() {
    this.userId = this.$route.params.id
    this.token = getStore(constant.K_TOKEN)
    if (!this.token) {
      this.$router.push({
        path: '/login'
      })
      return
    }
    this.queryMonth = this.getNowDate()
    this.getRecentlyYear()
    this.provinceId = getStore(constant.K_PROVINCE_ID)
    this.cityId = getStore(constant.K_CITY_ID)
    this.districtId = getStore(constant.K_DISTRICT_ID)
    this.groupId = getStore(constant.K_GROUP_ID)
    this.villageId = getStore(constant.K_VILLAGE_ID)
    this.userType = getStore(constant.K_USER_TYPE)
    this.userId = getStore(constant.K_UID)
    this.curSelectUserType = parseInt(this.userType)

    var times = 0;
    // 设置整点定时发送请求
    this.timer = setInterval(() => {
      // const now = new Date();
      // if (now.getMinutes() === 0 && now.getSeconds() === 0) {
      //   this.httpRefreshCurData();
      // }
      // this.currentTime = now.toLocaleString();
      this.httpRefreshCurData();

      if (times >= 1) {
        this.httpRefresh10sData();
        times = 0;
      } else {
        times++;
      }

    }, 5000);

    this.refreshAllData();
    this.httpRefreshCurData();


    // this.httpGroupList()
    this.httpGetCurGroupDetail()
  },


  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null
    this.destroyScreenFull()
  },
  watch: {
    filterGroupText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    this.init()
  },
  beforeUnmount() {

  },

  methods: {
    onClickSwitchMonitor(){
      this.selectMonitorShow = true
    },
    onClickLogout() {
      // this.centerDialogVisible = false
      this.token = ''
      this.userInfo = {}
      setStore(constant.K_TOKEN, '')
      setStore(constant.K_APP_ID, '')
      setStore(console.K_UID, '')
      clearInterval(this.timer);
      this.timer = null
      this.$router.replace('/login')
    },
    onClickMoreDevice(){
      this.isShowAllShop = true
    },
    onVideoTabChangeListener(item){
        // console.log(item)
        this.monitorChildCategoryId = item.id
        this.isActiveDeviceSectionLoading = true
        this.httpActiveDeviceList()
    },
    onClickVideoPlay(item) {
      this.isCurLive = true
      this.curDevice = item
      this.curDeviceId = item.id
      if (item){
        this.curCapture = item.captureRecord
      }else {
        this.curCapture = null
      }
      this.isShowVideoLive = true
    },
    onClickVideoCenterPlay(item) {
      this.isCurLive = true
      this.curDevice = null
      this.curDeviceId = item.id
      this.curCapture = null
      this.isShowVideoLive = true
    },
    onClickVideoPlayBack(item){
      this.isCurLive = false
      this.curCapture = item
      this.curDevice = null
      this.curDeviceId = item.deviceId
      this.isShowVideoLive = true
    },

    onCloseVideoLiveDialog() {
      this.isShowVideoLive = false
    },
    onClosePlayCenterDialog(){
      this.isShowAllShop = false
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    onClickItemMeal(item) {
      if (this.curSelectUserType != 5) {
        let id = item.id
        let data = this.getSelectedItem(id)
        if (data) {
          this.handleCheckChange(data, true, null)
        }
      } else {
        this.$message.error('不能再往下查看了');
      }

    },
    onClickItemVisit(item) {
      if (this.curSelectUserType != 6) {
        let id = item.id
        let data = this.getSelectedItem(id)
        if (data) {
          this.handleCheckChange(data, true, null)
        }
      } else {
        this.$message.error('不能再往下查看了');
      }
    },

    getSelectedItem(id) {
      for (let itemBean of this.groupData) {
        if (id == itemBean.id) {
          console.log("======1=" + itemBean.id)
          return itemBean
        }
        if (itemBean.children) {
          for (let childItem of itemBean.children) {
            if (id == childItem.id) {
              console.log("======2=" + childItem.id)
              return childItem
            }
            if (childItem.children) {
              for (let child2Item of childItem.children) {
                if (id == child2Item.id) {
                  console.log("======3=" + child2Item.id)
                  return child2Item
                }
                if (child2Item.children) {
                  for (let child3Item of child2Item.children) {
                    if (id == child3Item.id) {
                      console.log("======4=" + child3Item.id)
                      return child3Item
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    handleCheckChange(data, checked, indeterminate) {
      if (checked) {
        this.dirId = data.id
        this.$refs.tree.setCheckedKeys([data.id])
        this.curGroupName = data.label
        this.provinceId = ''
        this.cityId = ''
        this.districtId = ''
        this.groupId = ''
        this.villageId = ''
        switch (data.level) {
          case 0:
            this.curSelectUserType = 11
            this.provinceId = data.id.toString()
            break
          case 1:
            this.curSelectUserType = 12
            this.cityId = data.id.toString()
            break
          case 2:
            this.curSelectUserType = 4
            this.districtId = data.id.toString()
            break
          case 3:
            this.curSelectUserType = 5
            this.groupId = data.id.toString()
            break
          case 4:
            this.curSelectUserType = 6
            this.villageId = data.id.toString()
            break
        }
        this.refreshAllData()
      } else {
        // 如何取消当前选项，就重新选择
        if (this.dirId == data.id) {
          this.dirId = data.id
          this.$refs.tree.setCheckedKeys([data.id])
        }
      }

      this.$refs.popover4.showPopper = false;
    },
    handleClick(...args) {
      console.log("click from echarts", ...args);
    },
    handleZrClick(...args) {
      console.log("click from zrender", ...args);
    },
    /**
     * 查询当天日期
     */
    getNowDate() {
      const timeOne = new Date()
      const year = timeOne.getFullYear()
      let month = timeOne.getMonth() + 1
      let day = timeOne.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      // const NOW_MONTHS_AGO = `${year}-${month}-${day}`
      const NOW_MONTHS_AGO = `${year}-${month}`
      return NOW_MONTHS_AGO
    },

    //  获取近一年的年份
    getRecentlyYear() {
      var dataArr = [];
      var data = new Date();
      var year = data.getFullYear();
      data.setMonth(data.getMonth() + 1, 1)
      for (var i = 0; i < 12; i++) {
        data.setMonth(data.getMonth() - 1);
        var m = data.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        dataArr.push({
          name: data.getFullYear() + "年" + (m) + "月",
          value: data.getFullYear() + "-" + (m)
        })
      }
      this.recentlyYearArr = dataArr
    },


    splitStr(photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },

    clickScreenFull() {
      if (!screenfull.isEnabled) {
        this.$message({message: '你的浏览器不支持全屏', type: 'warning'})
        return false
      }
      screenfull.toggle()
    },
    changeScreen() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.changeScreen)
      }
    },
    destroyScreenFull() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.changeScreen)
      }
    },

    refreshAllData() {
      this.loading = true
      this.httpTrafficByMonth()
      this.httpDeviceWarmByMonth()
      this.httpDutyRateByMonth()
      this.httpHygieneRectifyByMonth()
      this.httpDeviceCategoryList()

      // 获取最新设备列表

      this.httpRefresh10sData();

      this.httpAgencyStatisticsList()

      this.loading = false
    },

    httpRefresh10sData() {

      // 最新活跃设备
      this.httpActiveDeviceList();

      // 时时人脸抓拍照片
      this.httpCaptureFaceList()

      // 时时卫生整改照片
      this.httpCaptureHygienet()

      // 全县机构列表
      this.httpMealListByArea()

      // 时时警告
      this.httpWarnAlarmList()

      // 时时卫生整改
      this.httpWarnHygieneList()
    },

    // 更新探视走访数据

    // 更新助餐数据
    refreshBarLineMealOption(orginData) {

      if (orginData.length != 12) {
        return
      }
      let xData = []
      let chatData = []
      let amountChatData = []
      let subsidyAmountChartData = []

      for (var i = 6; i < 12; i++) {
        var month = orginData[i].mon.substring(5);
        if (month.indexOf("0") == 0) {
          month = month.substring(1)
        }
        xData.push(month + '月')
        chatData.push(orginData[i].nums ? orginData[i].nums : 0)
        amountChatData.push(orginData[i].count ? orginData[i].count : 0)
        subsidyAmountChartData.push(orginData[i].subsidyAmount ? orginData[i].subsidyAmount : 0)
      }

      this.barLineMealMonth = getBarLineMealMonth(xData, chatData, amountChatData, subsidyAmountChartData)
    },
    refreshPieChartMealLevel(orginData) {

      var chatData = []
      var legendData = []
      orginData.forEach((itemBean) => {
        chatData.push({
          name: itemBean.name,
          value: itemBean.num
        })
        legendData.push(itemBean.name)
      });

      this.pieMealUserLevel = getPieMealUserLevel(legendData, chatData)

    },
    refreshBarChartMealType(orginData) {
      var xData = []
      var chatDataOn = []
      var chatDataOff = []
      var totalNum = orginData[0].num
      var colors = [
        '#24A0FF',
        '#00EFE6',
        '#FFE200',
        '#9E41EC',
        '#07a807',
        '#1684FC',
        '#ffd700',
        '#ffa500',
        '#ff0000'
      ]
      for (var i = 1; i < orginData.length; i++) {
        xData.push(orginData[i].name)
        chatDataOn.push({
          value: orginData[i].num,
          itemStyle: {
            color: colors[i]
          }
        })
        chatDataOff.push(totalNum - orginData[i].num)
      }


      this.barMealUserType = getBarMealUserType(xData, chatDataOn)
    },


    // 人流量统计
    httpTrafficByMonth() {
      // 探视走访-最近一年问题走势
      request.httpPost('/v1/business/institution_monitor/statistics/traffic/by_month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId
      }, success => {
        let xData = []
        let chatData = []
        let orginData = success
        if (orginData.length != 6) {
          return
        }
        for (let i = 0; i < 6; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          chatData.push(orginData[i].count ? orginData[i].count : 0)
        }
        this.lineTraffic = getLineTraffic(xData, chatData)
      }, error => {
      })
    },

    // 值班率统计
    httpDutyRateByMonth() {
      // 探视走访-最近一年问题走势
      request.httpPost('/v1/business/institution_monitor/statistics/traffic/by_month', {
        // request.httpPost('/v1/business/institution_monitor/statistics/clock_in/rate/by_month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId
      }, success => {
        let xData = []
        let chatData = []
        let orginData = success
        if (orginData.length != 6) {
          return
        }
        for (let i = 0; i < 6; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          if (i==5){
            chatData.push(98)
          }else {
            chatData.push(orginData[i].count ? orginData[i].count : 0)
          }

        }
        this.lineDutyRate = getLineDutyRate(xData, chatData)
      }, error => {
      })
    },

    // 设备报警统计
    httpDeviceWarmByMonth() {
      // // 设备报警统计-最近一年走势
      request.httpPost('/v1/business/institution_monitor/statistics/warn/alarm/by_month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId
      }, success => {
        let xData = []
        let chatData = []
        let processingData = []
        let completedData = []
        let orginData = success
        if (orginData.length != 6) {
          return
        }
        for (let i = 0; i < 6; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          chatData.push(orginData[i].allNum ? orginData[i].allNum : 0)
          processingData.push(orginData[i].processingNum)
          completedData.push(orginData[i].allNum - orginData[i].processingNum)
        }
        this.barLineDeviceWarm = getBarLineDeviceWarm(xData, chatData, processingData, completedData)
      }, error => {
      })
    },

    // 卫生整改按月统计
    httpHygieneRectifyByMonth() {
      // // 设备报警统计-最近一年走势
      request.httpPost('/v1/business/institution_monitor/statistics/warn/hygiene_rectify/by_month', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId
      }, success => {
        let xData = []
        let chatData = []
        let processingData = []
        let completedData = []
        let orginData = success
        if (orginData.length != 6) {
          return
        }
        for (let i = 0; i < 6; i++) {
          let month = orginData[i].mon.substring(5);
          if (month.indexOf("0") == 0) {
            month = month.substring(1)
          }
          xData.push(month + '月')
          chatData.push(orginData[i].allNum ? orginData[i].allNum : 0)
          processingData.push(orginData[i].processingNum)
          completedData.push(orginData[i].allNum - orginData[i].processingNum)
        }
        this.barLineHygieneRectify = getBarLineHygieneRectify(xData, chatData, processingData, completedData)
      }, error => {
      })
    },

    // 获取摄像头分类
    httpDeviceCategoryList() {
      request.httpPost('/v1/business/device/monitor/category/child', {
        parentId: 101
      }, success => {
        if (success) {
          let deviceNum = 0
          success.forEach(item=>{
            deviceNum = deviceNum + item.deviceNum
          })
          success.unshift({
            id:'',
            name:'全部',
            deviceNum: deviceNum
          })
        }
        this.videoTypeArr = success

      }, error => {
      })
    },

    // 最新活跃的6台摄像头
    httpActiveDeviceList() {

      request.httpPost('/v1/business/institution_monitor/device/active/latest', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId,
        categoryId: 101,
        childCategoryId: this.monitorChildCategoryId
      }, success => {
        this.activeDeviceList = success
        this.isActiveDeviceSectionLoading = false
      }, error => {
        this.isActiveDeviceSectionLoading = false
      })
    },


    // 时时人脸抓拍照片
    httpCaptureFaceList() {
      request.httpPost('/v1/business/institution_monitor/capture/latest/face', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.captureFaceList = success
      }, error => {
      })
    },
    // 时时卫生整改照片
    httpCaptureHygienet() {
      request.httpPost('/v1/business/institution_monitor/capture/latest/hygiene', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.captureHygieneList = success
      }, error => {
      })
    },
    // 全县机构列表
    // this.httpMealListByArea()

    // 时时警告
    httpWarnAlarmList() {
      request.httpPost('/v1/business/institution_monitor/warn/alarm/latest', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.warnAlarmConfig = getWarnAlarmConfig(success)
      }, error => {
      })
    },
    // 时时卫生整改
    httpWarnHygieneList() {
      request.httpPost('/v1/business/institution_monitor/warn/hygiene_rectify/latest', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.warnHygieneList = success
        this.warnHygieneConfig = getWarnHygieneConfig(success)
      }, error => {
      })
    },


    // 获取所有养老院列表
    httpAgencyStatisticsList() {
      request.httpPost('/v1/business/institution_monitor/statistics/shop/list', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        groupId: this.groupId,
        villageId: this.villageId
      }, success => {
        this.shopStatisticsList = success
        this.shopStatisticsListConfig = getShopStatisticsListConfig(success)
      }, error => {
      })
    },


    // 助餐按照区域统计列表
    httpMealListByArea() {
      let userType = parseInt(this.curSelectUserType)
      switch (userType) {
        case 11:
          this.httpMealListByCity()
          break
        case 12:
          this.httpMealListByDistrict()
          break
        case 4:
          this.httpMealListByTown()
          break
        case 5:
          this.httpMealListByShop()
          break
        case 6:
          break
      }
    },


    // 助餐-按城市分组统计列表
    httpMealListByCity() {
      request.httpPost('/v2/business/meal/statistics/list/by/city', {
        provinceId: this.provinceId,
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
    // 助餐-按区县分组统计列表
    httpMealListByDistrict() {
      request.httpPost('/v2/business/meal/statistics/list/by/district', {
        cityId: this.cityId,
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpMealListByTown() {
      request.httpPost('/v2/business/meal/statistics/list/by/town', {
        districtId: this.districtId,
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpMealListByGroup() {
      request.httpPost('/v2/business/meal/statistics/list/by/group', {
        queryMonth: this.queryMonth
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按店铺统计列表
    httpMealListByShop() {
      request.httpPost('/v2/business/meal/statistics/list/by/shop', {
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.mealGroupListData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    httpGroupList(parentGroup) {
      request.httpPost('/v1/common/group/all', {}, success => {

        if (success) {
          var allGroupArr = []
          var groupArr = []

          let name = ""
          let suffix = ""
          if (this.userId == 64921) {
            name = "市县区数字养老监管中心(演示)"
            suffix = "(演示)"
          } else {
            name = parentGroup.name + "数字养老监管中心"
          }

          for (let itemBean of success) {
            var childArr = []
            if (itemBean.child) {
              for (let childItem of itemBean.child) {
                var child2Arr = []
                if (childItem.child) {
                  for (let child2Item of childItem.child) {
                    child2Arr.push({
                      id: child2Item.id,
                      label: child2Item.name + "数字养老监管中心",
                      parentId: child2Item.id,
                      level: child2Item.level
                    })
                  }
                }
                childArr.push({
                  id: childItem.id,
                  label: childItem.name + "数字养老监管中心" + suffix,
                  parentId: itemBean.id,
                  level: itemBean.level,
                  children: child2Arr,
                })
              }
            }

            groupArr.push({
              id: itemBean.id,
              label: itemBean.name + "数字养老监管中心" + suffix,
              parentId: parentGroup.id,
              level: itemBean.level,
              children: childArr,
            })
          }
          allGroupArr.push({
            id: parentGroup.id,
            label: name,
            level: parentGroup.level,
            children: groupArr,
          })


          this.groupData = allGroupArr
        }
      }, error => {
      })
    },

    httpGetCurGroupDetail() {
      let id = this.groupId
      switch (parseInt(this.userType)) {
        case 11:
          id = this.provinceId
          break
        case 12:
          id = this.cityId
          break
        case 4:
          id = this.districtId
          break
        case 5:
          id = this.groupId
          break
        case 6:
          id = this.villageId
          break
      }

      request.httpPost(`/v1/business/group/detail/${id}`, {}, success => {
        if (success) {
          if (this.userId == 64921) {    // 市县区演示账号
            this.curGroupName = "市县区机构养老数字视频监控(演示)"
          } else {
            // this.curGroupName = success.name + "数字养老监管中心"
            this.curGroupName =  "演示数字养老监管中心"
          }

          this.httpGroupList(success)
        }
      }, error => {
      })
    },


    httpRefreshCurData() {

      // 总体统计数据
      request.httpPost('/v1/business/institution_monitor/statistics/traffic', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        // queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.todayTraffic = success.todayTraffic
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })


      // 助餐-本月总体统计数据
      request.httpPost('/v1/business/institution_monitor/statistics/all', {
        provinceId: this.provinceId,
        cityId: this.cityId,
        districtId: this.districtId,
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.monthTotalBean = success
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
      })


    }
  }
}
</script>


<style lang="stylus" rel="stylesheet/stylus">
@import "../common/stylus/mixin.styl"
@import "../styles/monitor-center.css"


*,
*::before,
*::after {
  box-sizing: border-box;
}


::-webkit-scrollbar {
  display: none
}


html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  color: white;
  text-align: center;
}

.monitor-video-container
  width: 100%;
  //height: 100%;
  background-color: #070536 !important;
  display: flex;
  flex-direction: column;
  align-items center
  justify-content center

  .video-header-box
    position: relative;
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .bg-header
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

    .title-txt
      font-size: 24px;
      color: white;
      font-weight: bold;
      background: linear-gradient(0deg, #D7D7D7 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 1;
      padding-right 30px

    .logo-txt
      font-size: 20px;
      color: #00FCF9;
      z-index: 1;
      margin-left: 10px;
      margin-bottom 12px;
      font-weight: bold;
    .btn-exit
      display flex
      flex-direction row
      align-items center
      font-size 16px
      color#00FCF9
      margin-left 30px
      margin-bottom: 24px;
      margin-right 10px
      z-index 10
      .icon-exit
        width: 27px;
        height: 27px;
        padding: 5px;
        margin-left 0px


  .video-content-box
    width: 100vw;
    height 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 1;

    .video-left-box
      width 26vw
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left 16px
      box-sizing border-box

      .my-chart-box
        margin-top 20px
        width: 100%;
        height: 21.8vh;

    .my-chart-box:first-child
      margin-top 8px;

    .video-center-box
      margin 0 18px
      width 48vw
      height 100%
      display flex
      flex-direction column
      box-sizing border-box

      .center-top-box
        width 100%
        height 20vh
        display flex
        flex-direction column
        padding-top 20px
        .empty-ver
          width 1px
          flex 1

        .num-view-box
          display flex
          flex-direction row
          align-items center
          justify-content space-between
          box-sizing border-box
          padding 5px

      .video-tab-bar
        margin-top 20px
        margin-bottom 12px
        margin-left 6px

      .capture-box
        display flex
        flex-direction row
        align-items center
        margin-top 14px

    .video-right-box
      width 26vw
      display: flex;
      flex-direction: column
      align-items: center
      padding-right 16px
      box-sizing border-box

      .h2
        font-size 20px
        color #ffffff
        font-weight bold

      .my-chart-box
        margin-top 20px

      .my-chart-box:first-child
        margin-top 8px;


.dv-scroll-board
  margin-top 10px

  .header
    background: linear-gradient(92deg, #0A208A, #01CCFF)
    opacity: 0.7
    color #03FFDE

  .rows
    .row-item
      font-size 12px


.full-icon {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index 9999
}


a {
  color: inherit;
  text-decoration: none;
}

#logo {
  display: inline-block;
  width: 128px;
  height: 128px;
  pointer-events: none;
}

</style>
