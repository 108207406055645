// import {LinearGradient} from "echarts/graphic";
import * as echarts from 'echarts'


export default function getData(xData, chartData, processingChartData, completedChartData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    // backgroundColor: "#ffffff",
    // title: {
    //   text: '发现问题数量走势曲线',
    //   left: 'left'
    // },
    // legend: {
    //   data: ['A'],
    //   top: 0,
    //   left: 'center',
    //   backgroundColor: 'white',
    //   z: 100
    // },
    legend: {
      top: 10, //调整位置
      right: 2,//调整位置
      icon: "roundRect", // 修改形状
      itemHeight: 13, // 修改icon图形大小
      itemWidth:13,
      itemGap: 12, // 修改间距
      textStyle: {
        color: '#fff',
        fontSize:13
      }
    },
    grid: { //控制边距大小
      left: '50px',
      right: '2%',
      bottom: '32px',
      top: '56px',
      containLabel: false
    },
    toolbox: {
      feature: {
        // saveAsImage: {}
      }
    },
    // tooltip: {
    //   show: true,
    //   trigger: 'axis'
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      name: "时间",
      // boundaryGap: false,
      data: xData,
      splitLine: {
        show: false,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding: 2,
        },
      },
    },
    yAxis: {
      x: 'left',
      type: 'value',
      position: 'left',
      // name: '问题数(个)    ',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.1)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#fff',
          fontSize: 14,
          padding: 2,
        },
      }
      // show: false
    },
    detail: {
      formatter: '{value}个'
    },
    series: [{
      name: '报警数',
      type: 'line',
      showSymbol: true,
      symbolSize: 7,
      symbol: "circle",
      lineStyle: {
        color: 'rgba(54, 240, 178, 0.8)'
      },
      // 折线拐点的样式
      itemStyle: {
        color: "rgba(54, 240, 178, 1)",
        borderColor:  '#36F0B2',
        borderWidth: 1,
        emphasis: { // 鼠标经过时：
          color: '#00f6a2',
        }
      },
      markPoint: {
        data: [
          {type: 'max', name: 'Max'},
          {type: 'min', name: 'Min'}
        ]
      },
      color: '#5581FD',
      data: chartData
    }, {
      name: '已处理',
      type: 'bar',
      barWidth: 15,
      yAxisIndex: 0,
      barGap: '0',
      barCategoryGap: '0',
      data: completedChartData,
      label: {
        normal: {
          show: true,
          position: 'top'
        }
      },

      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [12, 12, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(57, 214, 196, 1)'
          },
            {
              offset: 1,
              color: 'rgba(46, 119, 187, 1)'
            }
          ]),
          label: {
            show: true, //开启显示数值
            // position: 'center', //数值在上方显示
            textStyle: {  //数值样式
              color: '#ffffff',   //字体颜色
              fontSize: 13  //字体大小
            }
          }
        }
      },
    }, {
      name: '处理中',
      type: 'bar',
      barWidth: 15,
      yAxisIndex: 0,
      data: processingChartData,
      label: {
        normal: {
          show: true,
          position: 'top'
        }
      },
      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius: [12, 12, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(255, 162, 0, 1)'
          },
            {
              offset: 1,
              color: 'rgba(250, 213, 9, 1)'
            }
          ]),
          label: {
            show: true, //开启显示数值
            // position: 'center', //数值在上方显示
            textStyle: {  //数值样式
              color: '#ffffff',   //字体颜色
              fontSize: 13  //字体大小
            }
          }
        }
      },
    }]
  };
}
