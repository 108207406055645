
export default function getData(xData, chartData) {
  return {
    textStyle: {
      fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    color: [
      '#24A0FF',
      '#00EFE6',
      '#FFE200',
      '#9E41EC',
      '#07a807',
      '#1684FC',
      '#ffd700',
      '#ffa500',
      '#ff0000'
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: { // 坐标轴指示器，坐标轴触发有效
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      },
      confine: true
    },
    // legend: {
    //   data: ['拥有', '不拥有']
    // },
    grid: { //控制边距大小
      left: '25%',
      right: '5%',
      bottom: '10%',
      top: '2%',
      containLabel: false
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: true,     // 网格线
        lineStyle: {
          type: 'dashed',
          color: 'rgba(200,200,200, 0.2)'
        }
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: false,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#0478C3',
          fontSize: 14,
          padding:2,
        },
      }
    },
    yAxis: {
      type: 'category',
      name:'',
      data: xData,
      show:true,
      splitLine: {
        show: false    // 网格线
      },
      // 坐标刻度线
      axisTick: {
        show: true,
        lineStyle: {
          type: 'solid', //y轴分割线类型
          color: '#0478C3',
          width: 2,
        },
      },
      // 坐标 轴线
      axisLine: {
        show: true,
        lineStyle: {
          type: 'solid',
          color: '#0478C3',
          width: 2
          // color: 'red'
        },
      },
      // //坐标轴样式
      axisLabel: {
        show: true,
        textStyle: {
          color: '#FFFFFF',
          fontSize: 14,
          padding:0,
        },
      },
    },
    series: [{
      name: '人数',
      type: 'bar',
      label: {
        normal: {
          show: true,
          position: 'right'
        }
      },
      markPoint: {
        data: [
          // { type: 'max', name: 'Max' }
        ]
      },
      data: chartData,
      emphasis: {
        focus: 'series'
      },
      itemStyle: {
        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
        normal: {
          //柱形图圆角，初始化效果
          barBorderRadius:[0, 15, 15, 0],
          label: {
            show: true, //开启显示数值
            position: 'right', //数值在上方显示
            distance: 10,
            textStyle: {  //数值样式
              color: '#00FFF6',   //字体颜色
              fontSize: 13  //字体大小
            }
          }
        }
      },
      barGap:'0%',
    }
    ]
  };
}
