<template>
  <div class="h2-box">
    <img  class="bg-h2" src="../images/bg_h2.png" />
    <div class="title-txt">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'H2View',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.h2-box{
  position: relative;
  width: 90%;
  height: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}
.h2-box .bg-h2{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.h2-box .title-txt{
  font-size: 22px;
  color: white;
  z-index: 1;
}
</style>
