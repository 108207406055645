<template>
  <div class="rec-user-container">
    <div class="header-txt">猜你喜欢</div>
    <div class="rec-user-item" v-for="item in recUserArr" :key="item.id" v-on:click="onClickDetail(item.id)">

      <el-image style="width: 88px; height: 110px;border-radius: 5px" :src="item.avatar" fit="cover">
        <img src="../images/icon_avatar_default.png"/>
      </el-image>
      <el-row class="user-info">
        <div class="user-name">
          <span style="margin-right: 8px">{{ item.name }}</span>
          <img src="../images/icon_vip.png" width="20" height="20"/>
        </div>
        <div class="info">{{ getAge(item.birthday) }} {{ item.user_info.height }}cm</div>
        <div class="desc">{{ item.user_info.your_detail_desc }}</div>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: 'RecUser',
  props: {
    recUserArr: {
      type: Array
    }
  },
  methods: {
    onClickDetail (id) {
      this.$router.push({
        path: `/user_detail/${id}`
      })
    },
    // 根据出生日期计算年龄周岁 参数2019-12-09 小于10补0
    getAge (birthday) {
      if (!birthday) {
        return ''
      }

      var returnAge = ''
      var mouthAge = ''
      var strBirthdayArr = birthday.split('-')
      var birthYear = strBirthdayArr[0]
      var birthMonth = strBirthdayArr[1]
      var birthDay = strBirthdayArr[2]
      var d = new Date()
      var nowYear = d.getFullYear()
      var nowMonth = d.getMonth() + 1
      var nowDay = d.getDate()
      if (nowYear == birthYear) {
        // returnAge = 0; //同年 则为0岁
        var monthDiff = nowMonth - birthMonth //月之差
        if (monthDiff < 0) {
        } else {
          mouthAge = monthDiff + '个月'
        }
      } else {
        var ageDiff = nowYear - birthYear //年之差
        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay //日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1 + '岁'
            } else {
              returnAge = ageDiff + '岁'
            }
          } else {
            var monthDiff = nowMonth - birthMonth //月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1 + '岁'
            } else {
              mouthAge = monthDiff + '个月'
              returnAge = ageDiff + '岁'
            }
          }
        } else {
          returnAge = -1 //返回-1 表示出生日期输入错误 晚于今天
        }
      }
      // return returnAge + mouthAge; //返回周岁年龄+月份
      return returnAge //返回周岁年龄+月份
    },
  }
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

.rec-user-container
  width 290px
  display flex
  flex-direction column
  background-color white
  box-shadow: 0px 2px 6px 2px rgba(192, 192, 192, 0.5);
  border-radius: 10px;
  margin-top 20px

  .header-txt
    margin 20px 10px 10px 20px
    color #1f1f1f
    font-size 18px
    font-weight bold

  .rec-user-item
    display flex
    flex-direction row
    border-bottom 1px solid #f2f2f2
    box-sizing border-box
    padding 20px 0
    margin 0 20px

    .user-info
      display flex
      flex-direction column
      margin-left 15px
      flex 1

      .user-name
        font-size 18px
        color #282828
        font-weight bold
        display flex
        flex-direction row
        align-items center

      .info
        color #666666
        font-size 16px
        margin-top 8px

      .desc
        color #666666
        font-size 14px
        margin-top 8px
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;


</style>
