<template>
  <div class="msg-list-item">
    <div class="msg-time">{{ time }}</div>
    <div class="container-left" v-if="(userInfo.id==msgBean.user_id)&&(msgBean.type==0)">
      <div class="icon">
        <div class="caret-left"></div>
        <el-image fit="cover" :src="userInfo.avatar+'?x-oss-process=style/s4'" class="avatar" ></el-image>
      </div>
      <div class="content">
        <span class="txt">{{ msgBean.msg }}</span>
      </div>
    </div>
    <div class="container-right" v-if="(userInfo.id!=msgBean.user_id)&&(msgBean.type==0)">
      <div class="msg-right">
        <span class="txt">{{ msgBean.msg }}</span>
      </div>
      <div class="icon-right">
        <el-image fit="cover" :src="avatar+'?x-oss-process=style/s4'" class="avatar" ></el-image>
        <div class="caret-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import constant from '../constant'
import util from '../utils/util'

export default {
  name: 'MsgList',
  props: {
    avatar: {
      type: String,
      value: ''
    },
    id: Number,
    msgBean: Object,
    userInfo: Object,
    lastTime: {
      type: String,
      value: ''
    }
  },
  data () {
    return {
      time: '',
      fitcover:'cover'
    }
  },
  created () {

    // 在组件实例进入页面节点树时执行
    let time = util.getChatTime(this.msgBean.created_at, this.lastTime)

    this.time = time
  },
  mounted () {
  },

  methods: {}
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

.msg-list-item
  width 100%
  min-height 40px
  display flex
  flex-direction column

  .msg-time
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    color: #999999;

  .container-left
    display: flex;
    flex-direction: row;
    padding: 20px;
    .icon
      width: 36px;
      height: 36px;
      position: relative;
      margin-right: 28px;
      .avatar
        width: 36px;
        height: 36px;
        margin-right: 28px;
      .caret-left
        width: 0;
        height: 0;
        border-width: 12px;
        border-color: transparent #f0f0f0 transparent transparent;
        border-style: solid;
        position: absolute;
        right: -28px;
        top: 50%;
        transform: translateY(-50%);

    .content
      max-width: 55%;
      background-color: #f0f0f0;
      border-radius: 4px;
      padding: 6px 10px;
      word-break: break-all;
      font-size: 14px;
      color black
      .txt
        line-height 24px
  .container-right
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    .msg-right
      max-width: 55%;
      background-color: #aedb44;
      border-radius: 4px;
      padding: 6px 10px;
      word-break: break-all;
      font-size: 14px;
      .txt
        line-height 24px
    .icon-right
      width: 36px;
      height: 36px;
      position: relative;
      margin-left: 28px;
      .avatar
        width: 36px;
        height: 36px;
        border-radius: 4px;
        overflow: hidden;
      .caret-right
        width: 0;
        height: 0;
        border-width: 12px;
        border-color: transparent transparent transparent #aedb44;
        border-style: solid;
        position: absolute;
        left: -28px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;


</style>
