<template>
  <section class="box-scroll-table" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.4)">
    <div  class="content">
      <span class="txt head-txt">
        <el-dropdown size="medium" @command="handleCommand">
            <span class="head-txt"  >
              排名 <span style="color: #00FFF6; font-size: 13px">{{recentlyYearArr[curIndex].name}}</span><i class="el-icon-caret-bottom"   style="color: #00FFF6; font-size: 14px"></i>
            </span>
            <el-dropdown-menu ref="dropdown" @command="handleCommand">
              <el-dropdown-item v-for="(item,index) in recentlyYearArr"  :key="index" :command="index" divided>{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

      </span>
      <span class="txt head-txt" :class="curSelectUserType == 5?'txt-max-length':''">{{curSelectUserType==5?'助餐点':'乡镇'}}</span>
      <span  v-if="curSelectUserType != 5" class="txt head-txt" >总助餐点</span>
      <span  v-if="curSelectUserType != 5" class="txt head-txt">总助餐人</span>
      <span class="txt head-txt"> 助餐次数</span>
      <span class="txt head-txt">运营补贴（元）</span>
      <span class="txt head-txt">助餐金额（元）</span>
    </div>
    <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp" >
      <div v-for="(item, index) in listData" :key="index"  class="content" @click="onClickItem(item)">
        <span class="txt rank">{{index+1}}</span>
        <span  class="txt" :class="curSelectUserType == 5?'txt-max-length':''">{{item.name}}</span>
        <span  v-if="curSelectUserType != 5" class="txt">{{item.shopNum}}</span>
        <span   v-if="curSelectUserType != 5" class="txt">{{item.personNum}}</span>
        <span class="txt">{{item.num}}</span>
        <span class="txt">￥{{item.subsidyAmount}}</span>
        <span class="txt">￥{{item.amount}}</span>
      </div>
    </vue-seamless-scroll>
  </section>

</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import request from '../http/request'
export default {
  name: 'AutoScrollTableViewMeal',
  components: {
    vueSeamlessScroll
  },
  props: {
    listData: {
      type: Array
    },
    recentlyYearArr:{
      type: Array
    },
    curSelectUserType:{
      type: Number
    },
    provinceId: String,
    cityId: String,
    districtId: String,
    groupId: String
  },
  // watch: {
  //   curSelectUserType: {
  //     handler(val) {
  //       console.log("msg change", val);
  //       //当监听的值发生改变后，可以在这里做些什么...
  //       this.httpMealListByArea()
  //     },
  //     immediate: true, // 初次监听即执行
  //   },
  // },

  data () {
    return {
      loading: false,
      curIndex:0,
      queryMonth:'',
      classOption: {
        step: 0.8, // 数值越大速度滚动越快
        limitMoveNum: this.listData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 38, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000
      },
    }
  },
  created() {
    this.curIndex = 0
    this.queryMonth = this.recentlyYearArr[0].value
    // this.httpMealListByArea()
  },
  methods: {
    handleCommand(command) {
     this.curIndex = command
      this.queryMonth = this.recentlyYearArr[command].value
      this.httpMealListByArea()
    },

    onClickItem(item){
      this.$emit('onClickItem', item);
    },

// 助餐按照区域统计列表
    httpMealListByArea(){
      let userType = parseInt(this.curSelectUserType)
      switch (userType){
        case 11:
          this.httpMealListByCity()
          break
        case 12:
          this.httpMealListByDistrict()
          break
        case 4:
          this.httpMealListByTown()
          break
        case 5:
          this.httpMealListByShop()
          break
        case 6:
          break
      }
    },


    // 助餐-按城市分组统计列表
    httpMealListByCity(){
      this.loading = true
      request.httpPost('/v2/business/meal/statistics/list/by/city', {
        provinceId: this.provinceId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
    // 助餐-按区县分组统计列表
    httpMealListByDistrict(){
      this.loading = true
      request.httpPost('/v2/business/meal/statistics/list/by/district', {
        cityId: this.cityId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpMealListByTown(){
      this.loading = true
      request.httpPost('/v2/business/meal/statistics/list/by/town', {
        districtId: this.districtId,
        queryMonth: this.queryMonth
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按城镇分组统计列表
    httpMealListByGroup(){
      this.loading = true
      request.httpPost('/v2/business/meal/statistics/list/by/group', {
        queryMonth: this.queryMonth,
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },

    // 助餐-按店铺统计列表
    httpMealListByShop(){
      this.loading = true
      request.httpPost('/v2/business/meal/statistics/list/by/shop', {
        queryMonth: this.queryMonth,
        groupId: this.groupId
      }, success => {
        this.listData = success
        this.loading = false
      }, error => {
        this.loading = false
      })
    },
  }
}
</script>

<style >


.box-scroll-table{
  border: 4px solid #243978;
  border-image: linear-gradient(90deg, rgba(36, 57, 120, 0.8) 0%, rgba(36, 57, 120, 0.5) 100%) 2 2 2 2;
  opacity: 0.8;
  padding: 0 4px;
  z-index: 1;
  margin: 0 10px;
}

.content .txt {
  color: #57C7ED;
  text-align: center;
  font-size: 14px;
  flex: 1;
}
.content .rank{
  font-weight: bold;
}
.content .head-txt{
  color: #247CE1;
  font-weight: bold;
}
.content .txt-max-length{
  flex: 3 !important;
}
</style>
