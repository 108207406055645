<template>
  <el-card style="overflow: visible">
    <div class="user-card">
      <el-image class="avatar" fit="cover" :src="userBean.avatar"></el-image>
      <div class="user-info">
        <div class="name">
          <span>{{ userBean.name }}</span>
          <img style="margin-left: 10px" width="20" height="20" src="../images/icon_vip.png"/>
          <div class="btn-love" :class="userBean.love==1?'loved':''" @click="onClickLove">
            <el-image class="icon-love" fit="cover"
                      :src="userBean.love==1?require('../images/icon_loved.png'):require('../images/icon_love.png')"></el-image>
            <span class="love-txt">{{ userBean.love == 1 ? '已关注' : '关注' }}</span>
          </div>
        </div>

        <div class="user-id">ID：{{ 11000000 + userBean.id }}</div>
        <div class="info-txt">
          {{ getAge(userBean.birthday) }}
          | {{ userBean.user_info.height }}cm
          | {{ userBean.city }}
          | {{ monthlyIncomeArray[userBean.user_info.monthly_income] }}
          | {{ userBean.occupation }}
          | {{ educationArray[userBean.education] }}
        </div>

        <div class="pics">
          <el-scrollbar
            v-if="userBean.user_info&&userBean.user_info.life_photos&&userBean.user_info.life_photos.length>0">
            <el-image class="photo" v-for="img in splitStr(userBean.user_info.life_photos)" fit="cover" :src="img"
                      :key="img"></el-image>
          </el-scrollbar>
          <div v-else style="padding: 20px; color:#999999;margin-top: 20px">该用户尚未上传生活照</div>
        </div>


      </div>

    </div>
    <div v-if="showBtn" class="btn-box">

      <el-button class="btn orange" type="warning" icon="el-icon-message" @click="onClickSendMsg">发消息</el-button>

      <el-button class="btn blue" type="info" icon="el-icon-chat-round" @click="onClickSendHi(userBean.id)"
                 :loading="hiBtnLoading">打招呼
      </el-button>

      <el-button class="btn" type="primary" icon="el-icon-coordinate" @click="onClickMatchMaker"
                 :loading="matchBtnLoading">
        红娘牵线
      </el-button>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="25%"
      center>
      <span style="text-align: center">登录后才能查看, 是否要登录？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="onClickLogin" size="medium">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import constant from '../constant'
import { getStore } from '../utils/storage'
import request from '../http/request'
import { getAge } from '../utils/util'

export default {
  name: 'UserInfo',
  props: {
    userBean: {
      type: Object
    },
    showBtn: {
      type: Boolean
    },
    isVip: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      educationArray: constant.educationArray,
      maritalStatusArray: constant.maritalStatusArray,
      monthlyIncomeArray: constant.monthlyIncomeArray,
      carStatusArray: constant.carStatusArray,
      housingArray: constant.housingArray,
      token: '',
      centerDialogVisible: false,
      matchBtnLoading: false,
      hiBtnLoading: false
    }
  },
  created () {
    this.token = getStore(constant.K_TOKEN)
  },
  methods: {
    onClickLove () {
      if (!this.token) {
        this.centerDialogVisible = true
        return
      }
      request.httpPost('/api/user/love', {
        type: 1,
        love_user_id: this.userBean.id,
        status: this.userBean.love == 1 ? 0 : 1    // 1： 喜欢 0 取消喜欢
      }, success => {
        this.userBean.love = success.status
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80,
        })
      })
    },
    onClickLogin () {
      this.$router.push('/')
    },
    onClickSendMsg () {
      if (!this.token) {
        this.centerDialogVisible = true
        return
      }
      if (this.isVip!=1){
        this.$message({
          message: '开通钻石会员后，才能发消息',
          type: 'error',
          offset: 80,
        })
        this.$router.push({
          path: '/vip'
        })
        return
      }
      this.$router.push({
        path: `/msg_send/${this.userBean.id}`
      })
    },
    onClickSendHi (toUserId) {
      if (!this.token) {
        this.centerDialogVisible = true
        return
      }
      var msg = 'Hi，你好，很高兴认识你！'
      let conversationId = -1
      let lastId = null
      let type = 0
      var params = {
        conversation_id: conversationId,
        to_user_id: toUserId,
        msg: msg,
        type: type,
        last_id: lastId
      }
      this.hiBtnLoading = true
      request.httpPost('/api/chat/send', params, success => {
        this.hiBtnLoading = false
        this.$message({
          message: '打招呼成功！',
          type: 'success',
          offset: 80
        })
      }, error => {
        this.hiBtnLoading = false
        this.$message({
          message: error,
          type: 'error',
          offset: 80
        })
        console.log(error)
      })
    },
    onClickMatchMaker () {
      if (!this.token) {
        this.centerDialogVisible = true
        return
      }
      this.matchBtnLoading = true
      request.httpPost('/api/web/contact-match-maker', {}, data => {
        this.$message({
          message: '你的申请已收到！红娘老师将及时与你取得联系。',
          type: 'success',
          offset: 80
        })
        this.matchBtnLoading = false
      }, error => {
        this.$message({
          message: '网络请求失败',
          type: 'error',
          offset: 80
        })
        this.matchBtnLoading = false
      })
    },

    splitStr (photoStr) {
      if (photoStr) {
        return photoStr.split(',')
      }
      return []
    },
    // 根据出生日期计算年龄周岁 参数2019-12-09 小于10补0
    getAge (birthday) {
      return getAge(birthday)
    },

  }
}
</script>

<style lang='stylus' rel='stylesheet/stylus'>
@import "../common/stylus/mixin.styl"

.user-card
  display flex
  flex-direction row
  margin-top -120px
  z-index 1

  .avatar
    width 238px
    height 238px
    margin-right 20px
    border-radius 5px
    margin-left 20px

  .user-info
    display flex
    flex-direction column
    color white
    width 600px

    .name
      display flex
      flex-direction row
      align-items center
      font-size 20px

      .btn-love
        display flex
        flex-direction row
        align-items center
        height 38px
        padding 0 16px
        border-radius 20px
        background-color white
        margin-left 30px

        .icon-love
          width 24px
          height 24px
          margin-right 5px

        .love-txt
          color #FF4666
          font-size 16px

    .user-id
      font-size 14px
      margin-top 8px
      font-weight 320

    .info-txt
      font-size 16px
      margin-top 8px

    .pics
      display flex
      flex-direction row
      align-items center

      .photo
        width 116px
        height 116px
        margin-top 25px
        border-radius 4px
        margin-right 10px
        display: inline-block;


.btn-box
  padding 30px 20px 10px 20px
  display flex
  flex-direction row
  align-items center
  justify-content space-between

  .btn
    width 230px
    height 46px
    text-align center
    color white
    font-size 18px
    font-weight 400

    &.orange
      background-color #FF6200

      &:hover
        background-color #fb883b

    &.blue
      background-color #1684FC

      &:hover
        background-color #3c96f8

    &.red
      background-color #ff4666

</style>
